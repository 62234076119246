import { Box, IconProps, TextProps, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

type ContentProps = {
  title: string;
  intent?: TextProps['intent'];
  icon?: IconProps['icon'] | null;
};

const Content = ({
  title,
  intent,
  icon = 'academic-hat-outlined',
}: ContentProps) => (
  <Box sx={{ display: 'flex', gap: 'medium', alignItems: 'center' }}>
    <Box sx={{ minWidth: 24 }}>
      <Image src={`/assets/menu-icons/${icon}.svg`} width={24} height={24} />
    </Box>

    <Typography.Text
      fontSize={16}
      tagName="span"
      intent={intent}
      fontWeight="semi-bold"
      sx={{ overflow: 'hidden' }}
    >
      {title}
    </Typography.Text>
  </Box>
);

export default Content;
