import React, { useCallback } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Checkbox } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface CheckboxButtonGroupInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<
    React.ComponentProps<typeof Checkbox.ButtonGroup>,
    'onChange' | 'value' | 'name'
  > & {
    disabled?: boolean;
  };
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
}

const CheckboxButtonGroupInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: CheckboxButtonGroupInputProps<TFieldValues, TName>) => {
  const { options, disabled } = inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const {
    error: extraError,
    'data-test-id': dataTestId,
    onRemoveCallbackFn,
    onSelectCallbackFn,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const inputOptions = React.useMemo(
    () => options.map(opt => ({ ...opt, disabled: opt.disabled || disabled })),
    [options, disabled]
  );

  const customOnChange = useCallback(
    newValue => {
      const deleteValues =
        value?.filter((item: string) => !newValue.includes(item)) || [];
      const createValues =
        newValue?.filter((item: string) => !value?.includes(item)) || [];

      deleteValues.forEach((item: string) => {
        onRemoveCallbackFn?.(item);
      });

      createValues.forEach((item: string) => {
        onSelectCallbackFn?.(item);
      });

      onChange(newValue);
    },
    [value, onChange, onRemoveCallbackFn, onSelectCallbackFn]
  );

  return (
    <InputContainer inline={inline} data-test-id={dataTestId}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        input={
          <Checkbox.ButtonGroup
            name={name}
            value={value ?? []}
            options={inputOptions}
            onChange={customOnChange}
          />
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default CheckboxButtonGroupInput;
