import { useEffect, useState } from 'react';

const useChangeViewPort = () => {
  const [viewportContent, setViewportContent] = useState(
    'width=device-width, initial-scale=1'
  );

  useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      setViewportContent(
        'width=device-width, initial-scale=1, maximum-scale=1'
      );
    }
  }, []);

  return viewportContent;
};

export default useChangeViewPort;
