import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import styled from 'styled-components';

import { Checkbox } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { themeGet } from '../../utils';
import { ExtraProps, LabelProps } from '../types';

const Spacer = styled.div`
  margin-bottom: ${themeGet(theme => theme.space.small)}px;
`;

interface CheckboxInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<
    React.ComponentProps<typeof Checkbox.Group>,
    'value' | 'onChange'
  > & {
    disabled?: boolean;
  };
  labelProps?: LabelProps;
  extraProps?: ExtraProps & { checkAllText?: string };
}

const CheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: CheckboxInputProps<TFieldValues, TName>) => {
  const { options, layout, disabled } = inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const {
    error: extraError,
    checkAllText,
    'data-test-id': dataTestId,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const inputOptions = React.useMemo(
    () => options.map(opt => ({ ...opt, disabled: opt.disabled || disabled })),
    [options, disabled]
  );

  const onCheckAllChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    e => {
      if (e.target.checked) {
        onChange(
          options
            .filter(opt => opt.disabled !== true)
            .map(option => option.value)
        );
      } else {
        onChange([]);
      }
    },
    [options, onChange]
  );
  const nOpts = options.length;
  const nSelected = value ? value.length : 0;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        input={
          <>
            {checkAllText !== undefined && (
              <>
                <Checkbox
                  text={checkAllText}
                  value="check_all"
                  indeterminate={nSelected !== 0 && nSelected < nOpts}
                  checked={nSelected !== 0 && nSelected === nOpts}
                  onChange={onCheckAllChange}
                />

                <Spacer />
              </>
            )}

            <Checkbox.Group
              name={name}
              value={value || []}
              options={inputOptions}
              onChange={onChange}
              layout={layout}
            />
          </>
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default CheckboxInput;
