import type { DynamicOptions, Loader, LoaderComponent } from 'next/dynamic';
import dynamic from 'next/dynamic';

export const dynamicImport = <T = Record<string, unknown>>(
  componentLoader: Loader<T>,
  opts?: DynamicOptions<T>
) =>
  dynamic(
    (): LoaderComponent<T> =>
      new Promise((resolve, reject) => {
        if (typeof componentLoader === 'function') {
          componentLoader()
            .then(resolve)
            .catch(error => {
              reject(error);
              window.location.reload();
            });
        } else if (componentLoader instanceof Promise) {
          componentLoader.then(resolve).catch(error => {
            reject(error);
            window.location.reload();
          });
        }
      }),
    { ...opts }
  );
