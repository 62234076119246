import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Checkbox } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface SingleCheckboxInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<React.ComponentProps<typeof Checkbox>, 'value' | 'onChange'>;
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
}

const SingleCheckboxInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: SingleCheckboxInputProps<TFieldValues, TName>) => {
  const { indeterminate, style, disabled, text: inputText } = inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const { error: extraError, 'data-test-id': dataTestId } = extraProps;
  const hasError = error != null || extraError != null;

  const id = `hero-theme-single-checkbox-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <Checkbox
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            indeterminate={indeterminate}
            style={style}
            checked={value}
            text={inputText}
          />
        }
      />

      {hasError && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default SingleCheckboxInput;
