import { PHONE_CODE_OPTIONS } from './constants';

export const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const getPhoneCodeFromPhoneNumber = (phoneNumber: string) =>
  PHONE_CODE_OPTIONS.find(op => phoneNumber?.startsWith(op.value))?.value;
