import React, { useCallback } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Input } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface TextInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps?: React.ComponentProps<typeof Input>;
  labelProps?: LabelProps;
  extraProps?: ExtraProps & {
    onSelectCallbackFn?: (optionValue: string) => void;
  };
}

const TextInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {},
  labelProps = {},
  extraProps = {},
}: TextInputProps<TFieldValues, TName>) => {
  const {
    placeholder,
    prefix,
    suffix,
    disabled,
    type,
    className,
    autoFocus,
    autoComplete,
    onKeyDown,
    size,
    ref,
  } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    helpText: labelHelpText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { onChange: onChangeField, onBlur, name, value } = field;
  const { error } = fieldState;
  const {
    error: extraError,
    'data-test-id': dataTestId,
    onSelectCallbackFn,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const id = `hero-theme-text-input__${name}`;

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    newSelectedValue => {
      onSelectCallbackFn?.(newSelectedValue?.target?.value);
      onChangeField(newSelectedValue);
    },
    [onChangeField, onSelectCallbackFn]
  );

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        helpText={labelHelpText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <Input
            id={id}
            value={value}
            name={name}
            onChange={val => onChange(val ?? null)}
            onBlur={onBlur}
            placeholder={placeholder}
            prefix={prefix}
            suffix={suffix}
            invalid={hasError}
            disabled={disabled}
            type={type}
            className={className}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
            size={size}
            ref={ref}
          />
        }
      />

      {hasError && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default TextInput;
