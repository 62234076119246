import { remove, unionBy } from 'lodash/fp';

import React, { useCallback, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import styled from 'styled-components';

import { BaseOption, Box, Chip, Select, Tag } from '@hero-design/react';

import ErrorBoundaryMobile from '@packages/error-boundary/ErrorBoundaryMobile';
import { XOR } from '@packages/type-utils';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import SearchNoResults from '../SearchNoResults';
import { ExtraProps, LabelProps } from '../types';

type ExtraInputProps = {
  menuVariant?: 'wrap' | 'nowrap';
};

const StyledSelect = styled(Select)`
  input {
    width: 100%;
  }
`;

interface SelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value'> &
    ExtraInputProps;
  labelProps?: LabelProps;
  extraProps?: ExtraProps &
    XOR<{ showTagOutside?: boolean }, { showChipOutside?: boolean }> & {
      queryable?: boolean;
      clearable?: boolean;
      noResultText?: string;
      onQueryChange?: (query: string) => void;
      onCreateNewOption?: (optionText: string) => void;
      onSelectCallbackFn?: (optionValue: string) => void;
      onRemoveCallbackFn?: (option: BaseOption<string | number>) => void;
      inputRef?: React.Ref<HTMLInputElement>;
    };
}

const SelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: SelectInputProps<TFieldValues, TName>) => {
  const {
    onBlur: externalOnBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    options,
    optionRenderer,
    optionPredicate,
    menuVariant = 'wrap',
    placeholder,
    prefix,
    disabled,
    loading,
    onScrollListToBottom,
    autoComplete = 'off',
    size,
  } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { error } = fieldState;
  const { value, onChange: onChangeField, onBlur: fieldOnBlur, name } = field;
  const {
    error: extraError,
    queryable = true,
    noResultText,
    onQueryChange: extraOnQueryChange,
    onCreateNewOption,
    'data-test-id': dataTestId,
    clearable,
    showTagOutside,
    showChipOutside,
    onSelectCallbackFn,
    onRemoveCallbackFn,
    inputRef,
  } = extraProps;
  const hasError = error != null || extraError != null;

  const [query, setQuery] = React.useState();
  const onQueryChange = React.useCallback(
    (q: any) => {
      setQuery(q);
      extraOnQueryChange?.(q);
    },
    [extraOnQueryChange]
  );
  const queryProps = queryable
    ? {
        query,
        onQueryChange,
        noResults: <SearchNoResults noResultText={noResultText} />,
        inputRef,
      }
    : {};
  const optionMenuStyle = useMemo(() => {
    switch (menuVariant) {
      case 'wrap':
        return undefined;
      case 'nowrap':
        return { width: 'auto' };
      default:
        return undefined;
    }
  }, [menuVariant]);

  const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    e => {
      externalOnBlur?.(e);
      fieldOnBlur();
    },
    [externalOnBlur, fieldOnBlur]
  );
  const id = `hero-theme-select-input__${name}`;

  const onChange = useCallback(
    (newSelectedValue: string) => {
      if (showTagOutside || showChipOutside) {
        const newSelectedOption = (
          options as Array<BaseOption<string | number>>
        ).find(option => option.value === newSelectedValue);

        if (!newSelectedOption) {
          return;
        }

        const newValue = unionBy('value', value, [newSelectedOption]);

        if (
          !value?.find(
            (option: BaseOption<string | number>) =>
              option.value === newSelectedValue
          )
        ) {
          onSelectCallbackFn?.(newSelectedValue);
        }

        onChangeField(newValue);
      } else {
        onSelectCallbackFn?.(newSelectedValue);
        onChangeField(newSelectedValue);
      }
    },
    [
      onChangeField,
      onSelectCallbackFn,
      options,
      showTagOutside,
      showChipOutside,
      value,
    ]
  );

  const onRemoveSelectedOption = useCallback(
    (removeOption: any) => {
      const newValue = remove(removeOption, value);

      onChangeField(newValue);
      onRemoveCallbackFn?.(removeOption);
    },
    [onChangeField, onRemoveCallbackFn, value]
  );

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <ErrorBoundaryMobile>
            <StyledSelect
              id={id}
              name={name}
              value={showTagOutside ? undefined : value}
              options={options}
              optionRenderer={optionRenderer}
              optionPredicate={optionPredicate}
              optionMenuStyle={optionMenuStyle}
              onChange={val => onChange(val as string)}
              placeholder={placeholder}
              prefix={prefix}
              invalid={hasError}
              disabled={disabled}
              loading={loading}
              onScrollListToBottom={onScrollListToBottom}
              autoComplete={autoComplete}
              clearable={clearable}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
              size={size}
              onCreateNewOption={onCreateNewOption}
              {...queryProps}
            />
          </ErrorBoundaryMobile>
        }
      />

      {hasError && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}

      {showTagOutside ? (
        <Box mt="medium">
          {value?.map((option: BaseOption<string | number>, index: number) => (
            <Tag
              data-test-id={`${dataTestId}-tag-${index}`}
              key={option.value}
              value={option.value}
              text={option.text}
              onRemove={() => onRemoveSelectedOption(option)}
              sx={{
                mr: 'small',
                mb: 'small',
              }}
            />
          ))}
        </Box>
      ) : null}
      {showChipOutside ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 'small',
            mt: 'medium',
          }}
        >
          {value?.map((option: BaseOption<string | number>, index: number) => (
            <Chip
              key={option.value}
              checked
              text={option.text}
              onClick={() => onRemoveSelectedOption(option)}
              data-test-id={`${dataTestId}-chip-${index}`}
            />
          ))}
        </Box>
      ) : null}
    </InputContainer>
  );
};

export default SelectInput;
