import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Banner, Button, theme, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { useFetch } from '@packages/eh-utils/api';
import {
  getMobileAppLink,
  getUrlTrackingServiceDirectApiHost,
} from '@packages/eh-utils/browserEnv';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';

type UserActivity = {
  happened_at: number;
  kind: string;
};

type MarkIgnoreBannerResponse = {
  data: {
    items: Array<UserActivity>;
  };
};

type MarkIgnoreBannerParams = {
  activity: string;
};

const MobileBannerContainer = styled.div`
  width: 100%;
`;
const BannerContainer = styled.div`
  width: 100%;
`;

const BannerWrapper = styled(Banner)`
  && {
    padding-left: ${theme.space.xxlarge * 2}px;
    padding-right: ${theme.space.xxlarge * 2}px;

    ${mediaMaxQueries.sm} {
      padding-left: ${theme.space.medium}px;
      padding-right: ${theme.space.medium}px;
    }
  }
`;

const CtaWrapper = styled.div`
  margin: 0 0 0 auto;
  padding: 0;

  ${mediaMaxQueries.sm} {
    display: flex;
    align-items: center;
  }
`;

const AppDownloadBanner = ({ isMobile = false, showBanner = false }) => {
  const MOBILE_APP_DYNAMIC_LINK = getMobileAppLink() || '';
  const { data: session } = useSession();
  const { fetcher: markIgnoreBanner } = useFetch<
    MarkIgnoreBannerResponse,
    MarkIgnoreBannerParams
  >({
    endpoint: `${getUrlTrackingServiceDirectApiHost()}/api/v1/user_activities/${
      session?.user.id
    }/create_activity`,
    method: 'POST',
    formatBody: params => JSON.stringify(params),
  });

  const onDismiss = () => {
    markIgnoreBanner({
      activity: 'ignore_banner',
    });
  };

  if (!showBanner) return <></>;

  return isMobile ? (
    <MobileBannerContainer>
      <Banner
        style={{
          background: theme.colors.defaultLightBackground,
          paddingLeft: theme.space.xsmall * 3,
          paddingRight: theme.space.xsmall * 3,
        }}
        icon={null}
        content={
          <Typography.Text
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button.Icon icon="cancel" intent="text" onClick={onDismiss} />

            <Image
              src="/assets/images/swag-white-logo.png"
              alt="swag-logo"
              style={{
                height: '48px',
                width: '48px',
                paddingLeft: theme.space.xsmall * 3,
                paddingRight: theme.space.xsmall * 3,
              }}
            />

            <div style={{ flex: 1, maxWidth: '50%' }}>
              <Typography.Text fontWeight="bold">Swag app</Typography.Text>

              <Typography.Text>
                Manage your job application now!
              </Typography.Text>
            </div>

            <CtaWrapper>
              <LinkButton
                href={MOBILE_APP_DYNAMIC_LINK}
                target="_blank"
                text="Get mobile app"
              />
            </CtaWrapper>
          </Typography.Text>
        }
      />
    </MobileBannerContainer>
  ) : (
    <>
      <BannerContainer>
        <BannerWrapper
          icon={null}
          content={
            <Typography.Text
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Image
                src="/assets/images/swag-white-logo.png"
                alt="swag-logo"
                sx={{ height: '48px', width: '48px' }}
              />

              <div style={{ marginLeft: theme.space.large }}>
                <Typography.Text fontWeight="bold">Swag app</Typography.Text>

                <Typography.Text>
                  Manage your job application now!
                </Typography.Text>
              </div>

              <CtaWrapper>
                <Button
                  text="Get mobile app"
                  sx={{ marginRight: theme.space.large }}
                  onClick={() => {
                    window.scrollTo({
                      left: 0,
                      top: document.body.scrollHeight,
                      behavior: 'smooth',
                    });
                  }}
                />

                <Button.Icon icon="cancel" intent="text" onClick={onDismiss} />
              </CtaWrapper>
            </Typography.Text>
          }
          intent="info"
          sx={{ backgroundColor: theme.colors.palette.dodgerBlueLight75 }}
        />
      </BannerContainer>
    </>
  );
};

export default AppDownloadBanner;
