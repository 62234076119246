import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTheme } from 'styled-components';

import { Button, Modal, Typography } from '@hero-design/react';

import { required } from '@packages/eh-utils/formValidators';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';

import PhoneNumberField from 'src/shared/PhoneNumberField';

import useFetchUserProfile from '../../hooks/useFetchUserProfile';
import useUpdateUserProfile from '../../hooks/useUpdateUserProfile';

type TPhoneNumberForm = {
  phoneNumber: string;
};

const SMSConsentModal = () => {
  const theme = useTheme();
  const { status } = useSession();
  const { route, isReady } = useRouter();
  const [open, setOpen] = useState(true);

  const { control, formState, setValue, handleSubmit, watch } =
    useForm<TPhoneNumberForm>({
      mode: 'all',
    });

  const phoneNumberValue = watch('phoneNumber');

  const { permissionsData, isFetchingPermissions } = useFetchPermissions();
  const { userProfileData, isFetchingUserProfile } = useFetchUserProfile();
  const { updateProfile, updatingProfile } = useUpdateUserProfile();

  const onConsent = () => {
    if (updatingProfile) return;

    updateProfile({
      phoneNumber: phoneNumberValue,
      allowSmartMatchSmsNotification: true,
    });

    setOpen(false);
  };

  const onClose = () => {
    if (updatingProfile) return;

    updateProfile({
      allowSmartMatchSmsNotification: false,
    });

    setOpen(false);
  };

  const shouldHideModal = useMemo<boolean>(() => {
    // pre-conditions
    if (status !== 'authenticated') return true;
    if (isFetchingPermissions || isFetchingUserProfile || !isReady) return true;

    // Feature flag
    if (!permissionsData?.data.smart_match_sms_notification_email_enabled)
      return true;

    const profileData = userProfileData?.data;
    const countryCode = profileData?.country_code ?? '';

    // Consent
    if (profileData?.allow_smart_match_sms_notification !== null) return true;

    // Allow location AU, NZ, MY, SG, SI only (Notes: SI is country code in idibu of Singapore)
    if (
      !['AU', 'NZ', 'MY', 'SG', 'SI'].includes(countryCode) &&
      !profileData?.work_eligibility_countries?.some((country: string) =>
        ['AU', 'NZ', 'MY', 'SG', 'SI'].includes(country)
      )
    )
      return true;

    // Hide in onboarding flow
    if (
      ![
        '/',
        '/user/job_matches',
        '/search',
        '/user/savedJobs',
        '/user/applications',
      ].includes(route)
    )
      return true;

    return false;
  }, [
    isFetchingPermissions,
    isFetchingUserProfile,
    isReady,
    permissionsData,
    route,
    status,
    userProfileData?.data,
  ]);

  useEffect(() => {
    if (userProfileData?.data.phone_number) {
      setValue('phoneNumber', userProfileData?.data.phone_number);
    }
  }, [setValue, userProfileData?.data.phone_number]);

  if (shouldHideModal) {
    return null;
  }

  return (
    <Modal
      variant="basic"
      canOutsideClickClose={false}
      open={open}
      onClose={onClose}
      title="Never miss another job opportunity with SMS notifications!"
      body={
        <>
          <PhoneNumberField
            name="phoneNumber"
            control={control}
            inputProps={{ placeholder: 'phone number' }}
            validate={{ required }}
          />

          <Typography.Text intent="subdued">
            By turning this feature on, you agree to receiving SMS or WhatsApp
            notifications when a hiring manager saves your profile, or sends you
            a message to show interest in recruiting you for a role in their
            business. You can turn this feature off in your profile settings at
            any time.
          </Typography.Text>
          <Typography.Text
            intent="subdued"
            sx={{ mt: 'medium', fontStyle: 'italic' }}
          >
            Please note: All mobile numbers except UK are eligible for SMS or
            WhatsApp notifications. Updating your number here will also update
            it in your profile.
          </Typography.Text>
        </>
      }
      footer={
        <>
          <Button
            variant="text"
            text="Maybe later"
            sx={{
              fontWeight: theme.fontWeights.bold,
              color: theme.colors.text,
            }}
            onClick={onClose}
          />
          <Button
            text="Turn on"
            disabled={!formState.isValid}
            onClick={handleSubmit(onConsent)}
          />
        </>
      }
    />
  );
};

export default SMSConsentModal;
