export const APPLICATION_STATUS = {
  NEW: 'new',
  DECLINED: 'declined',
  EXPIRED: 'expired',
  ACCEPTED: 'accepted',
  APPLIED: 'applied',
};

export const localeOptions = [
  { value: 'NZ', text: 'New Zealand' },
  { value: 'AU', text: 'Australia' },
  { value: 'GB', text: 'United Kingdom' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'SG', text: 'Singapore' },
  { value: 'anywhere', text: 'International' },
];

export const LOCALE_OPTIONS_V2 = [
  { value: 'NZ', text: 'New Zealand' },
  { value: 'AU', text: 'Australia' },
  { value: 'GB', text: 'United Kingdom' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'SG', text: 'Singapore' },
  { value: 'anywhere', text: 'Region' },
];

export const REGION_OPTIONS = [
  { value: 'NZ', text: 'New Zealand' },
  { value: 'AU', text: 'Australia' },
  { value: 'GB', text: 'United Kingdom' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'SG', text: 'Singapore' },
];

export const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
