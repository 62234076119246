import get from 'lodash/fp/get';
import remove from 'lodash/fp/remove';
import uniqueId from 'lodash/fp/uniqueId';

import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, theme, Typography } from '@hero-design/react';

import { formatRelativeTime } from 'src/modules/User/components/shared/date';

import { mediaQueries, themeGet } from '../../utils';

import { isRawFile } from './utils';
import { CustomFile } from './types';

import { UPLOAD_STATUSES } from './constants';

const INVALID_REASONS = {
  SIZE_LIMIT_EXCEEDED: 'size-limit-exceeded',
  FORMAT_NOT_ALLOWED: 'format-not-allowed',
};

const FileInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & > :last-child {
    padding: 0 ${themeGet('space.xsmall')}px;
  }
`;

const FileName = styled(Typography.Text)`
  white-space: pre-wrap;
  word-break: break-word;

  ${mediaQueries.sm} {
    max-width: 500px;
  }

  ${mediaQueries.md} {
    max-width: 700px;
  }
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${themeGet('space.xsmall')}px;

  &:hover {
    background-color: ${themeGet('colors.blueLight90')};
  }
`;

export const getAllowedFormats = (accept?: string) => {
  if (accept == null) {
    return '';
  }

  const types = accept.split(',');
  const processedTypes = types.map(type => {
    const validType = type.trim().toLowerCase();

    // accept="audio/*,video/*,image/*"
    if (validType.endsWith('/*')) {
      return validType.replace('/*', '');
    }

    // accept="image/png,image/jpeg"
    // accept=".jpg,.jpeg,.png"
    return validType;
  });

  return processedTypes.join(', ').trim();
};

export const getAllowedSize = (maxSize?: number) => {
  if (maxSize == null) {
    return '';
  }

  const k = 1024;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(maxSize) / Math.log(k));

  return `${parseFloat((maxSize / k ** i).toFixed(2))}${units[i]}`;
};

export const getInvalidMessage = ({
  allowedFormats,
  allowedSize,
  reason,
}: {
  allowedFormats: string;
  allowedSize: string;
  reason: 'size-limit-exceeded' | 'format-not-allowed';
}) => {
  const invalidMessageMap = {
    [INVALID_REASONS.SIZE_LIMIT_EXCEEDED]: `Maximum allowed ${allowedSize} for uploaded file(s).`,
    [INVALID_REASONS.FORMAT_NOT_ALLOWED]: `Only ${allowedFormats} ${
      allowedFormats.length > 1 ? 'are' : 'is'
    } allowed for uploaded file(s).`,
  };

  return invalidMessageMap[reason];
};

export const getButtonIconIntent = ({
  isInvalid,
  hover,
}: {
  isInvalid: boolean;
  hover: boolean;
}) => {
  if (isInvalid) {
    return 'danger';
  }

  return hover ? 'danger' : 'subdued-text';
};

export const getIconIntent = (isInvalid: boolean) =>
  isInvalid ? 'danger' : 'text';

export const checkInvalid = (data: any) => data?.reason !== undefined;

export const extractFileInfo = (data: any) => {
  if (checkInvalid(data) || isRawFile(data?.file) || data.file != null) {
    return data.file;
  }

  return data;
};

const getFileName = ({
  data,
  accept,
  maxSize,
}: {
  data: any;
  accept?: string;
  maxSize?: number;
}) => {
  const fileInfo = extractFileInfo(data);
  const isInvalid = checkInvalid(data);
  const { uploadStatus } = data;
  const href = fileInfo.signed_remote_url || fileInfo.remote_url;
  const allowedFormats = getAllowedFormats(accept);
  const allowedSize = getAllowedSize(maxSize);
  const invalidMessage = getInvalidMessage({
    allowedFormats,
    allowedSize,
    reason: data.reason,
  });
  const uploadMessageMap = {
    [UPLOAD_STATUSES.FAILED]: 'Failed',
    [UPLOAD_STATUSES.UPLOADING]: 'Uploading',
  };

  if (isInvalid) {
    return (
      <div>
        <FileName intent="danger">{fileInfo.name}</FileName>

        <Typography.Text fontSize={12} intent="subdued">
          {invalidMessage}
        </Typography.Text>
      </div>
    );
  }

  if (
    uploadStatus === UPLOAD_STATUSES.INITIAL ||
    uploadStatus === UPLOAD_STATUSES.FAILED ||
    uploadStatus === UPLOAD_STATUSES.UPLOADING
  ) {
    return (
      <div>
        <FileName intent="subdued">{fileInfo.name}</FileName>

        <Typography.Text fontSize={12} intent="subdued">
          {uploadMessageMap[uploadStatus]}
        </Typography.Text>
      </div>
    );
  }

  return (
    <Button.Link
      // TODO: Fix this after support from HD
      // https://github.com/Thinkei/hero-design/issues/675
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      text={<FileName intent="inherit">{fileInfo.name}</FileName>}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};

const FileContent = ({
  data,
  accept,
  maxSize,
}: {
  data: any;
  accept?: string;
  maxSize?: number;
}) => {
  const isInvalid = useMemo(() => checkInvalid(data), [data]);
  const fileInfo = useMemo(() => extractFileInfo(data), [data]);
  const iconIntent = getIconIntent(isInvalid);
  const fileName = getFileName({ data, accept, maxSize });

  return (
    <FileInfo title={fileInfo.name}>
      <Icon
        intent={iconIntent}
        icon="paperclip"
        size="small"
        style={{ margin: `${theme.space.xsmall}px 0` }}
      />

      {fileName}
    </FileInfo>
  );
};

const RemoveIcon = ({
  data,
  onRemove,
  removable,
}: {
  data: any;
  onRemove: () => void;
  removable: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const onMouseEnter = useCallback(() => setHover(true), []);
  const onMouseLeave = useCallback(() => setHover(false), []);
  const isInvalid = useMemo(() => checkInvalid(data), [data]);
  const fileInfo = useMemo(() => extractFileInfo(data), [data]);
  const { uploadStatus } = data;
  const hideRemoveIcon = useMemo(
    () => removable === false || uploadStatus === UPLOAD_STATUSES.UPLOADING,
    [removable, uploadStatus]
  );

  return (
    <div
      data-test-id={`file-item-remove-icon-${fileInfo.id}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hidden={hideRemoveIcon}
    >
      <Button.Icon
        onClick={onRemove}
        intent={getButtonIconIntent({ isInvalid, hover })}
        icon="cancel"
      />
    </div>
  );
};

export const FileItem = ({
  data,
  onChange,
  files,
  accept,
  maxSize,
  fileRemovePredicate = () => true,
}: {
  data: CustomFile;
  onChange: (newFiles: CustomFile[]) => void;
  files: CustomFile[];
  accept?: string;
  maxSize?: number;
  fileRemovePredicate?: (file: CustomFile) => boolean;
}) => {
  const removable = useMemo(
    () => fileRemovePredicate(data),
    [data, fileRemovePredicate]
  );
  const onRemove = useCallback(() => {
    onChange(remove((file: CustomFile) => data === file)(files));
  }, [onChange, data, files]);

  return (
    <FileWrapper>
      <FileContent data={data} accept={accept} maxSize={maxSize} />
      <RemoveIcon data={data} onRemove={onRemove} removable={removable} />
    </FileWrapper>
  );
};

interface FileListProps {
  files: CustomFile[];
  onChange: (newFiles: CustomFile[]) => void;
  accept?: string;
  maxSize?: number;
  fileRemovePredicate?: (file: CustomFile) => boolean;
}

const FileList = ({
  files,
  onChange,
  accept,
  maxSize,
  fileRemovePredicate,
}: FileListProps) => (
  <div style={{ marginBottom: theme.space.large }}>
    {files.map(file => {
      const key = get('id', file) || uniqueId(get('name', file));

      const createdAt = file.created_at;

      return (
        <div key={key}>
          <FileItem
            key={key}
            data={file}
            files={files}
            onChange={onChange}
            accept={accept}
            maxSize={maxSize}
            fileRemovePredicate={fileRemovePredicate}
          />

          {createdAt ? (
            <Typography.Text sx={{ ml: 'large' }}>
              Added {formatRelativeTime(new Date(createdAt))} ago
            </Typography.Text>
          ) : null}
        </div>
      );
    })}
  </div>
);

export default FileList;
