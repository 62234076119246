import { Grid, Typography } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

const AcknowledgementSection = () => {
  const { isXSmallScreen, isSmallScreen } = useWindowSize();

  return (
    <Grid.Col span={[24, 12, 12, 12, 12]}>
      <Typography.Text fontSize={16} fontWeight="semi-bold">
        Acknowledgement of Country
      </Typography.Text>
      <Typography.Text fontSize={16} sx={{ mt: 'large' }}>
        We acknowledge Aboriginal and Torres Strait Islander peoples as the
        First Australians and Traditional Custodians of the lands where we live,
        learn and work.
      </Typography.Text>

      <Typography.Text
        fontSize={16}
        fontWeight="semi-bold"
        sx={{ mt: 'large' }}
      >
        What we do
      </Typography.Text>
      {isXSmallScreen || isSmallScreen ? (
        <>
          <Typography.Text fontSize={16} sx={{ mt: 'large' }}>
            Employment Hero is an end-to-end HR, payroll and recruitment
          </Typography.Text>
          <Typography.Text fontSize={16} sx={{ mt: 'small' }}>
            solution designed for Australian businesses. Our suite of powerful
          </Typography.Text>
          <Typography.Text fontSize={16} sx={{ mt: 'small' }}>
            employment tools helps streamline your workday, so you can focus
          </Typography.Text>
          <Typography.Text fontSize={16} sx={{ mt: 'small' }}>
            on what matters.
          </Typography.Text>
        </>
      ) : (
        <Typography.Text fontSize={16} sx={{ mt: 'large' }}>
          Employment Hero is an end-to-end HR, payroll and recruitment solution
          designed for Australian businesses. Our suite of powerful employment
          tools helps streamline your workday, so you can focus on what matters.
        </Typography.Text>
      )}
    </Grid.Col>
  );
};

export default AcknowledgementSection;
