import { Box, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import useNavBarActions from '@shared/NavBarActions/hooks/useNavBarActions';
import useWindowSize from '@shared/hooks/useWindowSize';

import { TABLET_SIZE } from '../../constants';
import { useMegaMenu } from '../context';

const LoginButton = () => {
  const { showLoginRedirectDropdown } = useNavBarActions();

  const windowSize = useWindowSize();
  const { openMenuLogin } = useMegaMenu();

  const isTablet = windowSize?.width && windowSize.width <= TABLET_SIZE;

  const handleOpen = () => {
    openMenuLogin();
  };

  if (!showLoginRedirectDropdown || !isTablet) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        p: 'large',
      }}
      onClick={handleOpen}
    >
      <Image
        src={'/assets/images/user-outlined.svg'}
        sx={{ width: 14, height: 14 }}
        style={{ marginRight: '16px' }}
      />
      <Typography.Text fontWeight="semi-bold">Login</Typography.Text>
    </Box>
  );
};

export default LoginButton;
