import { Box, Icon, Typography } from '@hero-design/react';

import { MenuItem } from '@shared/NavMenu/types';

const BackButton = ({
  title,
  onBack,
}: Pick<MenuItem, 'title'> & { onBack?: () => void }) => (
  <Box
    p="large"
    sx={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    onClick={onBack}
  >
    <Icon icon="arrow-left" size="large" />

    <Typography.Text
      fontWeight="semi-bold"
      fontSize={16}
      sx={{
        ml: 'medium',
      }}
    >
      {`Main Menu / `}
      <span>&nbsp;</span>
    </Typography.Text>

    <Typography.Text intent="disabled">{title}</Typography.Text>
  </Box>
);

export default BackButton;
