/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { omit } from 'lodash/fp';

import React, { ImgHTMLAttributes } from 'react';
import { ImageProps } from 'next/image';

// Declare type based on https://github.com/vercel/next.js/issues/19764

type Props = {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
  quality?: number;
  placeholder?: string;
  onClick?: ImageProps['onClick'];
  objectFit?: ImageProps['objectFit'];
  blurDataURL?: ImageProps['blurDataURL'];
  layout?: ImageProps['layout'];
  priority?: boolean;
  sx?: {
    width?: number | string;
    height?: number | string;
    objectFit?: ImageProps['objectFit'];
  };
} & ImgHTMLAttributes<HTMLImageElement>;

const Image = (props: Props) => {
  const newProps = omit(['objectFit', 'blurDataURL', 'priority'])(props);

  return <img style={{ width: '100%', ...props.sx }} {...newProps} />;
};

export default Image;
