import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import styled, { useTheme } from 'styled-components';

import { Box, Collapse, Icon } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

import { checkLinkSupportLocale } from '../helpers';
import { MenuItem } from '../types';

import Content from './Content';
import { Accordion, List } from './MenuItemLevel3';

import { useMegaMenu } from './context';

const MenuItemContainer = styled.div<{
  isSelected: boolean;
  isSmallSize: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: ${({ isSelected, isSmallSize, theme }) =>
    isSelected && !isSmallSize
      ? `${theme.sizes.xsmall}px solid ${theme.colors.primary}`
      : 'none'};
  background-color: ${({ theme, isSelected, isSmallSize }) =>
    isSmallSize
      ? // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
        '#FCFBFF'
      : (isSelected && theme.colors.primaryLightBackground) || undefined};
  padding: ${({ theme }) => `${theme.space.medium}px ${theme.space.large}px`};
  padding-right: ${({ theme }) => `${theme.space.small}px`};

  transition: background-color 0.2s ease, padding 0.2s ease;

  &:hover {
    background-color: ${({ theme, isSmallSize }) =>
      isSmallSize ? undefined : theme.colors.primaryLightBackground};
    cursor: pointer;
  }
`;

type MenuItemLevel2Props = { menuData: MenuItem };

const MenuItemLevel2 = ({ menuData }: MenuItemLevel2Props) => {
  const theme = useTheme();
  const { isMediumScreen } = useWindowSize();
  const { selectedLevel2, setSelectedLevel2 } = useMegaMenu();
  const router = useRouter();

  const isItemSelected = selectedLevel2?.title === menuData.title;

  const sideIcon = useMemo(() => {
    if (isMediumScreen) {
      return isItemSelected ? 'carat-up' : 'carat-down';
    }

    return isItemSelected ? 'carat-right' : null;
  }, [isItemSelected, isMediumScreen]);

  const onItemClick = useCallback(() => {
    if (!isMediumScreen) {
      setSelectedLevel2(menuData);
      return;
    }

    setSelectedLevel2(selectedLevel2 ? undefined : menuData);
  }, [isMediumScreen, menuData, selectedLevel2, setSelectedLevel2]);

  const onItemHover = useCallback(() => {
    if (isMediumScreen) {
      return;
    }

    setSelectedLevel2(menuData);
  }, [isMediumScreen, menuData, setSelectedLevel2]);

  return (
    <>
      <MenuItemContainer
        isSelected={isItemSelected}
        isSmallSize={isMediumScreen}
        onClick={onItemClick}
        onMouseEnter={onItemHover}
      >
        <Content
          icon={menuData.icon}
          title={menuData.title}
          intent={isItemSelected && !isMediumScreen ? 'primary' : undefined}
        />

        {sideIcon && (
          <Icon
            size="xsmall"
            icon={sideIcon}
            sx={
              isMediumScreen
                ? {
                    mr: 'large',
                    fontSize: theme.sizes.small,
                    color: theme.colors.disabledText,
                  }
                : {}
            }
          />
        )}
      </MenuItemContainer>

      {isMediumScreen && (
        <Collapse open={isItemSelected}>
          <Box
            sx={{
              m: 'medium',
              mt: 0,
              pt: 'large',
              pb: 'large',
              ml: 'medium',
              pl: 'medium',
              gap: 'small',
              display: 'flex',
              position: 'relative',
              borderRadius: 'large',
              flexDirection: 'column',
              bgColor: 'defaultLightBackground',
              border: `${theme.borderWidths.base}px solid ${theme.colors.defaultBackground}`,
              padding: `${theme.space.large}px ${theme.space.xlarge}px 0px ${theme.space.small}px`,
            }}
          >
            {menuData.type === 'accordion' &&
              menuData.items?.[0] &&
              checkLinkSupportLocale(
                menuData.items?.[0].locales,
                router.locale
              ) && <Accordion.SmallScreens data={menuData.items[0]} />}

            {menuData.type === 'accordion' &&
              menuData.items?.[1] &&
              checkLinkSupportLocale(
                menuData.items?.[1].locales,
                router.locale
              ) && <Accordion.SmallScreens data={menuData.items[1]} />}

            {menuData.type === 'list' && <List.SmallScreens data={menuData} />}
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default MenuItemLevel2;
