import { useEffect } from 'react';

import Inspectlet from './core';

const useInspectletTracking = (inspectletId: string | undefined) => {
  useEffect(() => {
    if (inspectletId === undefined) return;
    Inspectlet.initialize(inspectletId);
  }, []);
};

export default useInspectletTracking;
