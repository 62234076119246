import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import { Box, Button, Select, Typography } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';
import { REGION_OPTIONS } from '@shared/utils/constants';
import getCookieValue from '@shared/utils/getCookieValue';

type RegionBannerProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const RegionBanner = ({ isVisible, setIsVisible }: RegionBannerProps) => {
  const router = useRouter();
  const { isMediumScreen } = useWindowSize();
  const { asPath, query, pathname, push, locale } = router;

  const location = useMemo(() => getCookieValue('NEXT_LOCATION'), []);

  const handleRegionChange = (region: string | undefined) => {
    push({ pathname, query }, asPath, { locale: region, shallow: false });
  };

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (locale === location) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [locale]);

  if (!isVisible) {
    return null;
  }

  const regionName =
    REGION_OPTIONS.find(option => option.value === locale)?.text ?? 'Australia';

  return (
    <Box
      sx={{
        p: 'medium',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMediumScreen ? 'small' : 'large',
        pl: isMediumScreen ? 'xxlarge' : 'xlarge',
        pr: isMediumScreen ? 'xxlarge' : 'xlarge',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          gap: isMediumScreen ? 'small' : 'large',
        }}
      >
        <Typography.Text
          sx={{
            textAlign: 'center',
            flex: isMediumScreen ? '1 1 100%' : 'none',
          }}
        >
          You&apos;re on our <strong>{regionName}</strong> website
        </Typography.Text>

        <Select
          sx={{ zIndex: 999 }}
          options={REGION_OPTIONS}
          placeholder="Change Region"
          onChange={handleRegionChange}
          data-test-id="select-change-region"
          size={isMediumScreen ? 'small' : 'medium'}
        />
      </Box>

      <Box
        sx={{
          right: 0,
          position: 'absolute',
          width: isMediumScreen ? '10%' : '20%',
        }}
      >
        <Button.Icon
          icon="cancel"
          onClick={handleCancelClick}
          data-test-id="close-button-region-banner"
        />
      </Box>
    </Box>
  );
};

export default RegionBanner;
