import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTheme } from 'styled-components';

import { Button, Divider, Modal, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';

import usePopulateProfile from './hooks/usePopulateProfile';

type OffboardEmployeePopupPropsType = {
  company: string;
  setIsOpenBanner: (value: boolean) => void;
  isOpenModal: boolean;
  setIsOpenModal: (value: boolean) => void;
};
const OffboardEmployeePopup = ({
  company,
  setIsOpenBanner,
  isOpenModal,
  setIsOpenModal,
}: OffboardEmployeePopupPropsType) => {
  const theme = useTheme();
  const router = useRouter();
  const { data: session } = useSession();
  const user = session?.user;
  const { fetchUserProfile } = useFetchUserProfile();

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const { fetcher: populateProfile, loading: isLoading } = usePopulateProfile({
    onCompleted: () => {
      router.push(`/user/${user?.friendlyId}`);
      fetchUserProfile();
      setIsOpenBanner(false);
      closeModal();
    },
  });

  const onContinue = useCallback(async () => {
    populateProfile();
  }, [user?.friendlyId, populateProfile]);

  const onCancel = useCallback(() => {
    setIsOpenBanner(true);
    closeModal();
  }, []);

  return (
    <>
      <Modal open={isOpenModal} size="xlarge">
        <div
          style={{
            backgroundColor: theme.colors.neutralLightBackground,
            display: 'flex',
            justifyContent: 'center',
            borderTopLeftRadius: theme.radii.base,
            borderTopRightRadius: theme.radii.base,
          }}
        >
          <Image
            src={'/assets/images/offboard-employee.png'}
            alt="populate offboard employee's profile"
            sx={{
              width: '180px',
            }}
          />
        </div>

        <Modal.Body>
          <Typography.Title
            level={3}
            sx={{ mt: theme.space.xxlarge, mb: theme.space.medium }}
          >
            Let us create your Swag Profile?
          </Typography.Title>

          <Typography.Text
            intent="subdued"
            style={{
              marginBottom: theme.space.large,
            }}
          >
            {`Let us populate your Swag profile with information from your
            Employment Hero ${company} Profile, including your name, avatar,
            contact details and verified employment history at ${company}. Simply
            press continue to automatically create your Swag profile. We
            recommend you check the information in your profile is up-to-date
            and accurate, then you're all set!`}
          </Typography.Text>

          <Divider />
        </Modal.Body>

        <Modal.Footer>
          <Button
            data-test-id="cancelButton"
            variant="text"
            text="Cancel"
            onClick={onCancel}
            size="large"
          />

          <Button
            data-test-id="continueButton"
            text="Continue"
            onClick={onContinue}
            size="large"
            loading={isLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OffboardEmployeePopup;
