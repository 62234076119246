import React, { ComponentProps } from 'react';

import { Icon } from '@hero-design/react';

const ChatGPTIcon = ({
  sx,
  ...props
}: Omit<ComponentProps<typeof Icon>, 'icon'>) => (
  <Icon
    {...props}
    size="medium"
    icon="magic-outlined"
    data-test-id="chat-gpt-icon"
    sx={{
      ...sx,
      backgroundColor: 'yellow-light-60',
      borderRadius: '50%',
      p: 'xsmall',
    }}
  />
);

export default ChatGPTIcon;
