import camelCase from 'lodash/fp/camelCase';
import isArray from 'lodash/fp/isArray';
import keys from 'lodash/fp/keys';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';
import snakeCase from 'lodash/fp/snakeCase';

type ConvertFn = (input: string) => string;

const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj != null && Object.getPrototypeOf(obj).constructor === Object;

const processKeys =
  (convert: ConvertFn) =>
  (
    obj: unknown,
    ignoredKey?: RegExp | null
  ): unknown | Record<string, unknown> => {
    if (isArray(obj)) {
      return map(arrayItem => processKeys(convert)(arrayItem, ignoredKey), obj);
    }

    if (isObject(obj)) {
      return reduce(
        (result, key) => {
          const isIgnoredKey = ignoredKey != null && ignoredKey.test(key);

          return {
            ...result,
            [isIgnoredKey ? key : convert(key)]: processKeys(convert)(
              obj[key],
              ignoredKey
            ),
          };
        },
        {},
        keys(obj)
      );
    }

    return obj;
  };

// TODO: Support TypeScript
export const decamelizeKeys = processKeys(snakeCase);
export const camelizeKeys = processKeys(camelCase);
