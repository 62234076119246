import { Option } from './types';

export enum LOGIN_TYPES {
  BUSINESS_HR = 'BUSINESS_HR',
  BUSINESS_PAYROLL = 'BUSINESS_PAYROLL',
  BUSINESS_RECRUITMENT = 'BUSINESS_RECRUITMENT',
  JOBSEEKER = 'JOBSEEKER',
}

export const LOGIN_OPTIONS: Option[] = [
  {
    name: LOGIN_TYPES.BUSINESS_HR,
    title: 'HR',
    url: 'https://secure.employmenthero.com/users/sign_in',
  },
  {
    name: LOGIN_TYPES.BUSINESS_PAYROLL,
    title: 'Payroll',
    url: 'https://employmenthero.yourpayroll.com.au',
  },
  {
    name: LOGIN_TYPES.BUSINESS_RECRUITMENT,
    title: 'Recruitment',
    url: 'https://secure.employmenthero.com/users/sign_in',
  },
  {
    name: LOGIN_TYPES.JOBSEEKER,
    title: 'Job seeker job board',
    url: 'https://jobs.swagapp.com/sign_in',
  },
];

export const DOWNLOAD_APP_URL =
  'https://apps.apple.com/us/app/swag-by-employment-hero/id1171253572';
