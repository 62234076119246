import { getSession } from 'next-auth/react';

import mixpanel from 'mixpanel-browser';

import * as Sentry from '@sentry/nextjs';

const identifyMixpanel = (userId: string) => {
  const mpUserId = mixpanel.get_property('$user_id');

  if (!mixpanel.get_property('sync_from_sm_hub')) {
    mixpanel.identify(userId);
    return;
  }

  if (!mpUserId) {
    mixpanel.identify(userId);
    return;
  }

  if (mpUserId === userId) {
    return;
  }

  mixpanel.reset();
  mixpanel.identify(userId);
};

export const handleSignInSideEffect = async () => {
  const session = await getSession();

  // Sentry
  Sentry.setUser({
    id: session?.user.id || '',
    email: session?.user.email || '',
  });

  // Mixpanel
  if (session?.user.id) {
    identifyMixpanel(session.user.id);
  }

  if (session?.user.email) {
    mixpanel.people.set('email', session.user.email);
  }
};

export const handleSignOutSideEffect = () => {
  // Sentry
  Sentry.setUser(null);

  // Mixpanel
  mixpanel.reset();
};
