import { DownloadAppData, DownloadAppType } from './types';

export const TABLET_SIZE = 992;

export const DOWNLOAD_APP_TYPES = {
  EH_JOBS: 'EH_JOBS',
  EH_WORK_EMPLOYEE: 'EH_WORK_EMPLOYEE',
  EH_WORK_EMPLOYER: 'EH_WORK_EMPLOYER',
} as const;

export const DOWNLOAD_APP_DATA: Record<DownloadAppType, DownloadAppData> = {
  [DOWNLOAD_APP_TYPES.EH_WORK_EMPLOYEE]: {
    title: 'app',
    description: 'Find out more',
    buttonText: 'Download the Swag app',
    qrCode: 'assets/images/eh-work-employee-download-qr.svg',
  },
  [DOWNLOAD_APP_TYPES.EH_WORK_EMPLOYER]: {
    title: 'app',
    description: 'Find out more',
    buttonText: 'Download the Swag app',
    qrCode: 'assets/images/eh-work-employer-download-qr.svg',
  },
  [DOWNLOAD_APP_TYPES.EH_JOBS]: {
    title: 'Casual Jobs app',
    description: 'Find out more',
    buttonText: 'Download Employment Hero casual jobs app',
    qrCode: 'assets/images/eh-casual-jobs-download-qr.svg',
  },
};

export const COUNTRIES_LOCALES_MAP = {
  AU: 'au',
  GB: 'uk',
  MY: 'my',
  NZ: 'nz',
  SG: 'sg',
};
