export const LANDING_PAGE_SIZE = 4;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_ITEM_PER_PAGE = 10;

export const BLUR_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNU0dOrBwAB/wEB1j0rYwAAAABJRU5ErkJggg==';

export const FETCH_JWT_ERROR = 'fetchJwtError';
export const LOCAL_JWT_HEADER_KEY = 'Eh-Auth';

export const SWAG_IN_APP_REFERRER = 'swag';
export const FORCE_PASSWORD_RESET_CODE = 'force_password_reset';
export const FORCE_PASSWORD_RESET_MESSAGE = 'Force password reset';

export const MIN_PROFILE_STRENGTH_SCORE = 20; //  applied for EH users only, if the score is less than 20, the user will be redirected to the onboarding
export const ONBOARDING_PATH = '/setup_profile';

export const SIGN_UP_ERROR_MESSAGE =
  'Oops, something went wrong, please try again';

export enum SourceSetupJobPreferences {
  MANUAL_COMPLETE_PROFILE = 'manual_complete_profile',
  UPLOAD_CV = 'upload_cv',
  POPULATE_PROFILE = 'populate_profile',
}
