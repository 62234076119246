import { Box, Divider, Grid } from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

import AcknowledgementSection from './components/AcknowledgementSection';
import CountrySelector from './components/CountrySelector';
import LegalLinks from './components/LegalLinks';
import MarketoEmailSubscription from './components/MarketoEmailSubscription';
import Reference from './components/Reference';

import data from './data.json';

const SiteFooterV2 = () => {
  const { isSmallScreen } = useWindowSize();

  return (
    <Box sx={{ maxWidth: '1200px', m: '0 auto', p: 'large' }}>
      <Grid>
        <Grid.Row gutter={['xlarge', isSmallScreen ? 'xxxxlarge' : 'xlarge']}>
          {data.references.map(column => (
            <Grid.Col key={column.title} span={[12, 8, 8, 8, 8]}>
              <Reference title={column.title} links={column.links} />
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid>

      <Divider marginY="xlarge" />

      <Grid>
        <Grid.Row gutter={['xlarge', isSmallScreen ? 'xxxxlarge' : 'xlarge']}>
          <AcknowledgementSection />
          <MarketoEmailSubscription />
        </Grid.Row>
      </Grid>

      <Divider marginY="xlarge" />

      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: isSmallScreen ? 'flex-start' : 'space-between',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
        }}
      >
        <LegalLinks />
        <Box
          sx={{
            mt: isSmallScreen ? 'xlarge' : '0',
          }}
        >
          <CountrySelector />
        </Box>
      </Box>
    </Box>
  );
};

export default SiteFooterV2;
