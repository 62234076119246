/**
 * Follow bootstrap breakpoint
 *
 * @see https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
 */
export const BREAK_POINT = {
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

/**
 * An utility to quickly config different styles on screen breakpoints. Returns
 * a function which generates a style rule templated injected to a
 * styled-components body.
 *
 * @example
 *   // Control on small screen (_>=575px_)
 *   styled.div`
 *     ${mediaQueries.sm} {
 *       width: 50%;
 *     }
 *   `;
 *
 * @example
 *   // Control on medium screen (_>=768px_)
 *   styled.div`
 *     ${mediaQueries.md} {
 *       width: 50%;
 *     }
 *   `;
 *
 * @example
 *   // Control on large screen (_>=992px_)
 *   styled.div`
 *     ${mediaQueries.lg} {
 *       width: 50%;
 *     }
 *   `;
 *
 * @example
 *   // Control on extra large screen (_>=1200px_)
 *   styled.div`
 *     ${mediaQueries.xl} {
 *       width: 50%;
 *     }
 *   `;
 *
 * @example
 *   // Control on extra extra large screen (_>=1400px_)
 *   styled.div`
 *     ${mediaQueries.xxl} {
 *       width: 50%;
 *     }
 *   `;
 */

const mediaQueries = (key: keyof typeof BREAK_POINT) =>
  `@media (min-width: ${BREAK_POINT[key]}px)`;

// eslint-disable-next-line immutable/no-mutation
mediaQueries.BREAK_POINT = BREAK_POINT;

// eslint-disable-next-line immutable/no-mutation
mediaQueries.sm = mediaQueries('sm');

// eslint-disable-next-line immutable/no-mutation
mediaQueries.md = mediaQueries('md');

// eslint-disable-next-line immutable/no-mutation
mediaQueries.lg = mediaQueries('lg');

// eslint-disable-next-line immutable/no-mutation
mediaQueries.xl = mediaQueries('xl');

// eslint-disable-next-line immutable/no-mutation
mediaQueries.xxl = mediaQueries('xxl');

export default mediaQueries;
