import React, { ChangeEvent, ReactNode, useState } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import styled, { useTheme } from 'styled-components';

import { Box, Input, Tooltip, Typography } from '@hero-design/react';
import { SxObject } from '@hero-design/react/types/components/common/ThemeInterfaces';

import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

import ChatGPTButton, { ChatGPTButtonProps } from './chatGPT/ChatGPTButton';
import ChatGPTIcon from './chatGPT/ChatGPTIcon';

const StyledInput = styled(Input.TextArea)`
  #${props => props.id} {
    background: transparent;
  }
`;

const StyledFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${mediaMaxQueries.md} {
    flex-direction: column;
    align-items: flex-start;
    gap: ${themeGet('space.small')};
  }
`;

interface ChatGPTTextAreaInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps?: Omit<
    React.ComponentProps<typeof Input.TextArea>,
    'placeholder'
  > & {
    placeholder?: string | ReactNode;
  };
  labelProps?: LabelProps;
  extraProps: ExtraProps &
    Pick<ChatGPTButtonProps, 'fetchMessageConfigs' | 'trackMixPanel'>;
  sx?: SxObject;
}

const ChatGPTTextAreaInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {},
  labelProps = {},
  extraProps,
  sx,
}: ChatGPTTextAreaInputProps<TFieldValues, TName>) => {
  const [helperPrompt, setHelperPrompt] = useState<ReactNode | null>(null);
  const theme = useTheme();

  const { placeholder, autoResize, disabled, autoComplete } = inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, onBlur, name } = field;
  const {
    error: extraError,
    'data-test-id': dataTestId,
    errorStyle,
    fetchMessageConfigs,
    trackMixPanel,
  } = extraProps;
  const hasError = error != null || extraError != null;
  const onInputChange = React.useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
    [onChange]
  );

  const id = `hero-theme-text-area-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={
          <StyledFlexBox>
            {labelText}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography.Text
                fontSize={12}
                intent="subdued"
                sx={{ mr: 'small' }}
              >
                Magically generate a draft message
              </Typography.Text>

              <Tooltip
                content="Generate a summary based on prompts and keywords you enter below."
                target={
                  <ChatGPTButton
                    value={value}
                    setEditorValue={newValue => {
                      onChange(newValue);
                      setHelperPrompt(
                        <>
                          Don’t like your summary? Press the <ChatGPTIcon /> to
                          generate another version. Add more prompts and
                          keywords to help create better versions.
                        </>
                      );
                    }}
                    fetchMessageConfigs={fetchMessageConfigs}
                    trackMixPanel={trackMixPanel}
                  />
                }
              />
            </Box>
          </StyledFlexBox>
        }
        subText={helperPrompt}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <Box
            sx={{
              position: 'relative',
              background: theme.colors.defaultLightBackground,
            }}
          >
            {!value ? (
              <Typography.Text
                tagName="div"
                intent="subdued"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  padding: theme.space.small,
                }}
              >
                {placeholder}
              </Typography.Text>
            ) : null}

            <StyledInput
              id={id}
              name={name}
              value={value}
              onChange={onInputChange}
              onBlur={onBlur}
              invalid={hasError}
              autoResize={autoResize}
              disabled={disabled}
              autoComplete={autoComplete}
              sx={sx}
            />
          </Box>
        }
      />

      {hasError && (
        <Error
          style={errorStyle}
          text={(error?.message as string) || (extraError as string)}
        />
      )}
    </InputContainer>
  );
};

export default ChatGPTTextAreaInput;
