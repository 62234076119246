import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { DatePicker } from '@hero-design/react';

import ErrorBoundaryMobile from '@packages/error-boundary/ErrorBoundaryMobile';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

import { DATE_PICKER_TYPE } from './constants';

interface DateInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  inputProps?: React.ComponentProps<typeof DatePicker>;
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  datePickerType?: string;
}

const DateInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {},
  labelProps = {},
  extraProps = {},
  datePickerType = DATE_PICKER_TYPE.NORMAL,
}: DateInputProps<TFieldValues, TName>) => {
  const {
    placeholder,
    prefix,
    disabled,
    format,
    minDate,
    maxDate,
    autoComplete,
    style,
  } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { error } = fieldState;
  const { value, onChange, onBlur, name } = field;
  const { error: extraError, 'data-test-id': dataTestId } = extraProps;
  const hasError = error != null || extraError != null;

  const id = `hero-theme-date-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <ErrorBoundaryMobile>
            {datePickerType === DATE_PICKER_TYPE.MONTH ? (
              <DatePicker.Month
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                prefix={prefix}
                invalid={hasError}
                disabled={disabled}
                format={format}
                minDate={minDate}
                maxDate={maxDate}
                style={style}
                autoComplete={autoComplete}
              />
            ) : (
              <DatePicker
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                prefix={prefix}
                invalid={hasError}
                disabled={disabled}
                format={format}
                minDate={minDate}
                maxDate={maxDate}
                style={style}
                autoComplete={autoComplete}
              />
            )}
          </ErrorBoundaryMobile>
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default DateInput;
