import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import toNumber from 'lodash/fp/toNumber';
import trim from 'lodash/fp/trim';

import { Validate } from 'react-hook-form';

import { isValidPhoneNumber } from 'libphonenumber-js';

import {
  CustomFile,
  ProcessedFile,
} from '@packages/hero-theme/form/FileInput/types';

import { IsDefined, Required } from './types';

import { calcPasswordStrength, PASSWORD_SCORE } from '../passwordStrength';

export const isDefined: IsDefined = value => !isNil(value) && value !== '';

export const required: Required = value =>
  isDefined(value) ? undefined : 'Required';

// the email regex come from our current backend devise
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const emailPattern: Validate<string | undefined> = email => {
  if (typeof email === 'string') {
    return !isNil(email) && email.match(EMAIL_REGEX)
      ? undefined
      : 'Please use a valid email address';
  }
};

export const strongPassword: Validate<string | boolean | undefined> = value => {
  if (typeof value === 'string') {
    return calcPasswordStrength(value) === PASSWORD_SCORE.WEAK
      ? 'Please use a stronger password'
      : undefined;
  }
};

export const requiredFieldNotEmpty: Validate<
  string | boolean | undefined | number | Array<unknown> | CustomFile
> = value => {
  if (typeof value === 'undefined') {
    return 'This field is required';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return isEmpty(trim(value.toString()))
      ? 'This field is required'
      : undefined;
  }

  if (value === null) {
    return 'This field is required';
  }

  if (Array.isArray(value)) {
    return isEmpty(value) ? 'This field is required' : undefined;
  }
};

export const requiredValidNumberField: Validate<
  number | undefined | string
> = value => {
  if (typeof value === 'undefined') {
    return 'This field is required';
  }

  return Number(value) <= 0 ? 'This field is required' : undefined;
};

export const requiredFileNotEmpty: Validate<
  ProcessedFile | undefined
> = value => {
  if (typeof value !== 'undefined') {
    return value.file.size === 0 ? 'File can not be empty' : undefined;
  }
};

export const requiredMaxLength =
  (maxLength: number): Validate<string> =>
  value =>
    !!value && value.length > maxLength
      ? `Maximum is ${maxLength} characters.`
      : undefined;

const DATE_REGEX = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

export const requiredValidDate: Validate<
  string | boolean | undefined
> = value => {
  if (typeof value === 'string') {
    return value && value.match(DATE_REGEX)
      ? undefined
      : 'Please use a valid date YYYY-MM-DD';
  }
};

const MONTH_YEAR_DATE_REGEX = /^\d{4}-(0?[1-9]|1[012])$/;

export const requiredValidYearMonthDate: Validate<
  string | boolean | undefined
> = value => {
  if (typeof value === 'string') {
    return value.match(MONTH_YEAR_DATE_REGEX)
      ? undefined
      : 'Please use a valid date YYYY-MM';
  }
};

export const requiredNumber: Validate<string | undefined> = value => {
  if (typeof value === 'undefined') {
    return 'Invalid number';
  }

  if (typeof value === 'string') {
    const numberValue = toNumber(value);

    if (Number.isNaN(numberValue)) {
      return 'Invalid number';
    }
  }
};

export const requiredValidAUNZPhoneNumber: Validate<
  string | undefined
> = value => {
  if (!value) {
    return 'This field is required';
  }

  if (!value.startsWith('+61') && !value.startsWith('+64')) {
    return 'The phone number must start with +61 or +64';
  }

  if (!isValidPhoneNumber(value, 'AU') && !isValidPhoneNumber(value, 'NZ')) {
    return 'Invalid Australia or New Zealand phone number';
  }
};

export const requiredValidPhoneNumber: Validate<unknown> = value => {
  if (typeof value !== 'string' || !value) {
    return undefined;
  }

  if (!isValidPhoneNumber(value)) {
    return 'The phone number is not valid';
  }
};

export const endDateAfterStartDate = (
  startDateValue: string | undefined,
  endDateValue: string | undefined
) => {
  if (
    startDateValue &&
    endDateValue &&
    new Date(endDateValue) < new Date(startDateValue)
  ) {
    return "End date can't be before start date";
  }

  return true;
};
