declare global {
  interface Window {
    dataLayer: {
      push: (data: { event: string; [key: string]: unknown }) => void;
    };
  }
}

const trackGTMEvent = (event: string, opts: Record<string, unknown> = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      ...opts,
    });
  }
};

const convertStringToSHA256 = async (str: string) => {
  const encodedString = new TextEncoder().encode(str);
  const hashedString = await window.crypto.subtle.digest(
    'SHA-256',
    encodedString
  );

  return Array.from(new Uint8Array(hashedString))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
};

export { convertStringToSHA256, trackGTMEvent };
export * from './events';
