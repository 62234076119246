import { ComponentProps, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Box, Button, ButtonProps, Typography } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import useWindowSize from '@shared/hooks/useWindowSize';

import { normalizeLinkWithLocale } from '../helpers';
import { MenuItem } from '../types';

import { calculateFooterHeight } from './MenuLayout/helpers';

const HoverBox = styled(Box)`
  transition: background-color 0.2s ease, padding 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    cursor: pointer;
  }
`;

// LinkProps will be the same as <a> tag props
// but with an additional intent prop
type LinkProps = {
  intent?: ComponentProps<typeof Typography.Text>['intent'];
} & ComponentProps<'a'>;

const StyledLink = styled.a<LinkProps>`
  color: ${({ theme, intent }) =>
    intent === 'subdued'
      ? theme.colors.subduedText
      : theme.colors.text} !important;

  &:hover {
    text-decoration: underline !important;
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

const Link = ({
  children,
  intent,
  item,
}: LinkProps & { children: string; item: MenuItem }) => {
  const { locale } = useRouter();

  const href = normalizeLinkWithLocale(item, locale);

  if (!href) {
    return null;
  }

  return (
    <StyledLink href={href} intent={intent}>
      {children}
    </StyledLink>
  );
};

const CustomButton = (props: ButtonProps) => {
  const { isMediumScreen } = useWindowSize();
  const [hover, setHover] = useState(false);
  const onMouseEnter = useCallback(() => setHover(true), []);
  const onMouseLeave = useCallback(() => setHover(false), []);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: isMediumScreen ? '100%' : undefined,
      }}
    >
      <Button
        {...props}
        style={{
          height: '40px',
          fontSize: '16px',
          fontWeight: '600',
          borderWidth: '2px',
          borderRadius: '32px',
          paddingLeft: '20px',
          paddingRight: '20px',
          // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
          color: hover ? '#460078' : undefined,
          // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
          borderColor: hover ? '#460078' : undefined,
        }}
      />
    </div>
  );
};

type MenuBodyProps = {
  isRegionBannerOpened: boolean;
  isHideDownloadButton: boolean;
  isHaveButtons: boolean;
};

// eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
const BodyMobileContainer = styled.div<MenuBodyProps>`
  min-height: ${({
    isRegionBannerOpened,
    isHideDownloadButton,
    isHaveButtons,
  }) =>
    calculateFooterHeight(
      isRegionBannerOpened,
      isHideDownloadButton,
      isHaveButtons
    )};

  border-right: ${({ theme }) =>
    `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`};
  background-color: #fcfbff;

  ${mediaMaxQueries.sm} {
    min-height: ${({
      isRegionBannerOpened,
      isHideDownloadButton,
      isHaveButtons,
    }) =>
      calculateFooterHeight(
        isRegionBannerOpened,
        isHideDownloadButton,
        isHaveButtons,
        'small'
      )};
  }
`;

export { BodyMobileContainer, CustomButton, HoverBox, Link };
