// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';

const denyUrls = [
  // Tracking script
  /inspectlet.js/,

  // Browser extensions
  /extensions\//i,
  /^chrome:\/\//i,

  // One trust
  /otBannerSdk.js$/,
  /otSDKStub.js$/,

  // GTM tracking
  /gtag\/js$/,
  /gtm.js$/,
];

const ignoreErrors = [
  // Ignore noise caused by 3rd party extension: Grammarly
  /ResizeObserver loop limit exceeded/,
  // Ignore safely, this's like framedrop, no affection
  /ResizeObserver loop completed with undelivered notifications\./,
  // HeyTap Browser issue
  /Cannot read property 'getReadModeExtract' of undefined/,
  /Cannot read property 'getReadModeRender' of undefined/,
  /Cannot read property 'getReadModeConfig' of undefined/,
  // Network error when loading webpack chunk
  /Loading chunk [0-9]+ failed./,
  // Network error / Offline from client side, we can't handle them
  /Failed to fetch/,
];

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors,
  denyUrls,
  beforeSend(event, hint) {
    // Internal reCaptcha error
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
  environment: SENTRY_ENVIRONMENT,
});
