import React from 'react';
import { useTheme } from 'styled-components';

import { Banner, Box, Button, Icon, Typography } from '@hero-design/react';

type PopulateProfileInforBannerPropsType = {
  setIsOpenBanner: (value: boolean) => void;
  setIsOpenModal: (value: boolean) => void;
};
const PopulateProfileInfoBanner = ({
  setIsOpenBanner,
  setIsOpenModal,
}: PopulateProfileInforBannerPropsType) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <Banner
        icon={null}
        content={
          <Box
            sx={{
              display: 'flex',
              ml: 'xxxxlarge',
              mr: 'xxxxlarge',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 'medium',
              mb: 'medium',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  sx={{ mr: 'small' }}
                  icon="circle-info"
                  intent="info"
                  size="medium"
                />

                <Typography.Title level={4}>
                  Let us create your Profile
                </Typography.Title>
              </Box>

              <Typography.Text>
                Save time filling in the details by letting us automatically
                populate your profile in 1 click!
              </Typography.Text>
            </Box>

            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                text="Learn more"
                variant="filled"
                sx={{ mr: 'medium' }}
                onClick={() => setIsOpenModal(true)}
              />

              <Button.Icon
                icon="cancel"
                sx={{ color: theme.colors.black }}
                size="large"
                onClick={() => setIsOpenBanner(false)}
                data-test-id="xBtn"
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default PopulateProfileInfoBanner;
