import { useEffect } from 'react';
import { signIn } from 'next-auth/react';
import styled from 'styled-components';

import { Box, Divider, Typography } from '@hero-design/react';

import useNavBarActions from '@shared/NavBarActions/hooks/useNavBarActions';
import useWindowSize from '@shared/hooks/useWindowSize';

import {
  LOGIN_OPTIONS,
  LOGIN_TYPES,
} from 'src/modules/auth/components/LoginRedirectDropdown/constants';
import { Option } from 'src/modules/auth/components/LoginRedirectDropdown/types';

import BackButton from './BackButton';

import { TABLET_SIZE } from '../../constants';
import { useMegaMenu } from '../context';

const MenuItem = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLightBackground};
  }
  height: 72px;
`;

const MenuLogin = () => {
  const { closeMegaMenu, closeMenuLogin } = useMegaMenu();
  const { encodedCallbackUrl } = useNavBarActions();
  const { width } = useWindowSize();

  const handleChooseOption = (option: Option) => {
    closeMegaMenu();

    if (option.name === LOGIN_TYPES.JOBSEEKER) {
      signIn('EmploymentHero', { callbackUrl: encodedCallbackUrl });
      return;
    }

    const selectedOption = LOGIN_OPTIONS.find(o => o.name === option.name);

    if (selectedOption?.url) {
      window.open(selectedOption.url, '_blank');
    }
  };

  useEffect(() => {
    if (width && width > TABLET_SIZE) {
      closeMegaMenu();
    }
  }, [width]);

  return (
    <Box>
      <BackButton title="Login" onBack={closeMenuLogin} />

      {LOGIN_OPTIONS.map(option => (
        <Box key={option.name}>
          <MenuItem onClick={() => handleChooseOption(option)}>
            <Typography.Text sx={{ ml: 'large' }} fontSize={16}>
              {option.title}
            </Typography.Text>
          </MenuItem>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default MenuLogin;
