const TRACK_ATS_EH_JOB_BOARD_VISIT = 'ATS EH Job Board Visits';
const TRACK_ATS_INDUSTRY_FILTER_JOB_BOARD =
  'ATS EH Job Board Filter - Industry';
const TRACK_ATS_REDESIGNED_CAREER_PAGE_VISIT =
  'ATS Organisation Customised Career Page - Visit';
const TRACK_ATS_CAREER_PAGE_APPLY_ROLE_CLICK =
  'ATS EH Career Page Apply - Role Click';
const TRACK_ATS_SUBMIT_APPLICATION = 'ATS Submit Application';
const TRACK_ATS_SCAN_QR_POSTER_TO_CAREER_PAGE =
  'ATS Scan QR Poster Redirect To Career Page';
const TRACK_ATS_ROLE_DETAILS_CLICK_APPLY_NOW =
  'ATS Role Details Click Apply Now';
const TRACK_ATS_CANDIDATE_CREATES_ACCOUNT_VERIFICATION_EMAIL =
  'ATS Candidate Creates Account (Verification Email)';
const TRACK_ATS_CANDIDATE_TOGGLE_PUBLIC_PROFILE =
  'ATS Candidate Toggle Public Profile';
const TRACK_ATS_CANDIDATE_VIEWS_PROFILE = 'ATS Candidate Views Profile';
const TRACK_ATS_DIRECT_CANDIDATE_ACCOUNT_CREATION =
  'ATS Direct Candidate Account Creation';
const TRACK_ATS_MANUALLY_SIGN_UP_ACCOUNT_CREATION =
  'ATS Manually Sign Up Account Creation';
const TRACK_ATS_JORA_SIGN_UP_ACCOUNT_CREATION =
  'ATS Jora Sign Up Account Creation';
const TRACK_ATS_ADZUNA_SIGN_UP_ACCOUNT_CREATION =
  'ATS Adzuna Sign Up Account Creation';
const TRACK_ATS_INDEED_SIGN_UP_ACCOUNT_CREATION =
  'ATS Indeed Sign Up Account Creation';
const TRACK_ATS_LINKEDIN_SIGN_UP_ACCOUNT_CREATION =
  'ATS LinkedIn Sign Up Account Creation';
const TRACK_SWAG_JOBS_VIEW_ACCOUNT_SIGN_UP = 'Swag Jobs View Account Signup';

const TRACK_ATS_QR_CODE_APPLICATION = 'ATS QR Code Application';
const TRACK_ATS_SHARE_BUTTON_COPY_LINK = 'ATS Share Button - Copy Link';
const TRACK_ATS_RESEND_VERIFICATION_EMAIL =
  'ATS EH Career Page - Resend Verification';

const TRACK_ATS_WITHDRAW_APPLICATION = 'ATS Withdraw Application';
const TRACK_ATS_JOB_OVERVIEW = 'ATS Job Overview';
const TRACK_ATS_JOB_APPLICATION = 'ATS Job Application';
const TRACK_ATS_SAVE_JOB = 'ATS Save Job';
const TRACK_ATS_VIEW_IN_PROGRESS_APPLICATIONS =
  'ATS List Applications - View In Progress list';
const TRACK_ATS_VIEW_ARCHIVED_APPLICATIONS =
  'ATS List Applications - View Archived list';
const TRACK_ATS_VISIT_APPLICATION_PAGE =
  'ATS List Applications - Visit Application Page';

const TRACK_ATS_APP_STORE_CLICK = 'ATS Mobile Adoption - App store button';
const TRACK_ATS_PLAY_STORE_CLICK = 'ATS Mobile Adoption - Play store button';

const TRACK_SWAG_VIEW_CV = 'SWAG CV - View CV';
const TRACK_SWAG_SWITCHED_PUBLIC_ON = 'SWAG CV - Switched public on';
const TRACK_SWAG_COMPLETE_CV = 'SWAG CV - Complete CV';
const TRACK_SWAG_POWERED_BY_EH_STATEMENT_CLICK =
  'SWAG CV - Powered By EH statement clicked';
const TRACK_SWAG_FIND_OUT_MORE_BUTTON_CLICK =
  'SWAG CV - Find out more button clicked';

const TRACK_ATS_SAVED_JOB_PAGE_VISIT = 'ATS Saved Jobs Page - Visit';
const TRACK_SWAG_PROFILE_COPY_LINK = 'SWAG CV - Copy link button clicked';
const TRACK_SWAG_PROFILE_PREVIEW_PUBLIC_MODE =
  'SWAG CV - Preview public mode button clicked';
const TRACK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_VISIT =
  'SWAG CV - SCMP Fake Door Tail - Visit';
const CLICK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_GET_STARTED_BUTTON =
  'SWAG CV - SCMP Fake Door Tail - Click Get Started Button';
const CLICK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_EDIT_BUTTON =
  'SWAG CV - SCMP Fake Door Tail - Click Edit Button';

const TRACK_SWAG_INCENTIVE_PROMOTE_BANNER_CLICK =
  'SWAG CV - Incentive Visit Profile Click';
const TRACK_SWAG_CHOOSE_PDF_TEMPLATE_BUTTON_CLICK =
  'SWAG CV - Choose PDF Template button clicked';
const TRACK_SWAG_DOWNLOAD_PDF_CV_BUTTON_CLICK =
  'SWAG CV - Download PDF CV button clicked';

const TRACK_ATS_CV_PARSING_DIRECT_APPLICATION_RESUME_UPLOAD =
  'ATS CV Parsing - Upload CV and auto-populate direct application';

const TRACK_ATS_VIDEO_INTRODUCTION_COPY_CLIPBOARD_CLICK =
  'ATS Video Introduction - Copy Clipboard Click';
const TRACK_ATS_VIDEO_INTRODUCTION_DELETE_VIDEO_CLICK =
  'ATS Video Introduction - Delete Video Click';

const ROUTING_PATHS = {
  ORGANISATION_DETAIL: '/jobs/organisations/[id]',
};

const TRACK_ROLE_CLICK = {
  [ROUTING_PATHS.ORGANISATION_DETAIL]: TRACK_ATS_CAREER_PAGE_APPLY_ROLE_CLICK,
};

const TRACK_ATS_VIDEO_INTRODUCTION_GENERATE_QR_CLICK =
  'ATS Video Introduction - Generate QR Click';
const TRACK_ATS_VIDEO_INTRODUCTION_PLAY_CLICK =
  'ATS Video Introduction - Play Click';
const TRACK_ATS_VIDEO_INTRODUCTION_PUBLISH_CLICK =
  'ATS Video Introduction - Publish Click';
const TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_SUBMIT =
  'ATS Video Introduction - Send Report Submit';
const TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_SUCCESS =
  'ATS Video Introduction - Send Report ERROR';
const TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_ERROR =
  'ATS Video Introduction - Send Report ERROR';
const TRACK_ATS_VIDEO_INTRODUCTION_COPY_CLIPBOARD_ERROR =
  'ATS Video Introduction - Copy Clipboard Error';
const TRACK_ATS_VIDEO_INTRODUCTION_DELETE_VIDEO_ERROR =
  'ATS Video Introduction - Delete Video Error';
const TRACK_ATS_VIDEO_INTRODUCTION_REQUEST_VIDEO_ERROR =
  'ATS Video Introduction - Request Video Error';
const TRACK_ATS_VIDEO_INTRODUCTION_GENERATE_QR_ERROR =
  'ATS Video Introduction - Generate QR Error';
const TRACK_ATS_VIDEO_INTRODUCTION_UPLOAD_ERROR =
  'ATS Video Introduction - Upload Error';
const TRACK_ATS_VIDEO_INTRODUCTION_OVER_SIZE_LIMIT =
  'ATS Video Introduction - Over Size Limit';
const TRACK_ATS_VIDEO_INTRODUCTION_EXCEED_LIMIT_UPLOAD_SHOW =
  'ATS Video Introduction - Exceed Limit Upload';

const TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_CLICK =
  'ATS Application - Generate Cover Letter Click';
const TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_MAGIC_WAND_CLICK =
  'ATS Application - Generate Cover Letter Magic Wand Click';
const TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_SAVE_CLICK =
  'ATS Application - Generate Cover Letter Save Click';

const TRACK_ATS_REFERENCE_CHECK_SIGN_IN = 'ATS RefCheck Candidate Sign In';
const TRACK_ATS_REFERENCE_CHECK_SIGN_UP = 'ATS RefCheck Candidate Sign Up';
const TRACK_ATS_REFERENCE_CHECK_SUBMIT_REFEREE_DETAILS =
  'ATS RefCheck Candidate Submit';
const TRACK_ATS_REFERENCE_CHECK_REFERENCE_SUBMISSION =
  'ATS RefCheck Reference Submission';

const TRACK_ATS_RELOGIN_VERIFICATION =
  'ATS Tension free signup - Relogin after verification';

const TRACK_ATS_SMART_MATCH_PAGE_CLICK =
  'ATS Swag Jobs Top Nav - SmartMatch Click';
const TRACK_ATS_CONTINUE_CLICK_IN_UPLOAD_RESUME_PAGE =
  'ATS Click Continue in Upload Resume Page in Job Match';
const TRACK_ATS_UPDATE_LOCATION_JOB_MATCH = 'ATS Update Location in Job Match';
const TRACK_ATS_UPDATE_SKILL_JOB_MATCH = 'ATS Update Skill in Job Match';
const TRACK_ATS_UPDATE_ROLES_TO_MATCH_JOB_MATCH =
  'ATS Update Roles To Match in Job Match';
const TRACK_ATS_FINISH_LATER_CLICK = 'ATS Finish Later Click in Job Match';
const TRACK_ATS_FIND_MATCHES_CLICK = 'ATS Find Matches Click in Job Match';

const TRACK_ATS_JOB_MATCH_FEEDBACK_THUMBS_UP =
  'ATS Job Match Feedback Thumbs Up';
const TRACK_ATS_JOB_MATCH_FEEDBACK_THUMBS_DOWN =
  'ATS Job Match Feedback Thumbs Down';

const TRACK_ATS_VIEW_JOB_MATCHES = 'ATS Swag Jobs - View matches';
const TRACK_ATS_VIEW_ACTIVE_JOB_MATCH =
  'ATS Swag Jobs - View active job recommendation';
const TRACK_ATS_VIEW_HIDDEN_JOB_MATCH =
  'ATS Swag Jobs - View hidden job recommendation';
const TRACK_ATS_VIEW_SAVED_JOB_MATCH =
  'ATS Swag Jobs - View saved job recommendation';
const TRACK_ATS_SAVE_ACTIVE_JOB_MATCH = 'ATS Swag Jobs - Save recommendation';
const TRACK_ATS_APPLY_ACTIVE_JOB_MATCH =
  'ATS Swag Jobs - Apply to recommendation';
const TRACK_ATS_APPLY_HIDDEN_JOB_MATCH =
  'ATS Swag Jobs - Interest in hidden job';
const TRACK_ATS_VIEW_ALL_MATCHES_FROM_PROMPT =
  'ATS Swag Jobs - Match trigger on Job Search';
const TRACK_ATS_SETUP_SMART_MATCH_FROM_PROMPT =
  'ATS Swag Jobs - Click Setup SmartMatch trigger on Job Search';
const TRACK_ATS_CLICK_CREATE_ACCOUNT_FROM_PROMPT =
  'ATS Swag Jobs - Click Signup trigger on Job Search';

// Job search page
const TRACK_ATS_VISIT_JOB_SEARCH_PAGE = 'ATS Swag Jobs - Visit Job Search Page';
const TRACK_ATS_JOB_SEARCH_SUBMIT_SEARCH_CLICK =
  'ATS Swag Jobs - Job Search - Submit Search Click';
const TRACK_ATS_JOB_SEARCH_VIDEO_RECORDING_CLICK =
  'ATS Job Search - Video Recording Click';

const TRACK_ATS_CLICK_MAGIC_WAND_FOR_PERSONAL_SUMMARY_IN_EMPTY_STATE =
  'ATS Click Magic Wand For Personal Summary In Empty State';
const TRACK_ATS_CLICK_MAGIC_WAND_AFTER_INPUTTING_THEIR_OWN_PERSONAL_SUMMARY =
  'ATS Click Magic Wand After Inputting Their Own Personal Summary';
const TRACK_ATS_CLICK_SAVE_AFTER_USING_MAGIC_WAND_FOR_PERSONAL_SUMMARY =
  'ATS Click Save After Using Magic Wand For Personal Summary';

const TRACK_ATS_CLICK_MAGIC_WAND_FOR_EXPERIENCE_SUMMARY_IN_EMPTY_STATE =
  'ATS Click Magic Wand For Experience Summary In Empty State';
const TRACK_ATS_CLICK_MAGIC_WAND_AFTER_INPUTTING_THEIR_OWN_EXPERIENCE_SUMMARY =
  'ATS Click Magic Wand After Inputting Their Own Experience Summary';
const TRACK_ATS_CLICK_SAVE_AFTER_USING_MAGIC_WAND_FOR_EXPERIENCE_SUMMARY =
  'ATS Click Save After Using Magic Wand For Experience Summary';

const TRACT_ATS_RESEND_BUTTON_CLICK = 'ATS Resend Button Click';
const TRACK_ATS_LOCKED_OUT_PAGE_VISIT = 'ATS Locked out Page - Visit';

const TRACK_LANDING_PAGE_PROFILE_VALUE_CTA_CLICK =
  'ATS Landing Page - Profile Value Tile CTA Click';
const TRACK_LANDING_PAGE_SMART_MATCH_CTA_CLICK =
  'ATS Landing Page - Smart Match Tile CTA Click';
const TRACK_LANDING_PAGE_CAREER_ADVICE_CTA_CLICK =
  'ATS Landing Page - Career Advice Tile CTA Click';
const TRACK_LANDING_PAGE_CALCULATE_POTENTIAL_TILE_CTA_CLICK =
  'ATS Landing Page - Calculate Potential Tile CTA Click';
const TRACK_SMART_MATCH_POTENTIAL_LAND_ON_CV_UPLOAD_PAGE =
  'SmartMatch Potential - Land on CV upload page';
const TRACK_SMART_MATCH_POTENTIAL_CV_EXTRACTED_SUCCESSFULLY =
  'SmartMatch Potential - CV extracted successfully';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_CONTINUE_ON_SKILLS_PAGE =
  'SmartMatch Potential - Click Continue on Skills Page';
const TRACK_SMART_MATCH_POTENTIAL_CREATE_ACCOUNT_SUCCESSFULLY =
  'SmartMatch Potential - Created account successfully';
const TRACK_SMARTMATCH_POTENTIAL_CLICK_ON_SIGN_IN_BUTTON =
  'SmartMatch Potential - Click on Sign in button';
const TRACK_SMARTMATCH_POTENTIAL_CLICK_ON_SIGN_UP_BUTTON =
  'SmartMatch Potential - Click on Sign up button';
const TRACK_SMARTMATCH_POTENTIAL_CLICK_RECOMMENDED_ROLE_TILE =
  'SmartMatch Potential - Click on recommended role tile';

const TRACK_SMARTMATCH_POTENTIAL_LANDS_ON_GATED_REPORT =
  'SmartMatch Potential - Lands on gated report';

const TRACK_SMART_MATCH_POTENTIAL_LAND_ON_POTENTIAL_SURVEY =
  'SmartMatch Potential - Lands on Potential Survey';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_CONTINUE_ON_POTENTIAL_SURVEY_PAGE =
  'SmartMatch Potential - Clicks continue on Potential Survey page';

const TRACK_SMART_MATCH_POTENTIAL_AUTHENTICATED_CV_EXTRACTED_SUCCESS =
  'SmartMatch Potential - Authenticated CV extracted successfully';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_CLOSE_CV_OVERLAY_BUTTON =
  'SmartMatch Potential - Click CV Overlay Close Button';

const TRACK_SMART_MATCH_POTENTIAL_CLICK_REPORT_SWITCH_TILE =
  'SmartMatch Potential - Click Switch Report Tile';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_FIND_OUT_MORE_REDIRECT_BUTTON =
  'SmartMatch Potential - Click Find Out More Redirect Button';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_EXPLORE_PATHWAYS_REDIRECT_BUTTON =
  'SmartMatch Potential - Click Explore Career Pathways Redirect Button';
const TRACK_SMART_MATCH_POTENTIAL_EXPLORE_OTHER_CAREER_PATHWAYS_TILE =
  'SmartMatch Potential - Click Explore Other Career Pathway Tile';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_MARKET_JOB_DEMAND_BROWSE_JOBS_BUTTON =
  'SmartMatch Potential - Click Market Job Demand - Browse New Jobs Button';
const TRACK_SMART_MATCH_POTENTIAL_CLICK_MARKET_JOB_DEMAND_VIEW_SALARY_GROWTH_INSIGHTS_BUTTON =
  'SmartMatch Potential - Click Market Job Demand - View Salary Growth Insights Button';

const TRACK_ATS_VIEW_SIGN_IN = 'ATS View Sign In';
const TRACK_ATS_FAILED_SIGN_IN = 'ATS Sign In Failed';
const TRACK_ATS_SUCCESS_SIGN_IN = 'ATS Sign In Success';
const TRACK_ATS_RESET_PASSWORD_CLICKED = 'ATS Forget Password Click';

const TRACK_PROFILE_CV_PARSING_TILE_CLICK =
  'Swag Profile - Populate from CV - Tile Click';
const TRACK_PROFILE_CV_PARSING_RESUME_UPLOAD =
  'Swag Profile - Populate from CV - Upload CV ';
const TRACK_PROFILE_CV_PARSING_POPULATE_PROFILE =
  'Swag Profile - Populate from CV - Populate Profile';

const TRACK_ATS_REPORT_JOB_BUTTON_CLICK = 'ATS Report Job Button Click';

const TRACK_SMART_MATCH_SAVED_ROLE_TILE_CLICK =
  'Saved Candidate - Saved Role Tile Click';
const TRACK_SMART_MATCH_QUICK_APPLY_IN_DETAIL_PAGE_CLICK =
  'Saved Candidate - Quick Apply in Detail Page Click';
const TRACK_SMART_MATCH_QUICK_APPLY_IN_MODAL_CLICK =
  'Saved Candidate - Quick Apply in Modal Click';

const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_JOB_PREFERENCES =
  'SmartMatch Onboarding - Lands on job preferences page';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_CV_PARSING =
  'SmartMatch Onboarding - Lands on CV parsing';
const TRACK_SMART_MATCH_ONBOARDING_UPLOAD_CV =
  'SmartMatch Onboarding - Uploads CV';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_POSTCODE =
  'SmartMatch Onboarding - Lands on postcode page ';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_TRAVEL_DISTANCE =
  'SmartMatch Onboarding - Lands on travel distance page ';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_JOB_TITLE =
  'SmartMatch Onboarding - Lands on job title page ';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_COMPANY_NAME =
  'SmartMatch Onboarding - Lands on company name page ';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_START_DATE =
  'SmartMatch Onboarding - Lands on start date page ';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_END_DATE =
  'SmartMatch Onboarding - Lands on end date page ';
const TRACK_SMART_MATCH_ONBOARDING_CLICK_FINISH_BUTTON =
  'SmartMatch Onboarding - Clicks finish button';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_SUPPORT_WORKER_PAGE =
  'SmartMatch Onboarding - Lands on Support Worker page';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_FRONT_OF_HOUSE_PAGE =
  'SmartMatch Onboarding - Lands on Front of House page';
const TRACK_SMART_MATCH_ONBOARDING_LAND_ON_CHILDHOOD_EDUCATOR_PAGE =
  'SmartMatch Onboarding - Lands on Childhood Educator page';

const TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_VALUE_PROPOSITION_PAGE =
  'SmartMatch Onboarding - Lands on Value Proposition Page';
const TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_CV_PARSING_PAGE =
  'SmartMatch Onboarding - Lands on CV Parsing Page';
const TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_POSTCODE_PAGE =
  'SmartMatch Onboarding - Lands on Postcode Page';
const TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_HIGHLIGHTS_PAGE =
  'SmartMatch Onboarding - Lands on SmartMatch Highlights Page';
const TRACK_SMART_MATCH_ONBOARDING_WORK_ELIGIBILITY_PAGE =
  'SmartMatch Onboarding - Lands on Work Eligibility Page';
const TRACK_SMART_MATCH_ONBOARDING_NOTICE_PERIOD_PAGE =
  'SmartMatch Onboarding - Lands on Notice Period Page';
const TRACK_SMART_MATCH_ONBOARDING_WORK_TYPE_PAGE =
  'SmartMatch Onboarding - Lands on Work Type Page';
const TRACK_SMART_MATCH_ONBOARDING_EXPECTED_SALARY_PAGE =
  'SmartMatch Onboarding - Lands on Expected Salary Page';
const TRACK_SMART_MATCH_ONBOARDING_COMPLETES_WIZARD_PAGE =
  'SmartMatch Onboarding - Completes Wizard';
const TRACK_SMART_MATCH_ONBOARDING_VIEW_SETUP_PROFILE =
  'SmartMatch Onboarding - View Setup Profile';
const TRACK_SMART_MATCH_ONBOARDING_CLICK_CREATE_PROFILE_MANUALLY =
  'SmartMatch Onboarding - Click Create Profile Manually';
const TRACK_SMART_MATCH_ONBOARDING_UPLOAD_CV_SUCCESS =
  'SmartMatch Onboarding - Upload CV Successfully';
const TRACK_SMART_MATCH_ONBOARDING_EXISTING_CV_UPLOAD_SUCCESS =
  'SmartMatch Onboarding - Use Existing CV Successfully';

const TRACK_SMART_MATCH_INDUSTRY_PAGES_VISIT =
  'SmartMatch Industry Pages - Visit Landing Page';
const TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_GET_STARTED =
  'SmartMatch Industry Pages - Click Get Started button';
const TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_SM_GET_STARTED =
  'SmartMatch Industry Pages - Click SmartMatch Get Started button';
const TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_CREATE_PROFILE =
  'SmartMatch Industry Pages - Click Create Profile button';

const TRACK_SMART_MATCH_ONBOARDING_NEXT_IN_EDUCATION_PAGE_CLICK =
  'SmartMatch Onboarding - Click Next on Education Page';
const TRACK_SMART_MATCH_ONBOARDING_SKIP_IN_EDUCATION_PAGE_CLICK =
  'SmartMatch Onboarding - Click Skip on Education Page';

const TRACK_PROFILE_SETTING_SMS_SETTINGS_IS_TOGGLED_ON =
  'Profile Settings - SMS Setting is toggled on';

// Pre-sign up onboarding flow
const TRACK_ONBOARDING_FLOW_LANDS_ON_TYPE_OF_WORK_PAGE =
  'Swag Web - Lands on type of work page';
const TRACK_ONBOARDING_FLOW_LANDS_ON_LOCATION_PAGE =
  'Swag Web - Lands on location page';
const TRACK_ONBOARDING_FLOW_CLICKS_AUTOFILL_LOCATION =
  'Swag Web - Clicks autofill location';
const TRACK_ONBOARDING_FLOW_LANDS_ON_REFERRAL_ORGANISATION_PAGE =
  'Swag Web - Lands on referral organisation page';
const TRACK_ONBOARDING_FLOW_LANDS_ON_ROLE_AND_INDUSTRY_PREFERENCES_PAGE =
  'Swag Web - Lands on role and industry preferences page';
const TRACK_ONBOARDING_FLOW_LANDS_ON_ROLE_YOU_ARE_LOOKING_FOR_PAGE =
  'Swag Web - Lands on role you are looking for page';
const TRACK_ONBOARDING_FLOW_LANDS_ON_OPPORTUNITIES_PAGE =
  'Swag Web - Lands on # of opportunities page';
const TRACK_ONBOARDING_FLOW_LANDS_ACCOUNT_CREATION_PAGE =
  'Swag Web - Lands on account creation page';
const TRACK_ONBOARDING_FLOW_SUCCESSFULLY_CREATED_AN_ACCOUNT =
  'Swag Web - Successfully created an account';
const TRACK_ONBOARDING_FLOW_SUCCESS_NO_DATA =
  'Swag Web - Successfully created an account with no organic data';
const TRACK_ONBOARDING_FLOW_REDIRECT_TO_SIGN_IN_PAGE =
  'Swag Web - Candidate clicks redirect to sign in page';

const TRACK_ONBOARDING_FLOW_VIEW_POPULATE_PROFILE =
  'Swag Web - View Populate EH Profile';
const TRACK_ONBOARDING_FLOW_CLICK_POPULATE_PROFILE =
  'Swag Web - Click Populate EH Profile';
const TRACK_ONBOARDING_FLOW_COMPLETE_POPULATE_PROFILE =
  'Swag Web - Complete Populate EH Profile';

// Setup job preferences
const TRACK_ONBOARDING_FLOW_JOB_TITLE = 'Swag Web - View Job Title';
const TRACK_ONBOARDING_FLOW_LOCATION = 'Swag Web - View Location';
const TRACK_ONBOARDING_FLOW_WORK_HISTORY = 'Swag Web - View Work History';
const TRACK_ONBOARDING_FLOW_HIGHLIGHT_QUALIFICATIONS =
  'Swag Web - View certification';
const TRACK_ONBOARDING_FLOW_VIEW_SETUP_PROFILE =
  'Swag Web - View Setup Profile';
const TRACK_ONBOARDING_FLOW_VIEW_WORK_AVAILABILITY =
  'Swag Web - View Work Availability';
const TRACK_ONBOARDING_FLOW_VIEW_WORK_ELIGIBILITY =
  'Swag Web - View Work Eligibility';
const TRACK_ONBOARDING_FLOW_VIEW_PHONE_CONTACT =
  'Swag Web - View Work Phone Contact';
const TRACK_ONBOARDING_FLOW_VIEW_SALARY_EXPECTATION =
  'Swag Web - View Salary Expectation';
const TRACK_ONBOARDING_FLOW_VIEW_CAREER_GOALS = 'Swag Web - View Career Goals';
const TRACK_ONBOARDING_FLOW_VIEW_PROFILE_SUMMARY =
  'Swag Web - View Profile Summary';
const TRACK_ONBOARDING_FLOW_VIEW_HERO_FOUNDATION_SUMMARY =
  'Swag Web - View Hero Foundation Summary';
const TRACK_ONBOARDING_FLOW_VIEW_MOTIVATIONS_AND_INTERESTS =
  'Swag Web - View Motivations and Interests';
const TRACK_ONBOARDING_FLOW_VIEW_PERSONAL_STORY =
  'Swag Web - View Personal Story';
const TRACK_ONBOARDING_FLOW_VIEW_WORK_CONSTRAINTS_AND_REQUIREMENTS =
  'Swag Web - View Work Constraints and Requirements';
const TRACK_ONBOARDING_FLOW_CANDIDATE_CURRENT_SITUATIONS =
  'Swag Web - View Candidate Current Situations';
const TRACK_ONBOARDING_FLOW_VIEW_SHORT_TERM_GOALS =
  'Swag Web - View Short Term Goals';
const TRACK_ONBOARDING_FLOW_VIEW_LONG_TERM_GOALS =
  'Swag Web - View Long Term Goals';
const TRACK_ONBOARDING_FLOW_VIEW_SETUP_SUCCESS =
  'Swag Web - View Setup Success';

const TRACK_ONBOARDING_FLOW_CLICK_CREATE_PROFILE_MANUALLY =
  'Swag Web - Click Create Profile Manually';
const TRACK_ONBOARDING_FLOW_UPLOAD_CV_SUCCESS =
  'Swag Web - Upload CV Successfully';
const TRACK_ONBOARDING_FLOW_EXISTING_CV_UPLOAD_SUCCESS =
  'Swag Web - Use Existing CV Successfully';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_EDUCATION_HISTORY =
  'Swag Web - Click Next Education History';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_JOB_TITLE =
  'Swag Web - Click Next Job Title';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_LOCATION =
  'Swag Web - Click Next Location';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_HISTORY =
  'Swag Web - Click Next Work History';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_AVAILABILITY =
  'Swag Web - Click Next Work Availability';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_QUALIFICATIONS =
  'Swag Web - Click Next Qualifications';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_PHONE_CONTACT =
  'Swag Web - Click Next Phone Contact';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_TYPES =
  'Swag Web - Click Next Work Types';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_ELIGIBILITY =
  'Swag Web - Click Next Work Eligibility';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_SALARY_EXPECTATION =
  'Swag Web - Click Next Salary Expectation';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_CAREER_GOALS =
  'Swag Web - Click Next Career Goals';
const TRACK_ONBOARDING_FLOW_CLICK_NEXT_PROFILE_SUMMARY =
  'Swag Web - Click Next Profile Summary';
const TRACK_ONBOARDING_FLOW_CLICK_SKIP = 'Swag Web - Click Skip Setup Job Pref';

// CV upload services
const TRACK_USER_UPLOAD_CV_PARSING = 'Swag Web - CV Parse';

const TRACK_SMART_MATCH_JOB_MATCH_EMAILS_VIEW_JOB_ROLE =
  'SmartMatch Job Match Emails - View Job Role';
const TRACK_SMART_MATCH_JOB_MATCH_EMAILS_VIEW_MATCHES =
  'SmartMatch Job Match Emails - View Matches';
const TRACK_SMART_MATCH_JOB_MATCH_EMAILS_UPDATE_MATCH_CRITERIA =
  'SmartMatch Job Match Emails - Update Match Criteria';

const TRACK_NOTIFICATION_USER_CLICK_NOTIFICATION_BELL =
  'Notification User Click Notification Bell';
const TRACK_NOTIFICATION_USER_SEND_A_DM_IN_AN_ACTIVITY_THREAD =
  'Notification User Send a DM in an Activity Thread';

const TRACK_ACTIVITY_THREAD_USER_CLICK_ON_A_DIRECT_MESSAGE_OR_SM_EMAIL_EVENT_TILE =
  'Activity Thread - User Click on a DM or SM Email Event Tile';

const TRACK_DIRECT_MESSAGING_ATS_EMAIL_CTA_CLICKED =
  'Direct Messaging - ATS Email CTA Clicked';

const TRACK_NOTIFICATION_SETTINGS_TURN_OFF_EMAIL =
  'Notification Settings - User Toggles Email Event Off';
const TRACK_NOTIFICATION_SETTINGS_TURN_OFF_MOBILE =
  'Notification Settings - User Toggles Mobile Event Off';

// Direct message usage
const TRACK_DIRECT_MESSAGE_USUAGE_VIEW_THREAD =
  'Direct Messsage Usage - View Thread';
const TRACK_DIRECT_MESSAGE_USAGE_READ_MESSAGE =
  'Direct Messsage Usage - Read Message';
const TRACK_DIRECT_MESSAGE_USAGE_READ_MORE_MESSAGE =
  'Direct Messsage Usage - Read More Message';
const TRACK_DIRECT_MESSAGE_USAGE_WRITE_THREAD =
  'Direct Messsage Usage - Write Thread';
const TRACK_DIRECT_MESSAGE_USAGE_SEND_MESSAGE =
  'Direct Messsage Usage - Send Message';
const TRACK_DIRECT_MESSAGE_USAGE_INITIAL_LOAD_MESSAGE =
  'Direct Messsage Usage - Initial Load Message';

// Show Interest in Job
const TRACK_INTEREST_JOB_DETAILS_INTEREST_BUTTON_CLICK =
  'Interest Job Details - Interest Button Clicked';
const TRACK_INTEREST_JOB_DETAILS_NOT_INTEREST_BUTTON_CLICK =
  'Interest Job Details - Not Interest Button Clicked';
const TRACK_INTEREST_JOB_VISIT_JOB_DETAILS_PAGE =
  'Interest Job Details - Visit Job Details Page';
const TRACK_INTEREST_JOB_VISIT_LOGIN_PAGE =
  'Interest Job Details - Visit Login Page';

// Direct message
const TRACK_CANDIDATE_SEND_DIRECT_MESSAGE_WITH_ATTACHMENT =
  'Direct Message - Candidate Send DM with Attachment (Desktop)';
const TRACK_CANDIDATE_DOWNLOAD_ATTACHMENT =
  'Direct Message - Candidate Download an Attachment (Desktop)';
const TRACK_CANDIDATE_USE_DIRECT_MESSAGE_FORMATTING =
  'Direct Message - User use formatting function';

const TRACK_CANDIDATE_CLICK_ON_SHORTLISTED_NOTIFICATION =
  'Notification Page - Candidate click on shortlisted notification';

// Profile
const TRACK_USER_PROFILE_VIEW_PROFILE = 'User Profile - View Profile';
const TRACK_USER_PROFILE_EDIT_PERSONAL_DETAILS =
  'User Profile - Edit Personal Details';
const TRACK_USER_PROFILE_CLICK_SHARE_PROFILE_BUTTON =
  'User Profile - Click Share Profile Button';
const TRACK_USER_PROFILE_UPDATE_AVATAR_IMAGE =
  'User Profile - Update Avatar Image';
const TRACK_USER_PROFILE_UPDATE_PROFILE_SUMMARY =
  'User Profile - Update Profile Summary';

const TRACK_USER_PROFILE_UPLOAD_ATTACHMENT =
  'User Profile - Upload Attachment to profile';
const TRACK_USER_PROFILE_DELETE_ATTACHMENT =
  'User Profile - Delete Attachment from profile';
const TRACK_USER_PROFILE_DOWNLOAD_ATTACHMENT =
  'User Profile - Download Attachment from profile';
const TRACK_USER_PROFILE_POPULATE_PROFILE_FROM_RESUME_UPLOAD =
  'User Profile - Auto populate profile from resume';

const TRACK_USER_PROFILE_ADD_CERTIFICATION = 'User Profile - Add certification';
const TRACK_USER_PROFILE_EDIT_CERTIFICATION =
  'User Profile - Edit certification';
const TRACK_USER_PROFILE_DELETE_CERTIFICATION =
  'User Profile - Delete certification';

const TRACK_USER_PROFILE_ADD_EDUCATION_HISTORY =
  'User Profile - Add education history';
const TRACK_USER_PROFILE_EDIT_EDUCATION_HISTORY =
  'User Profile - Edit education history';
const TRACK_USER_PROFILE_DELETE_EDUCATION_HISTORY =
  'User Profile - Delete education history';

const TRACK_USER_PROFILE_ADD_EXPERIENCE = 'User Profile - Add experience';
const TRACK_USER_PROFILE_EDIT_EXPERIENCE = 'User Profile - Edit experience';
const TRACK_USER_PROFILE_DELETE_EXPERIENCE = 'User Profile - Delete experience';

const TRACK_USER_PROFILE_UPDATE_SKILLS = 'User Profile - Update skills';
const TRACK_ATS_JOB_APPLICATION_APP_INFO_STEP =
  'ATS Job Application - Application Info Step';
const TRACK_ATS_JOB_APPLICATION_SCREENING_QUESTIONS_STEP =
  'ATS Job Application - Screening Questions Step';
const TRACK_ATS_JOB_APPLICATION_PERSONAL_INFORMATION_STEP =
  'ATS Job Application - Personal Information Step';
const TRACK_ATS_JOB_APPLICATION_SWAG_PROFILE_STEP =
  'ATS Job Application - Swag Profile Step';
const TRACK_ATS_JOB_APPLICATION_REVIEW_SUBMIT_STEP =
  'ATS Job Application - Review & Submit Step';

// Automated prescreen question flow
const TRACK_SMART_MATCH_EMAIL_AUTOMATED_PRESCREEN_QUESTIONS_OPENED =
  'Smart Match - Email - Automated prescreen questions opened';

const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_INCREASE_YOUR_PAY_BUTTON =
  'SmartMatch Potential - Salary Insight Report - Click on Increase your pay button';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_FIND_OUT_MORE_BUTTON =
  'SmartMatch Potential - Salary Insight Report - Click on Find out more button';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_UPDATE_SWAG_PROFILE_BUTTON =
  'SmartMatch Potential - Salary Insight Report - Click on update swag profile button';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_UPDATE_USER_SKILL =
  'SmartMatch Potential - Salary Insight Report - Update user skill';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_RECOMMENDED_JOBS_TO_BUILD_SKILLS =
  'SmartMatch Potential - Salary Insight Report - Click on recommended jobs to build skills';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_SALARY_NEGOTIATION_GUIDE =
  'SmartMatch Potential - Salary Insight Report - Click on salary negotiation guide';
const TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_SALARY_RANGE_LETS_GO_BUTTON =
  'SmartMatch Potential - Salary Insight Report - Click on salary range let’s go button';
const TRACK_SMART_MATCH_POTENTIAL_CAREER_PATHWAY_REPORT_CLICK_ON_MATCHED_JOBS_TILE =
  'SmartMatch Potential - Career Pathway Report - Click on matched jobs tile';
const TRACK_SMART_MATCH_POTENTIAL_CAREER_PATHWAY_REPORT_CLICK_ON_JOB_OPENINGS_BUTTON =
  'SmartMatch Potential - Career Pathway Report - Click on job openings button';

// Public Application
const TRACK_PUBLIC_APPLICATION_VIEW =
  'EH-Work-Web-CandidateProfile-View profile page';
const TRACK_CLICK_REJECT_BUTTON = 'EH-Work-Web-CandidateProfile-Click Reject';
const TRACK_CLICK_STOP_RECEIVING_CANDIDATES_BUTTON =
  'EH-Work-Web-RejectForm-Click on Stop Receiving Candidates';
const TRACK_SUBMIT_REJECTING_CANDIDATE_FORM =
  'EH-Work-Web-RejectForm-Click on Submit';
const TRACK_SUBMIT_REQUEST_APPLICATION_INFO_FIELD =
  'EH-Work-Web-CandidateProfile-Ask Candidate to share';
const TRACK_CLICK_MESSAGE_BUTTON =
  'EH-Work-Web-CandidateProfile-Click on message button';
const TRACK_SEND_MESSAGE = 'EH-Work-Web-CandidateProfile-Send message';
const TRACK_CLICK_ON_REACTIVATE_BUTTON =
  'EH-Work-Web-RejectForm-Click on Reactivate';
const TRACK_CLICK_ON_VIEW_ALL_APPLICANTS_BUTTON =
  'EH-Work-Web-CandidateProfile-Click on view all applicants';

const TRACK_HIRER_CLICK_ON_PLAY_VIDEO =
  'EH-Work-Web-CandidateProfile-Click on play video';
const TRACK_HIRER_EXPANDS_SUMMARY =
  'EH-Work-Web-CandidateProfile-Click on Summary expander';
const TRACK_HIRER_CLICK_ON_REVEAL_CONTACT_NO =
  'EH-Work-Web-CandidateProfile-Click on reveal contact number';
const TRACK_HIRER_EXPANDS_PROFILE =
  'EH-Work-Web-CandidateProfile-Click on profile to expand';
const TRACK_HIRER_EXPANDS_SKILL =
  'EH-Work-Web-CandidateProfile-Click on Skills expander';
const TRACK_HIRER_EXPANDS_EXPERIENCE =
  'EH-Work-Web-CandidateProfile-Click on Experience expander';
const TRACK_HIRER_EXPANDS_CERT_N_LICENCE =
  'EH-Work-Web-CandidateProfile-Click on Certifications & Licences expander';
const TRACK_HIRER_CLICK_ON_HIRING_STATUS_DROPDOWN =
  'EH-Work-Web-CandidateProfile-Click on Hiring Status dropdown';
const TRACK_HIRER_UPDATE_HIRING_STATUS_VIA_DROPDOWN =
  'EH-Work-Web-CandidateProfile-Updates Hiring Status via dropdown';
const TRACK_HIRER_CONFIRM_HIRE =
  'EH-Work-Web-CandidateProfile- Clicks Confirm to Hire job seeker';

// Public Applications
const TRACK_EH_WORK_HIRING_APPLICANT_VIEW =
  'EH-Work-Web-Applicant list-View page';
const TRACK_EH_WORK_HIRING_APPLICANT_DOWNLOAD_APP =
  'EH-Work-Web-Applicant list-Download app';
const TRACK_EH_WORK_HIRING_APPLICANT_VIEW_APPLICANT =
  'EH-Work-Web-Applicant list-Clicks to view applicant';
const TRACK_EH_WORK_HIRING_APPLICANT_SELECT_SUGGESTED_ROLE =
  'EH-Work-Web-Role management-Selects suggested role';
const TRACK_EH_WORK_HIRING_APPLICANT_SELECT_SEARCH_ROLE =
  'EH-Work-Web-Role management-Selects searched role';
const TRACK_EH_WORK_HIRING_ROLE_MANAGEMENT_SAVES_ROLE =
  'EH-Work-Web-Role management-Saves roles';
const TRACK_CLICK_ON_ACTIVELY_HIRING =
  'EH-Work-Web-ApplicantList-Click on Actively Hiring';
const TRACK_CLICK_ON_NOT_HIRING =
  'EH-Work-Web-ApplicantList-Click on Not Hiring';
const TRACK_CLICK_ON_START_RECEIVING_CANDIDATES =
  'EH-Work-Web-ApplicantList-Click on Start Receiving New Applications';
const TRACK_EH_WORK_HIRING_APPLICANT_REQUEST_EMAIL_LINK =
  'EH-Work-Web-Applicant list-Clicks to email link';

// Hero Foundation Sign up flow
const TRACK_HERO_FOUNDATION_LANDS_ON_ORGANISATION_PAGE =
  'Hero Foundation - Lands on Hero Foundation Organisation Page';
const TRACK_HERO_FOUNDATION_CREATE_ACCOUNT_SUCCESSFULLY =
  'Hero Foundation - Create Account Successfully';

export {
  CLICK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_EDIT_BUTTON,
  CLICK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_GET_STARTED_BUTTON,
  TRACK_ACTIVITY_THREAD_USER_CLICK_ON_A_DIRECT_MESSAGE_OR_SM_EMAIL_EVENT_TILE,
  TRACK_ATS_ADZUNA_SIGN_UP_ACCOUNT_CREATION,
  TRACK_ATS_APP_STORE_CLICK,
  TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_CLICK,
  TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_MAGIC_WAND_CLICK,
  TRACK_ATS_APPLICATION_GENERATE_COVER_LETTER_SAVE_CLICK,
  TRACK_ATS_APPLY_ACTIVE_JOB_MATCH,
  TRACK_ATS_APPLY_HIDDEN_JOB_MATCH,
  TRACK_ATS_CANDIDATE_CREATES_ACCOUNT_VERIFICATION_EMAIL,
  TRACK_ATS_CANDIDATE_TOGGLE_PUBLIC_PROFILE,
  TRACK_ATS_CANDIDATE_VIEWS_PROFILE,
  TRACK_ATS_CAREER_PAGE_APPLY_ROLE_CLICK,
  TRACK_ATS_CLICK_CREATE_ACCOUNT_FROM_PROMPT,
  TRACK_ATS_CLICK_MAGIC_WAND_AFTER_INPUTTING_THEIR_OWN_EXPERIENCE_SUMMARY,
  TRACK_ATS_CLICK_MAGIC_WAND_AFTER_INPUTTING_THEIR_OWN_PERSONAL_SUMMARY,
  TRACK_ATS_CLICK_MAGIC_WAND_FOR_EXPERIENCE_SUMMARY_IN_EMPTY_STATE,
  TRACK_ATS_CLICK_MAGIC_WAND_FOR_PERSONAL_SUMMARY_IN_EMPTY_STATE,
  TRACK_ATS_CLICK_SAVE_AFTER_USING_MAGIC_WAND_FOR_EXPERIENCE_SUMMARY,
  TRACK_ATS_CLICK_SAVE_AFTER_USING_MAGIC_WAND_FOR_PERSONAL_SUMMARY,
  TRACK_ATS_CONTINUE_CLICK_IN_UPLOAD_RESUME_PAGE,
  TRACK_ATS_CV_PARSING_DIRECT_APPLICATION_RESUME_UPLOAD,
  TRACK_ATS_DIRECT_CANDIDATE_ACCOUNT_CREATION,
  TRACK_ATS_EH_JOB_BOARD_VISIT,
  TRACK_ATS_FAILED_SIGN_IN,
  TRACK_ATS_FIND_MATCHES_CLICK,
  TRACK_ATS_FINISH_LATER_CLICK,
  TRACK_ATS_INDEED_SIGN_UP_ACCOUNT_CREATION,
  TRACK_ATS_INDUSTRY_FILTER_JOB_BOARD,
  TRACK_ATS_JOB_APPLICATION,
  TRACK_ATS_JOB_APPLICATION_APP_INFO_STEP,
  TRACK_ATS_JOB_APPLICATION_PERSONAL_INFORMATION_STEP,
  TRACK_ATS_JOB_APPLICATION_REVIEW_SUBMIT_STEP,
  TRACK_ATS_JOB_APPLICATION_SCREENING_QUESTIONS_STEP,
  TRACK_ATS_JOB_APPLICATION_SWAG_PROFILE_STEP,
  TRACK_ATS_JOB_MATCH_FEEDBACK_THUMBS_DOWN,
  TRACK_ATS_JOB_MATCH_FEEDBACK_THUMBS_UP,
  TRACK_ATS_JOB_OVERVIEW,
  TRACK_ATS_JOB_SEARCH_SUBMIT_SEARCH_CLICK,
  TRACK_ATS_JOB_SEARCH_VIDEO_RECORDING_CLICK,
  TRACK_ATS_JORA_SIGN_UP_ACCOUNT_CREATION,
  TRACK_ATS_LINKEDIN_SIGN_UP_ACCOUNT_CREATION,
  TRACK_ATS_LOCKED_OUT_PAGE_VISIT,
  TRACK_ATS_MANUALLY_SIGN_UP_ACCOUNT_CREATION,
  TRACK_ATS_PLAY_STORE_CLICK,
  TRACK_ATS_QR_CODE_APPLICATION,
  TRACK_ATS_REDESIGNED_CAREER_PAGE_VISIT,
  TRACK_ATS_REFERENCE_CHECK_REFERENCE_SUBMISSION,
  TRACK_ATS_REFERENCE_CHECK_SIGN_IN,
  TRACK_ATS_REFERENCE_CHECK_SIGN_UP,
  TRACK_ATS_REFERENCE_CHECK_SUBMIT_REFEREE_DETAILS,
  TRACK_ATS_RELOGIN_VERIFICATION,
  TRACK_ATS_REPORT_JOB_BUTTON_CLICK,
  TRACK_ATS_RESEND_VERIFICATION_EMAIL,
  TRACK_ATS_RESET_PASSWORD_CLICKED,
  TRACK_ATS_ROLE_DETAILS_CLICK_APPLY_NOW,
  TRACK_ATS_SAVE_ACTIVE_JOB_MATCH,
  TRACK_ATS_SAVE_JOB,
  TRACK_ATS_SAVED_JOB_PAGE_VISIT,
  TRACK_ATS_SCAN_QR_POSTER_TO_CAREER_PAGE,
  TRACK_ATS_SETUP_SMART_MATCH_FROM_PROMPT,
  TRACK_ATS_SHARE_BUTTON_COPY_LINK,
  TRACK_ATS_SMART_MATCH_PAGE_CLICK,
  TRACK_ATS_SUBMIT_APPLICATION,
  TRACK_ATS_SUCCESS_SIGN_IN,
  TRACK_ATS_UPDATE_LOCATION_JOB_MATCH,
  TRACK_ATS_UPDATE_ROLES_TO_MATCH_JOB_MATCH,
  TRACK_ATS_UPDATE_SKILL_JOB_MATCH,
  TRACK_ATS_VIDEO_INTRODUCTION_COPY_CLIPBOARD_CLICK,
  TRACK_ATS_VIDEO_INTRODUCTION_COPY_CLIPBOARD_ERROR,
  TRACK_ATS_VIDEO_INTRODUCTION_DELETE_VIDEO_CLICK,
  TRACK_ATS_VIDEO_INTRODUCTION_DELETE_VIDEO_ERROR,
  TRACK_ATS_VIDEO_INTRODUCTION_EXCEED_LIMIT_UPLOAD_SHOW,
  TRACK_ATS_VIDEO_INTRODUCTION_GENERATE_QR_CLICK,
  TRACK_ATS_VIDEO_INTRODUCTION_GENERATE_QR_ERROR,
  TRACK_ATS_VIDEO_INTRODUCTION_OVER_SIZE_LIMIT,
  TRACK_ATS_VIDEO_INTRODUCTION_PLAY_CLICK,
  TRACK_ATS_VIDEO_INTRODUCTION_PUBLISH_CLICK,
  TRACK_ATS_VIDEO_INTRODUCTION_REQUEST_VIDEO_ERROR,
  TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_ERROR,
  TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_SUBMIT,
  TRACK_ATS_VIDEO_INTRODUCTION_SEND_REPORT_SUCCESS,
  TRACK_ATS_VIDEO_INTRODUCTION_UPLOAD_ERROR,
  TRACK_ATS_VIEW_ACTIVE_JOB_MATCH,
  TRACK_ATS_VIEW_ALL_MATCHES_FROM_PROMPT,
  TRACK_ATS_VIEW_ARCHIVED_APPLICATIONS,
  TRACK_ATS_VIEW_HIDDEN_JOB_MATCH,
  TRACK_ATS_VIEW_IN_PROGRESS_APPLICATIONS,
  TRACK_ATS_VIEW_JOB_MATCHES,
  TRACK_ATS_VIEW_SAVED_JOB_MATCH,
  TRACK_ATS_VIEW_SIGN_IN,
  TRACK_ATS_VISIT_APPLICATION_PAGE,
  TRACK_ATS_VISIT_JOB_SEARCH_PAGE,
  TRACK_ATS_WITHDRAW_APPLICATION,
  TRACK_CANDIDATE_CLICK_ON_SHORTLISTED_NOTIFICATION,
  TRACK_CANDIDATE_DOWNLOAD_ATTACHMENT,
  TRACK_CANDIDATE_SEND_DIRECT_MESSAGE_WITH_ATTACHMENT,
  TRACK_CANDIDATE_USE_DIRECT_MESSAGE_FORMATTING,
  TRACK_CLICK_MESSAGE_BUTTON,
  TRACK_CLICK_ON_ACTIVELY_HIRING,
  TRACK_CLICK_ON_NOT_HIRING,
  TRACK_CLICK_ON_REACTIVATE_BUTTON,
  TRACK_CLICK_ON_START_RECEIVING_CANDIDATES,
  TRACK_CLICK_ON_VIEW_ALL_APPLICANTS_BUTTON,
  TRACK_CLICK_REJECT_BUTTON,
  TRACK_CLICK_STOP_RECEIVING_CANDIDATES_BUTTON,
  TRACK_DIRECT_MESSAGE_USAGE_INITIAL_LOAD_MESSAGE,
  TRACK_DIRECT_MESSAGE_USAGE_READ_MESSAGE,
  TRACK_DIRECT_MESSAGE_USAGE_READ_MORE_MESSAGE,
  TRACK_DIRECT_MESSAGE_USAGE_SEND_MESSAGE,
  TRACK_DIRECT_MESSAGE_USAGE_WRITE_THREAD,
  TRACK_DIRECT_MESSAGE_USUAGE_VIEW_THREAD,
  TRACK_DIRECT_MESSAGING_ATS_EMAIL_CTA_CLICKED,
  TRACK_EH_WORK_HIRING_APPLICANT_DOWNLOAD_APP,
  TRACK_EH_WORK_HIRING_APPLICANT_REQUEST_EMAIL_LINK,
  TRACK_EH_WORK_HIRING_APPLICANT_SELECT_SEARCH_ROLE,
  TRACK_EH_WORK_HIRING_APPLICANT_SELECT_SUGGESTED_ROLE,
  TRACK_EH_WORK_HIRING_APPLICANT_VIEW,
  TRACK_EH_WORK_HIRING_APPLICANT_VIEW_APPLICANT,
  TRACK_EH_WORK_HIRING_ROLE_MANAGEMENT_SAVES_ROLE,
  TRACK_HERO_FOUNDATION_CREATE_ACCOUNT_SUCCESSFULLY,
  TRACK_HERO_FOUNDATION_LANDS_ON_ORGANISATION_PAGE,
  TRACK_HIRER_CLICK_ON_HIRING_STATUS_DROPDOWN,
  TRACK_HIRER_CLICK_ON_PLAY_VIDEO,
  TRACK_HIRER_CLICK_ON_REVEAL_CONTACT_NO,
  TRACK_HIRER_CONFIRM_HIRE,
  TRACK_HIRER_EXPANDS_CERT_N_LICENCE,
  TRACK_HIRER_EXPANDS_EXPERIENCE,
  TRACK_HIRER_EXPANDS_PROFILE,
  TRACK_HIRER_EXPANDS_SKILL,
  TRACK_HIRER_EXPANDS_SUMMARY,
  TRACK_HIRER_UPDATE_HIRING_STATUS_VIA_DROPDOWN,
  TRACK_INTEREST_JOB_DETAILS_INTEREST_BUTTON_CLICK,
  TRACK_INTEREST_JOB_DETAILS_NOT_INTEREST_BUTTON_CLICK,
  TRACK_INTEREST_JOB_VISIT_JOB_DETAILS_PAGE,
  TRACK_INTEREST_JOB_VISIT_LOGIN_PAGE,
  TRACK_LANDING_PAGE_CALCULATE_POTENTIAL_TILE_CTA_CLICK,
  TRACK_LANDING_PAGE_CAREER_ADVICE_CTA_CLICK,
  TRACK_LANDING_PAGE_PROFILE_VALUE_CTA_CLICK,
  TRACK_LANDING_PAGE_SMART_MATCH_CTA_CLICK,
  TRACK_NOTIFICATION_SETTINGS_TURN_OFF_EMAIL,
  TRACK_NOTIFICATION_SETTINGS_TURN_OFF_MOBILE,
  TRACK_NOTIFICATION_USER_CLICK_NOTIFICATION_BELL,
  TRACK_NOTIFICATION_USER_SEND_A_DM_IN_AN_ACTIVITY_THREAD,
  TRACK_ONBOARDING_FLOW_CANDIDATE_CURRENT_SITUATIONS,
  TRACK_ONBOARDING_FLOW_CLICK_CREATE_PROFILE_MANUALLY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_CAREER_GOALS,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_EDUCATION_HISTORY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_JOB_TITLE,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_LOCATION,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_PHONE_CONTACT,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_PROFILE_SUMMARY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_QUALIFICATIONS,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_SALARY_EXPECTATION,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_AVAILABILITY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_ELIGIBILITY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_HISTORY,
  TRACK_ONBOARDING_FLOW_CLICK_NEXT_WORK_TYPES,
  TRACK_ONBOARDING_FLOW_CLICK_POPULATE_PROFILE,
  TRACK_ONBOARDING_FLOW_CLICK_SKIP,
  TRACK_ONBOARDING_FLOW_CLICKS_AUTOFILL_LOCATION,
  TRACK_ONBOARDING_FLOW_COMPLETE_POPULATE_PROFILE,
  TRACK_ONBOARDING_FLOW_EXISTING_CV_UPLOAD_SUCCESS,
  TRACK_ONBOARDING_FLOW_HIGHLIGHT_QUALIFICATIONS,
  TRACK_ONBOARDING_FLOW_JOB_TITLE,
  TRACK_ONBOARDING_FLOW_LANDS_ACCOUNT_CREATION_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_LOCATION_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_OPPORTUNITIES_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_REFERRAL_ORGANISATION_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_ROLE_AND_INDUSTRY_PREFERENCES_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_ROLE_YOU_ARE_LOOKING_FOR_PAGE,
  TRACK_ONBOARDING_FLOW_LANDS_ON_TYPE_OF_WORK_PAGE,
  TRACK_ONBOARDING_FLOW_LOCATION,
  TRACK_ONBOARDING_FLOW_REDIRECT_TO_SIGN_IN_PAGE,
  TRACK_ONBOARDING_FLOW_SUCCESS_NO_DATA,
  TRACK_ONBOARDING_FLOW_SUCCESSFULLY_CREATED_AN_ACCOUNT,
  TRACK_ONBOARDING_FLOW_UPLOAD_CV_SUCCESS,
  TRACK_ONBOARDING_FLOW_VIEW_CAREER_GOALS,
  TRACK_ONBOARDING_FLOW_VIEW_HERO_FOUNDATION_SUMMARY,
  TRACK_ONBOARDING_FLOW_VIEW_LONG_TERM_GOALS,
  TRACK_ONBOARDING_FLOW_VIEW_MOTIVATIONS_AND_INTERESTS,
  TRACK_ONBOARDING_FLOW_VIEW_PERSONAL_STORY,
  TRACK_ONBOARDING_FLOW_VIEW_PHONE_CONTACT,
  TRACK_ONBOARDING_FLOW_VIEW_POPULATE_PROFILE,
  TRACK_ONBOARDING_FLOW_VIEW_PROFILE_SUMMARY,
  TRACK_ONBOARDING_FLOW_VIEW_SALARY_EXPECTATION,
  TRACK_ONBOARDING_FLOW_VIEW_SETUP_PROFILE,
  TRACK_ONBOARDING_FLOW_VIEW_SETUP_SUCCESS,
  TRACK_ONBOARDING_FLOW_VIEW_SHORT_TERM_GOALS,
  TRACK_ONBOARDING_FLOW_VIEW_WORK_AVAILABILITY,
  TRACK_ONBOARDING_FLOW_VIEW_WORK_CONSTRAINTS_AND_REQUIREMENTS,
  TRACK_ONBOARDING_FLOW_VIEW_WORK_ELIGIBILITY,
  TRACK_ONBOARDING_FLOW_WORK_HISTORY,
  TRACK_PROFILE_CV_PARSING_POPULATE_PROFILE,
  TRACK_PROFILE_CV_PARSING_RESUME_UPLOAD,
  TRACK_PROFILE_CV_PARSING_TILE_CLICK,
  TRACK_PROFILE_SETTING_SMS_SETTINGS_IS_TOGGLED_ON,
  TRACK_PUBLIC_APPLICATION_VIEW,
  TRACK_ROLE_CLICK,
  TRACK_SEND_MESSAGE,
  TRACK_SMART_MATCH_EMAIL_AUTOMATED_PRESCREEN_QUESTIONS_OPENED,
  TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_CREATE_PROFILE,
  TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_GET_STARTED,
  TRACK_SMART_MATCH_INDUSTRY_PAGES_CLICK_SM_GET_STARTED,
  TRACK_SMART_MATCH_INDUSTRY_PAGES_VISIT,
  TRACK_SMART_MATCH_JOB_MATCH_EMAILS_UPDATE_MATCH_CRITERIA,
  TRACK_SMART_MATCH_JOB_MATCH_EMAILS_VIEW_JOB_ROLE,
  TRACK_SMART_MATCH_JOB_MATCH_EMAILS_VIEW_MATCHES,
  TRACK_SMART_MATCH_ONBOARDING_CLICK_CREATE_PROFILE_MANUALLY,
  TRACK_SMART_MATCH_ONBOARDING_CLICK_FINISH_BUTTON,
  TRACK_SMART_MATCH_ONBOARDING_COMPLETES_WIZARD_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_EXISTING_CV_UPLOAD_SUCCESS,
  TRACK_SMART_MATCH_ONBOARDING_EXPECTED_SALARY_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_CHILDHOOD_EDUCATOR_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_COMPANY_NAME,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_CV_PARSING,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_END_DATE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_FRONT_OF_HOUSE_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_JOB_PREFERENCES,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_JOB_TITLE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_POSTCODE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_START_DATE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_SUPPORT_WORKER_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LAND_ON_TRAVEL_DISTANCE,
  TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_CV_PARSING_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_HIGHLIGHTS_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_POSTCODE_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_LANDS_ON_VALUE_PROPOSITION_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_NEXT_IN_EDUCATION_PAGE_CLICK,
  TRACK_SMART_MATCH_ONBOARDING_NOTICE_PERIOD_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_SKIP_IN_EDUCATION_PAGE_CLICK,
  TRACK_SMART_MATCH_ONBOARDING_UPLOAD_CV,
  TRACK_SMART_MATCH_ONBOARDING_UPLOAD_CV_SUCCESS,
  TRACK_SMART_MATCH_ONBOARDING_VIEW_SETUP_PROFILE,
  TRACK_SMART_MATCH_ONBOARDING_WORK_ELIGIBILITY_PAGE,
  TRACK_SMART_MATCH_ONBOARDING_WORK_TYPE_PAGE,
  TRACK_SMART_MATCH_POTENTIAL_AUTHENTICATED_CV_EXTRACTED_SUCCESS,
  TRACK_SMART_MATCH_POTENTIAL_CAREER_PATHWAY_REPORT_CLICK_ON_JOB_OPENINGS_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CAREER_PATHWAY_REPORT_CLICK_ON_MATCHED_JOBS_TILE,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_CLOSE_CV_OVERLAY_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_CONTINUE_ON_POTENTIAL_SURVEY_PAGE,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_CONTINUE_ON_SKILLS_PAGE,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_EXPLORE_PATHWAYS_REDIRECT_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_FIND_OUT_MORE_REDIRECT_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_MARKET_JOB_DEMAND_BROWSE_JOBS_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_MARKET_JOB_DEMAND_VIEW_SALARY_GROWTH_INSIGHTS_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_CLICK_REPORT_SWITCH_TILE,
  TRACK_SMART_MATCH_POTENTIAL_CREATE_ACCOUNT_SUCCESSFULLY,
  TRACK_SMART_MATCH_POTENTIAL_CV_EXTRACTED_SUCCESSFULLY,
  TRACK_SMART_MATCH_POTENTIAL_EXPLORE_OTHER_CAREER_PATHWAYS_TILE,
  TRACK_SMART_MATCH_POTENTIAL_LAND_ON_CV_UPLOAD_PAGE,
  TRACK_SMART_MATCH_POTENTIAL_LAND_ON_POTENTIAL_SURVEY,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_FIND_OUT_MORE_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_INCREASE_YOUR_PAY_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_RECOMMENDED_JOBS_TO_BUILD_SKILLS,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_SALARY_NEGOTIATION_GUIDE,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_SALARY_RANGE_LETS_GO_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_CLICK_ON_UPDATE_SWAG_PROFILE_BUTTON,
  TRACK_SMART_MATCH_POTENTIAL_SALARY_INSIGHT_REPORT_UPDATE_USER_SKILL,
  TRACK_SMART_MATCH_QUICK_APPLY_IN_DETAIL_PAGE_CLICK,
  TRACK_SMART_MATCH_QUICK_APPLY_IN_MODAL_CLICK,
  TRACK_SMART_MATCH_SAVED_ROLE_TILE_CLICK,
  TRACK_SMARTMATCH_POTENTIAL_CLICK_ON_SIGN_IN_BUTTON,
  TRACK_SMARTMATCH_POTENTIAL_CLICK_ON_SIGN_UP_BUTTON,
  TRACK_SMARTMATCH_POTENTIAL_CLICK_RECOMMENDED_ROLE_TILE,
  TRACK_SMARTMATCH_POTENTIAL_LANDS_ON_GATED_REPORT,
  TRACK_SUBMIT_REJECTING_CANDIDATE_FORM,
  TRACK_SUBMIT_REQUEST_APPLICATION_INFO_FIELD,
  TRACK_SWAG_CHOOSE_PDF_TEMPLATE_BUTTON_CLICK,
  TRACK_SWAG_COMPLETE_CV,
  TRACK_SWAG_DOWNLOAD_PDF_CV_BUTTON_CLICK,
  TRACK_SWAG_FIND_OUT_MORE_BUTTON_CLICK,
  TRACK_SWAG_INCENTIVE_PROMOTE_BANNER_CLICK,
  TRACK_SWAG_JOBS_VIEW_ACCOUNT_SIGN_UP,
  TRACK_SWAG_POWERED_BY_EH_STATEMENT_CLICK,
  TRACK_SWAG_PROFILE_COPY_LINK,
  TRACK_SWAG_PROFILE_PREVIEW_PUBLIC_MODE,
  TRACK_SWAG_PROFILE_SCMP_FAKE_DOOR_TAIL_VISIT,
  TRACK_SWAG_SWITCHED_PUBLIC_ON,
  TRACK_SWAG_VIEW_CV,
  TRACK_USER_PROFILE_ADD_CERTIFICATION,
  TRACK_USER_PROFILE_ADD_EDUCATION_HISTORY,
  TRACK_USER_PROFILE_ADD_EXPERIENCE,
  TRACK_USER_PROFILE_CLICK_SHARE_PROFILE_BUTTON,
  TRACK_USER_PROFILE_DELETE_ATTACHMENT,
  TRACK_USER_PROFILE_DELETE_CERTIFICATION,
  TRACK_USER_PROFILE_DELETE_EDUCATION_HISTORY,
  TRACK_USER_PROFILE_DELETE_EXPERIENCE,
  TRACK_USER_PROFILE_DOWNLOAD_ATTACHMENT,
  TRACK_USER_PROFILE_EDIT_CERTIFICATION,
  TRACK_USER_PROFILE_EDIT_EDUCATION_HISTORY,
  TRACK_USER_PROFILE_EDIT_EXPERIENCE,
  TRACK_USER_PROFILE_EDIT_PERSONAL_DETAILS,
  TRACK_USER_PROFILE_POPULATE_PROFILE_FROM_RESUME_UPLOAD,
  TRACK_USER_PROFILE_UPDATE_AVATAR_IMAGE,
  TRACK_USER_PROFILE_UPDATE_PROFILE_SUMMARY,
  TRACK_USER_PROFILE_UPDATE_SKILLS,
  TRACK_USER_PROFILE_UPLOAD_ATTACHMENT,
  TRACK_USER_PROFILE_VIEW_PROFILE,
  TRACK_USER_UPLOAD_CV_PARSING,
  TRACT_ATS_RESEND_BUTTON_CLICK,
};
