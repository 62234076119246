const HEIGHTS = {
  NAVBAR: 65,
  HEADER: 44,
  FOOTER: 140,
};

const getRegionBannerHeight = (
  isActive: boolean,
  viewportSize: string
): number => {
  if (viewportSize === 'small') {
    return isActive ? 86 : 0;
  }

  return isActive ? 56 : 0;
};

export const calculateFooterHeight = (
  isRegionBannerOpened: boolean,
  isHideDownloadButton: boolean,
  hasButtons: boolean,
  viewportSize = 'default'
): string => {
  const BUTTONS_HEIGHT = hasButtons ? 64 : 0;
  const DOWNLOAD_HEIGHT = isHideDownloadButton ? 0 : 40;

  const regionBannerHeight = getRegionBannerHeight(
    isRegionBannerOpened,
    viewportSize
  );

  const baseHeight = `100vh - ${regionBannerHeight}px - ${HEIGHTS.NAVBAR}px - ${HEIGHTS.HEADER}px - ${BUTTONS_HEIGHT}px - ${DOWNLOAD_HEIGHT}px - ${HEIGHTS.FOOTER}px`;

  return `calc(${baseHeight})`;
};
