import { useRouter } from 'next/router';

import { DownloadAppType } from '../types';

import { DOWNLOAD_APP_TYPES } from '../constants';

const useLocaleAndAppType = (type?: DownloadAppType) => {
  const router = useRouter();

  const { locale } = router;
  const isAULocale = locale === 'anywhere' || locale === 'AU';
  const isEHJobsInNonAU = !isAULocale && type === DOWNLOAD_APP_TYPES.EH_JOBS;

  return { locale: isAULocale ? '' : locale, isAULocale, isEHJobsInNonAU };
};

export default useLocaleAndAppType;
