import noop from 'lodash/fp/noop';

import React from 'react';

import { Button } from '@hero-design/react';

import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { captureError } from '@packages/sentry-helper';
import { addNotification } from '@shared/utils';

import ChatGPTIcon from './ChatGPTIcon';

export type TResponse = {
  output: string;
};

export interface ChatGPTButtonProps {
  fetchMessageConfigs: ApiConfigs<TResponse, string>;
  value: string;
  setEditorValue: (value: string) => void;
  trackMixPanel?: () => void;
}

const ChatGPTButton = ({
  fetchMessageConfigs,
  value,
  setEditorValue,
  trackMixPanel = noop,
}: ChatGPTButtonProps) => {
  const { fetcher, loading } = useFetch<TResponse, string>({
    ...fetchMessageConfigs,
    onCompleted: res => setEditorValue(res.output),
    onFailed: e => {
      addNotification({
        intent: 'danger',
        content:
          "Sorry, we're experiencing a light technical issue. Please try again later.",
        title: 'Fail',
      });

      captureError('Generate ChatGPT message', { error: e, value });
    },
  });

  return (
    <Button
      variant="text"
      text={<ChatGPTIcon spin={loading} />}
      disabled={loading}
      onClick={() => {
        fetcher(value);
        trackMixPanel();
      }}
      sx={{
        height: 'auto',
        color: 'inherit',
        p: 0,
      }}
    />
  );
};

export default ChatGPTButton;
