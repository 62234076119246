import get from 'lodash/fp/get';

import { Theme } from '@hero-design/react/types/theme';

type ThemeValue = string | number;
type ThemeSelector = (t: Theme) => ThemeValue;
type ThemePath = string;

const isThemeSelector = (a: ThemeSelector | ThemePath): a is ThemeSelector =>
  typeof a === 'function';

/**
 * An utility to quickly access to theme object. Returns a function which
 * generates a theme value injected to a styled-components body.
 *
 * We recommend you to use a function as an input passed to `themeGet` which is
 * type-safe guranteed in TS code.
 *
 * @example
 *   // [Recommended for TS] Selecting theme value by a function
 *   styled.div`
 *     margin-left: ${themeGet(theme => theme.space.small)}px;
 *   `;
 *
 * @example
 *   // [Recommended for JS] Using an ambigus string.
 *   styled.div`
 *     margin-left: ${themeGet('space.small')}px;
 *   `;
 */
const themeGet =
  <T>(getter: ThemeSelector | ThemePath) =>
  (props: T & { theme: Theme }) => {
    if (isThemeSelector(getter)) {
      return getter(props.theme);
    }

    return get(getter)(props.theme) as ThemeValue;
  };

export default themeGet;
