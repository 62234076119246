import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';

import React from 'react';
import styled, { useTheme } from 'styled-components';

import { AvatarItemProps, AvatarProps, OuterAvatarProps } from './types';

export const getFirstChar = (name: string) => name.charAt(0).toUpperCase();

const NAME_REGEX = /[^,$ ]+/g;

export const getShortName = (fullName: string) => {
  const nameTokens = fullName?.match(NAME_REGEX);

  if (!fullName || !nameTokens) return '';

  if (nameTokens.length < 2) {
    return `${getFirstChar(fullName)}${getFirstChar(fullName)}`;
  }

  const firstName = first(nameTokens) || '';
  const lastName = last(nameTokens) || '';

  return `${getFirstChar(firstName)}${getFirstChar(lastName)}`;
};

const AvatarItem = styled.img<AvatarItemProps>`
  &::before {
    content: '${props => props.name}';
    height: 100%;
    background: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

export const checkIsDefaultAvatar = (url = '') =>
  isEmpty(url) || url.endsWith('/avatar.svg') || url.endsWith('/avatar.png');

function enhance(Component: React.ComponentType<AvatarProps>) {
  return function AvatarEnhancer(props: OuterAvatarProps) {
    const { fullName, avatarURL, ...otherProps } = props;

    const enhancedProps = {
      shortName: getShortName(fullName),
      isDefaultAvatar: checkIsDefaultAvatar(avatarURL),
      avatarURL,
      fullName,
      ...otherProps,
    } as AvatarProps;

    return <Component {...enhancedProps} />;
  };
}

const Avatar = ({
  shortName,
  avatarURL,
  isDefaultAvatar,
  styles,
  imageWrapperStyles = {},
  avatarStyles = {},
  className,
  crossOrigin = false,
}: AvatarProps) => {
  const theme = useTheme();
  const [isAvatarUrlValid, setAvatarUrlValidity] = React.useState(true);

  const finalStyles = styles || {
    imageWrapper: {
      paddingRight: theme.space.small,
      ...imageWrapperStyles,
    },
    avatar: {
      height: theme.sizes.xxxlarge,
      width: theme.sizes.xxxlarge,
      borderRadius: '50%',
      border: `${theme.borderWidths.base}px solid ${theme.colors.primary}`,
      color: theme.colors.primary,
      fontSize: theme.fontSizes.xlarge,
      fontWeight: theme.fontWeights.regular,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      lineHeight: theme.sizes.xxxlarge,
      objectFit: 'cover',
      objectPosition: 'center',
      ...avatarStyles,
    },
  };

  return (
    <div className={className} style={finalStyles.imageWrapper}>
      {isDefaultAvatar || !isAvatarUrlValid ? (
        <span style={finalStyles.avatar}>{shortName}</span>
      ) : (
        <AvatarItem
          {...(crossOrigin ? { crossOrigin: 'anonymous' } : {})}
          role="presentation"
          style={finalStyles.avatar}
          alt="Avatar"
          src={avatarURL}
          name={shortName}
          onError={() => setAvatarUrlValidity(false)}
        />
      )}
    </div>
  );
};

export default enhance(Avatar);
