import * as Sentry from '@sentry/nextjs';
import { Context, User } from '@sentry/types';

export interface Extra {
  payload?: Context | null;
  meta?: Context | null;
  debugMeta?: Context | null;
  user?: User;
  [key: string]: unknown;
}

export const captureError = (error: Error | string, extra?: Extra) => {
  if (process.env.NODE_ENV === 'production') {
    const capturer =
      typeof error === 'string'
        ? Sentry.captureMessage
        : Sentry.captureException;

    if (extra != null) {
      const { payload, meta, debugMeta, user, ...additionalExtra } = extra;

      Sentry.withScope(scope => {
        scope.setExtra('extra', JSON.stringify(additionalExtra));
        if (payload != null) scope.setContext('payload', payload);
        if (meta != null) scope.setContext('meta', meta);
        if (debugMeta != null) scope.setContext('debugMeta', debugMeta);
        capturer(error, { user });
      });

      return;
    }

    capturer(error);
    return;
  }

  if (extra != null) {
    // eslint-disable-next-line no-console
    console.error(error, extra);
    return;
  }

  // eslint-disable-next-line no-console
  console.error(error);
};
