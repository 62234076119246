import every from 'lodash/fp/every';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import head from 'lodash/fp/head';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import overSome from 'lodash/fp/overSome';
import reduce from 'lodash/fp/reduce';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import styled from 'styled-components';

import { Box, File, Spinner } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

import FileList from './FileList';
import { isConsumedFile, isProcessedFile, isRawFile } from './utils';
import { CustomFile, ProcessedFile, RawFile, UploadStatus } from './types';

import { UPLOAD_STATUSES } from './constants';

interface FileInputExtraProps {
  loading?: boolean;
  fileRemovePredicate?: (file: CustomFile) => boolean;
}

interface FileInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps?: React.ComponentProps<typeof File.DragAndDrop> & {
    onFilesChange?: (file?: CustomFile) => void;
  };
  labelProps?: LabelProps;
  extraProps?: ExtraProps & FileInputExtraProps;
}

export const cleanUpRawFiles = filter(
  (data: ProcessedFile) => isRawFile(data.file) === false
);

export const prepareInitialFiles = (value: any, multiple?: boolean) => {
  if (isEmpty(value)) {
    return [];
  }

  if (multiple) {
    return value;
  }

  return [value];
};

export const updateFileUploadStatus =
  (status?: UploadStatus) => (file: RawFile | ProcessedFile) => {
    if (
      isProcessedFile(file) &&
      overSome([
        isEqual(UPLOAD_STATUSES.FAILED),
        isEqual(UPLOAD_STATUSES.COMPLETED),
      ])(file.uploadStatus)
    ) {
      return file;
    }

    if (isRawFile(file)) {
      return {
        file,
        uploadStatus: status,
      };
    }

    // The consumed file should be considered as uploaded completely
    if (isConsumedFile(file)) {
      return {
        ...file,
        uploadStatus: UPLOAD_STATUSES.COMPLETED,
      };
    }

    return {
      ...file,
      uploadStatus: status,
    };
  };

export const extractFormData = (files: Array<RawFile>) =>
  reduce((result, file: RawFile) => {
    result.append('files[]', file);
    return result;
  }, new FormData())(files);

const DragAndDropItem = styled.div``;

const DragAndDropWrapper = styled.div<{ themeLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: ${props =>
    props.themeLoading === true ? 'none' : undefined};

  // At the time this component is created, we're using 2 UI libs: react-velonic, hero-design.
  // The hero-design handled input diplay: none; itself.
  // But when integrate with our frontend-core this input get affected by the velonic-theme from react-velonic.
  // The below css snippet is to temporarily fix that affection and the fix is isolated in this component also.
  &&& input {
    display: none;
  }

  > ${DragAndDropItem} {
    width: 100%;
  }
`;

const FileInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {},
  labelProps = {},
  extraProps = {},
}: FileInputProps<TFieldValues, TName>) => {
  const [invalidFiles, setInvalidFiles] = useState<CustomFile[]>([]);
  const { text, description, accept, maxSize, multiple, style, onFilesChange } =
    inputProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const handleOnChange = useMemo(
    () => onFilesChange || onChange,
    [onChange, onFilesChange]
  );
  const initValues = useMemo(
    () => prepareInitialFiles(value, multiple),
    [multiple, value]
  );
  const [files, setFiles] = useState(initValues);
  const {
    error: extraError,
    loading: extraLoading,
    'data-test-id': dataTestId,
    fileRemovePredicate,
    fileListStyle,
    showFiles = true,
    showInvalidFiles = true,
    showFileListAbove = false,
    autoUpdateFiles = false,
  } = extraProps;
  const {
    text: labelText,
    subText: labelSubText,
    helpText: labelHelpText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const hasError = error != null || extraError != null;
  const id = `hero-theme-file-input__${name}`;

  const onAccept = useCallback(
    (acceptedRawFiles: any) => {
      const markFilesAsInitial = map(
        updateFileUploadStatus(UPLOAD_STATUSES.INITIAL)
      );
      const rawFiles = multiple
        ? acceptedRawFiles
        : acceptedRawFiles.slice(0, 1);
      const initialFiles = markFilesAsInitial(rawFiles);

      if (multiple) {
        setFiles([...files, ...initialFiles]);
      } else {
        setFiles(initialFiles);
      }
    },
    [files, setFiles, multiple]
  );
  const onReject = useCallback((rejectedFiles: any) => {
    setInvalidFiles(rejectedFiles);
  }, []);

  useEffect(
    () => {
      if (multiple) {
        handleOnChange(files);
      } else {
        handleOnChange(head(files));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  useEffect(
    () => {
      const isUploadedFiles = multiple
        ? every(
            item => get('uploadStatus', item) === UPLOAD_STATUSES.COMPLETED
          )(value)
        : get('uploadStatus', value) === UPLOAD_STATUSES.COMPLETED;

      const shouldUpdateFiles = multiple
        ? !isEqual(value, files)
        : !isEqual(
            get('file.file_id', value),
            get('file.file_id', head(files))
          );

      if (autoUpdateFiles && isUploadedFiles && shouldUpdateFiles) {
        const newFiles = multiple ? value : [value];

        setFiles(newFiles);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        tooltip={tooltip}
        htmlFor={id}
        required={required}
        subText={labelSubText}
        helpText={labelHelpText}
        text={labelText}
        hasError={hasError}
        input={
          <DragAndDropWrapper themeLoading={extraLoading}>
            {showFileListAbove ? (
              <DragAndDropItem>
                {showInvalidFiles && !isEmpty(invalidFiles) && (
                  <FileList
                    accept={accept}
                    maxSize={maxSize}
                    files={invalidFiles}
                    onChange={setInvalidFiles}
                  />
                )}

                {showFiles && !isEmpty(files) && (
                  <Box style={fileListStyle}>
                    <FileList
                      files={files}
                      onChange={setFiles}
                      fileRemovePredicate={fileRemovePredicate}
                    />
                  </Box>
                )}
              </DragAndDropItem>
            ) : null}

            <DragAndDropItem>
              <Spinner size="small" loading={extraLoading}>
                <File.DragAndDrop
                  id={id}
                  style={style}
                  text={text}
                  description={description}
                  accept={accept}
                  maxSize={maxSize}
                  multiple={multiple}
                  onAccept={onAccept}
                  onReject={onReject}
                />
              </Spinner>
            </DragAndDropItem>

            {!showFileListAbove ? (
              <DragAndDropItem>
                {showInvalidFiles && !isEmpty(invalidFiles) && (
                  <FileList
                    accept={accept}
                    maxSize={maxSize}
                    files={invalidFiles}
                    onChange={setInvalidFiles}
                  />
                )}

                {showFiles && !isEmpty(files) && (
                  <Box style={fileListStyle}>
                    <FileList
                      files={files}
                      onChange={setFiles}
                      fileRemovePredicate={fileRemovePredicate}
                    />
                  </Box>
                )}
              </DragAndDropItem>
            ) : null}
          </DragAndDropWrapper>
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export { UPLOAD_STATUSES };

export default FileInput;
