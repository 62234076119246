import styled from 'styled-components';

import { Box, Menu, Typography } from '@hero-design/react';

import { Option } from '../types';

import { LOGIN_OPTIONS } from '../constants';

type Props = {
  handleChooseOption: (option: Option) => void;
};

const MenuItem = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLightBackground};
  }
  border-radius: ${({ theme }) => `${theme.radii.base}px`};
  height: ${({ theme }) => theme.sizes.xlarge}px;
`;

const LoginOptions: React.FC<Props> = ({ handleChooseOption }) => (
  <Menu>
    <Box
      sx={{
        pt: 'medium',
        pb: 'medium',
        pr: 'small',
        pl: 'small',
        width: '200px',
      }}
    >
      <Typography.Text
        fontSize={16}
        fontWeight="semi-bold"
        sx={{ ml: 'small' }}
      >
        Desktop login
      </Typography.Text>

      {LOGIN_OPTIONS.map(option => (
        <Box sx={{ mt: 'medium' }} key={option.name}>
          <MenuItem onClick={() => handleChooseOption(option)}>
            <Typography.Text sx={{ ml: 'medium' }}>
              {option.title}
            </Typography.Text>
          </MenuItem>
        </Box>
      ))}
    </Box>
  </Menu>
);

export default LoginOptions;
