import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Radio } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface RadioButtonInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<
    React.ComponentProps<typeof Radio.ButtonGroup>,
    'onChange' | 'value' | 'name'
  > & {
    disabled?: boolean;
  };
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
}

const RadioButtonInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: RadioButtonInputProps<TFieldValues, TName>) => {
  const { options, disabled, layout } = inputProps;
  const { text: labelText, required, tooltip, inline = false } = labelProps;
  const { error } = fieldState;
  const { value, onChange, name } = field;
  const { error: extraError, 'data-test-id': dataTestId } = extraProps;
  const hasError = error != null || extraError != null;
  const inputOptions = React.useMemo(
    () => options.map(opt => ({ ...opt, disabled: opt.disabled || disabled })),
    [options, disabled]
  );

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        input={
          <Radio.ButtonGroup
            name={name}
            value={value}
            options={inputOptions}
            onChange={onChange}
            layout={layout}
          />
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default RadioButtonInput;
