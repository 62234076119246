/* eslint-disable no-plusplus */
/* eslint-disable immutable/no-let */
export const PASSWORD_SCORE = {
  WEAK: 'Weak',
  STRONG: 'Strong',
};

// Regular Expressions
// eslint-disable-next-line no-useless-escape
const regExpQuote = (str: string) =>
  str.replace(/([.?*+\^$\[\]\\(){}|\-\/])/g, '\\$1');
const symbolChars = [
  ' ',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '-',
  '=',
  '[',
  ']',
  '{',
  '}',
  ';',
  '`',
  "'",
  ':',
  '"',
  '\\',
  '|',
  ',',
  '.',
  '<',
  '>',
  '/',
  '?',
  '~',
];
const quotedSymbols = `.*[${regExpQuote(symbolChars.join())}]`;
const regEx = new RegExp(`(${quotedSymbols})`);

// Condition checker methods
// Define repeatedChars function that calculates the count of repeated characters in a given password string based on a specified 'repeatedTimes' parameter.
// repeatedTimes: A number representing the minimum number of characters that must be repeated consecutively to be considered as repeated characters.
export const repeatedChars = (repeatedTimes: number, password: string) => {
  let result = '';
  let i = 0;

  while (i < password.length) {
    let repeated = true;
    let j = 0;

    while (j < repeatedTimes && i + j + repeatedTimes < password.length) {
      repeated =
        repeated &&
        password.charAt(i + j) === password.charAt(i + j + repeatedTimes);

      j++;
    }

    if (j < repeatedTimes) {
      repeated = false;
    }

    if (repeated) {
      i += repeatedTimes - 1;
    } else {
      result += password.charAt(i);
    }

    i++;
  }

  return password.length - result.length;
};

export const hasThreeNumbers = (password: string) =>
  password.match(/(.*[0-9].*[0-9].*[0-9])/);
export const hasTwoSymbols = (password: string) => {
  const sampleSymbols = `.*[${regExpQuote(symbolChars.join())}]`;
  const symbols = new RegExp(`(${sampleSymbols}${sampleSymbols})`);

  return password.match(symbols);
};

export const hasMixedCasing = (password: string) =>
  password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/);
export const isAlphaNumeric = (password: string) =>
  password.match(/([a-zA-Z])/) && password.match(/([0-9])/);
export const hasSymbol = (password: string) => password.match(regEx);
export const hasNumberAndSymbol = (password: string) =>
  hasSymbol(password) && password.match(/([0-9])/);
export const hasCharAndSymbol = (password: string) =>
  hasSymbol(password) && password.match(/([a-zA-Z])/);
export const isNumberOrCharsOnly = (password: string) =>
  password.match(/^[a-zA-Z]+$/) || password.match(/^\d+$/);

// --- PASSWORD STRENGTH ---
// ALgorithm is from https://www.evernote.com/shard/s308/sh/5633b5b0-0c8b-48da-9a28-6630e4fdd4cb/af66c109009bd54b
export const calculateScore = (password: string, username?: string) => {
  let score = 0;

  if (password == null || password.length < 6) {
    return 0;
  }

  if (username != null && username.length) {
    const user = new RegExp(username.toLowerCase());

    if (password.toLowerCase().match(user)) {
      return 0;
    }
  }

  // password length
  score += password.length * 4;

  // characters repetition
  for (let repeatedTimes = 1; repeatedTimes < 5; repeatedTimes++) {
    score -= repeatedChars(repeatedTimes, password);
  }

  if (hasThreeNumbers(password)) {
    score += 5;
  }

  if (hasTwoSymbols(password)) {
    score += 5;
  }

  if (hasMixedCasing(password)) {
    score += 10;
  }

  if (isAlphaNumeric(password)) {
    score += 15;
  }

  if (hasNumberAndSymbol(password)) {
    score += 15;
  }

  if (hasCharAndSymbol(password)) {
    score += 15;
  }

  if (isNumberOrCharsOnly(password)) {
    score -= 10;
  }

  if (score > 100) {
    score = 100;
  }

  if (score < 0) {
    score = 0;
  }

  return score;
};

export const calcPasswordStrength = (password: string, userEmail?: string) => {
  const score = calculateScore(password, userEmail);

  if (score <= 68 || password.length < 8) {
    return PASSWORD_SCORE.WEAK;
  }

  return PASSWORD_SCORE.STRONG;
};
