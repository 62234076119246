import { BREAK_POINT } from './mediaQueries';

const mediaMaxQueries = (key: keyof typeof BREAK_POINT) =>
  `@media (max-width: ${BREAK_POINT[key]}px)`;

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.BREAK_POINT = BREAK_POINT;

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.sm = mediaMaxQueries('sm');

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.md = mediaMaxQueries('md');

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.lg = mediaMaxQueries('lg');

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.xl = mediaMaxQueries('xl');

// eslint-disable-next-line immutable/no-mutation
mediaMaxQueries.xxl = mediaMaxQueries('xxl');

export default mediaMaxQueries;
