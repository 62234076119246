import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { getCallbackUrl } from '@shared/AuthStatus/AuthStatusNotSignedIn';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useFetchPublicProfile from '@shared/hooks/useFetchPublicProfile';
import useWindowSize from '@shared/hooks/useWindowSize';

const useNavBarActions = () => {
  const { isMediumScreen, isSmallScreen, isXSmallScreen } = useWindowSize();
  const router = useRouter();
  const { data: session, status } = useSession();
  const user = session?.user;
  const { permissionsData } = useFetchPermissions();

  const { callbackUrl, previewMode, token, friendlyId } = router.query || {};
  const encodedCallbackUrl = callbackUrl
    ? encodeURIComponent(callbackUrl as string)
    : getCallbackUrl(router.asPath);

  const { publicProfileData } = useFetchPublicProfile({
    friendlyId: friendlyId as string,
    previewMode: !!previewMode,
    customToken: previewMode ? (token as string) : undefined,
  });

  const showNotificationBell = useMemo(
    () =>
      status === 'authenticated' &&
      (permissionsData?.data.direct_messaging_slice_2_enabled ||
        permissionsData?.data.notification_v1_enabled),
    [status, permissionsData]
  );

  const allowJobSeekersStream1Experiment1Enabled = useMemo(
    () => !!publicProfileData?.data.job_seekers_stream_1_experiment_1_enabled,
    [publicProfileData]
  );

  const isOnStream1Experiment1 = useMemo(
    () =>
      router.pathname === '/user/[friendlyId]' &&
      status !== 'authenticated' &&
      allowJobSeekersStream1Experiment1Enabled,
    [router.pathname, status, allowJobSeekersStream1Experiment1Enabled]
  );

  const hideStatus = useMemo(
    () => status === 'loading' || isOnStream1Experiment1,
    [status, isOnStream1Experiment1]
  );

  const showLoginRedirectDropdown = useMemo(
    () => !hideStatus && !user,
    [hideStatus, user]
  );

  const showSignedInStatus = useMemo(
    () => !hideStatus && user,
    [hideStatus, user]
  );

  return {
    isMediumScreen,
    isSmallScreen,
    isXSmallScreen,
    encodedCallbackUrl,
    showNotificationBell,
    showLoginRedirectDropdown,
    showSignedInStatus,
    session,
  };
};

export default useNavBarActions;
