export const SPECIFIC_AUTH_TOKEN_ENDPOINT_WHITELIST_REGEX = [
  /\/api\/v1\/dumb_match\/businesses\/[^/]+$/,
  /\/api\/v1\/dumb_match\/businesses\/.*\/marketplace_applications\/[^/]+$/,
  /\/api\/v1\/dumb_match\/businesses\/.*\/marketplace_applications\/[^/]+\/application_info_request$/,
  /\/api\/v1\/dumb_match\/businesses\/.*\/marketplace_applications\/[^/]+\/application_status$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/[^/]+$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/applications$/,
  /\/api\/v1\/businesses\/marketplace_businesses\/.*\/universal_setup_link$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/preferred_roles$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/send_application_link$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/hiring_status$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/email_subscriptions$/,
  /\/api\/v1\/public\/marketplace\/marketplace_businesses\/.*\/marketplace_applications\/[^/]+\/change_status$/,
  /\/api\/v1\/public\/limit_access_authorizations\/.*\/validate_reset_code$/,
  /\/api\/v1\/public\/limit_access_authorizations\/.*\/send_reset_code$/,
  /\/api\/v2\/firebase\/application_page_messages$/,
];
