import has from 'lodash/fp/has';

import { ConsumedFile, ProcessedFile, RawFile } from './types';

// The file returned by hero-design's File.DragAndDrop
export const isRawFile = (file: any): file is RawFile =>
  file && file instanceof Blob;

// The file is consumed by a service, eg: ats, custom survey, ...
export const isConsumedFile = (file: any): file is ConsumedFile =>
  has('file_id')(file);

export const isProcessedFile = (file: any): file is ProcessedFile =>
  has('uploadStatus')(file);
