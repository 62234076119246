import React, { Component, ErrorInfo, ReactNode } from 'react';

import { Box, Button, Result } from '@hero-design/react';

import { captureError } from '../sentry-helper';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line immutable/no-mutation, immutable/no-this
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: ErrorInfo) {
    captureError(error, { info });
  }

  render() {
    // eslint-disable-next-line immutable/no-this
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Result
            title="Oops, we have temporarily gotten lost!"
            subTitle="Please try refreshing your browser or tap the `Try again` button below."
            status="404"
            extra={
              <Button
                text="Try again"
                onClick={() => window.location.reload()}
              />
            }
          />
        </Box>
      );
    }

    // eslint-disable-next-line immutable/no-this
    return this.props.children;
  }
}

export default ErrorBoundary;
