import React from 'react';
import styled from 'styled-components';

import { Box, Collapse, Icon, Typography } from '@hero-design/react';

import NavBarActions from '@shared/NavBarActions';
import useWindowSize from '@shared/hooks/useWindowSize';

import { useMegaMenu } from './MegaMenu/context';
import { HoverBox } from './MegaMenu/styledComponents';
import { MenuItem } from './types';

const NAV_MENU_GAP = 120;

const SideBarWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
`;

interface SideBarProp {
  navBarItems: MenuItem[];
  isOpenSideBar: boolean;
  setOpenSideBar: (open: boolean) => void;
}

const SideBar = ({
  navBarItems,
  isOpenSideBar,
  setOpenSideBar,
}: SideBarProp) => {
  const { height } = useWindowSize();

  const { selectedLevel1, setSelectedLevel1 } = useMegaMenu();

  if (selectedLevel1) {
    return null;
  }

  return (
    <Collapse open={isOpenSideBar}>
      <SideBarWrapper>
        <Box
          sx={{
            height: height ? height - NAV_MENU_GAP : '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%' }}>
            {navBarItems.map(navBarItem => (
              <HoverBox
                key={navBarItem.title}
                p="large"
                onClick={() => {
                  setSelectedLevel1(navBarItem);
                }}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography.Text fontSize={16} fontWeight="semi-bold">
                  {navBarItem.title}
                </Typography.Text>

                <Icon size="large" icon="arrow-right" />
              </HoverBox>
            ))}
          </Box>

          <NavBarActions
            variant="sideBar"
            isOpenSideBar={isOpenSideBar}
            setOpenSideBar={setOpenSideBar}
          />
        </Box>
      </SideBarWrapper>
    </Collapse>
  );
};

export default SideBar;
