import { dynamicImport } from '@packages/eh-utils/dynamicImport';

export const RichTextInput = dynamicImport(() => import('./RichTextInput'), {
  ssr: false,
});

export { default as ChatGPTTextAreaInput } from './ChatGPTTextAreaInput';
export { default as CheckboxButtonGroupInput } from './CheckboxButtonGroupInput';
export { default as CheckboxInput } from './CheckboxInput';
export { default as DateInput } from './DateInput';
export { default as FieldError } from './Error';
export { default as FileInput } from './FileInput';
export { default as MultiSelectInput } from './MultiSelectInput';
export { default as PhoneNumberInput } from './PhoneNumberInput';
export { default as RadioButtonInput } from './RadioButtonInput';
export { default as RadioInput } from './RadioInput';
export { default as SelectInput } from './SelectInput';
export { default as SingleCheckboxInput } from './SingleCheckboxInput';
export { default as SliderInput } from './SliderInput';
export { default as TextAreaInput } from './TextAreaInput';
export { default as TextInput } from './TextInput';
