import React, { useCallback, useEffect, useState } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import { useTheme } from 'styled-components';

import { Box, Input, Select } from '@hero-design/react';

import ErrorBoundaryMobile from '@packages/error-boundary/ErrorBoundaryMobile';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

import { getFlagEmoji, getPhoneCodeFromPhoneNumber } from './utils';

import { PHONE_CODE_OPTIONS } from './constants';

type ExtraInputProps = {
  menuVariant?: 'wrap' | 'nowrap';
};

export interface PhoneNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<React.ComponentProps<typeof Input>, 'onChange' | 'value'> &
    ExtraInputProps;
  labelProps?: LabelProps;
  extraProps?: ExtraProps;
}

const PhoneNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: PhoneNumberInputProps<TFieldValues, TName>) => {
  const theme = useTheme();

  const {
    onBlur: externalOnBlur,
    onFocus,
    placeholder,
    prefix,
    disabled,
    autoComplete = 'off',
    size,
    onKeyDown,
    autoFocus,
    className,
    ref,
  } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { 'data-test-id': dataTestId } = extraProps;
  const { error } = fieldState;
  const { value, onChange: onChangeField, onBlur: fieldOnBlur, name } = field;
  const hasError = error != null;

  const [code, setCode] = useState(getPhoneCodeFromPhoneNumber(value) || '+61');
  const [number, setNumber] = useState(value?.replace(code, ''));

  const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    e => {
      externalOnBlur?.(e);
      fieldOnBlur();
    },
    [externalOnBlur, fieldOnBlur]
  );
  const id = `hero-theme-phone-number-input__${name}`;

  useEffect(() => {
    if (code && number) {
      onChangeField(code + number);
    }
  }, [code, number, onChangeField]);

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <ErrorBoundaryMobile>
            <Box sx={{ display: 'flex' }}>
              <Select
                id={`${id}-code`}
                name={`${name}-code`}
                value={code}
                options={PHONE_CODE_OPTIONS}
                optionRenderer={({ option }) => (
                  <Box data-test-id={`flag-country-option-${option.text}`}>
                    {getFlagEmoji(option.text)}
                  </Box>
                )}
                selectedOptionRenderer={({ option }) =>
                  getFlagEmoji(option.text)
                }
                onChange={val => setCode(val as string)}
                prefix={prefix}
                invalid={hasError}
                disabled={disabled}
                size={size}
                sx={{
                  mr: 'xsmall',
                  width: theme.space.xxxlarge + theme.space.small,
                }}
              />
              <Input
                id={id}
                value={number}
                name={`${name}-number`}
                onChange={e => setNumber(e.target.value ?? null)}
                onBlur={onBlur}
                placeholder={placeholder}
                prefix={<span style={{ margin: 0, padding: 0 }}>{code}</span>}
                invalid={hasError}
                disabled={disabled}
                type="number"
                className={className}
                autoFocus={autoFocus}
                onFocus={onFocus}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
                size={size}
                ref={ref}
                sx={{ width: '100%' }}
              />
            </Box>
          </ErrorBoundaryMobile>
        }
      />

      {hasError && <Error text={error?.message as string} />}
    </InputContainer>
  );
};

export default PhoneNumberInput;
