import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';

type TResendVerificationEmailParams = {
  email: string;
};

const useResendVerificationEmail = ({
  onCompleted,
  onFailed,
}: Pick<
  ApiConfigs<unknown, TResendVerificationEmailParams>,
  'onCompleted' | 'onFailed'
>) => {
  const { fetcher, loading } = useFetch<
    unknown,
    TResendVerificationEmailParams
  >({
    method: 'POST',
    endpoint: `${getDirectMainAppHost()}/api/v4/accounts/verifications/send_email`,
    formatBody: params => {
      if (params) {
        return JSON.stringify({
          email: params.email,
        });
      }
    },
    onCompleted,
    onFailed,
  });

  return {
    resendVerififcationEmail: fetcher,
    isResendingVerificationEmail: loading,
  };
};

export default useResendVerificationEmail;
