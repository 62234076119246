import Script from 'next/script';

import { getWebtrendsOptimizeAccountId } from '@packages/eh-utils/browserEnv';

const WebtrendsOptimizeTagContainer = () => {
  const webtrendsOptimizeAccountId = getWebtrendsOptimizeAccountId();

  if (!webtrendsOptimizeAccountId) {
    return null;
  }

  return (
    <Script
      type="text/javascript"
      id="webtrends-optimize-tag-script"
      data-test-id="webtrends-optimize-tag-settings"
      src={`//c.webtrends-optimize.com/acs/accounts/${webtrendsOptimizeAccountId}/js/wt.js`}
    />
  );
};

export default WebtrendsOptimizeTagContainer;
