import styled from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';

interface ReferenceProps {
  title: string;
  links: { text: string; href: string; icon?: string }[];
}

const Link = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.medium}px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Reference: React.FC<ReferenceProps> = ({ title, links }) => (
  <Box>
    <Typography.Text fontSize={16} fontWeight="semi-bold">
      {title}
    </Typography.Text>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {links.map(link => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', mt: 'large' }}
          key={link.text}
        >
          {link.icon && (
            <Image
              src={`/assets/images/${link.icon}`}
              sx={{ width: 20, height: 20 }}
              style={{ marginRight: '8px' }}
            />
          )}
          <Link
            key={link.text}
            href={link.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            {link.text}
          </Link>
        </Box>
      ))}
    </Box>
  </Box>
);

export default Reference;
