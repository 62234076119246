import isUndefined from 'lodash/fp/isUndefined';

import { useEffect, useState } from 'react';

type TWindowSize = {
  width?: number;
  height?: number;
  isMediumScreen: boolean;
  isSmallScreen: boolean;
  isXSmallScreen: boolean;
};

type WindowSize = {
  width?: number;
  height?: number;
};

const useWindowSize = (): TWindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    isMediumScreen: !isUndefined(windowSize.width) && windowSize.width < 992,
    isSmallScreen: !isUndefined(windowSize.width) && windowSize.width < 768,
    isXSmallScreen: !isUndefined(windowSize.width) && windowSize.width < 575,
  };
};

export default useWindowSize;
