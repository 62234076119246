/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable vars-on-top */
/* eslint-disable no-underscore-dangle */
/* eslint-disable immutable/no-mutation */

declare global {
  interface Window {
    __insp: any;
    attachEvent: any;
    __inspld: number;
  }
}

const logId = '[next-inspeclet]';

const Inspectlet = {
  initialize: (wid: string) => {
    if (!wid) {
      console.warn(logId, 'wid is required'); // eslint-disable-line no-console
      return;
    }

    window.__insp = window.__insp || [];

    window.__insp.push(['wid', wid]);

    (function () {
      function ldinsp() {
        if (typeof window.__inspld !== 'undefined') return;
        window.__inspld = 1;
        const insp = document.createElement('script');

        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = 'inspsync';
        insp.src = `${
          document.location.protocol === 'https:' ? 'https' : 'http'
        }://cdn.inspectlet.com/inspectlet.js`;

        const head = document.getElementsByTagName('head')[0];

        head.appendChild(insp);
      }

      setTimeout(ldinsp, 500);
      if (document.readyState !== 'complete') {
        if (window.attachEvent) {
          window.attachEvent('onload', ldinsp);
        } else {
          window.addEventListener('load', ldinsp, false);
        }
      } else {
        ldinsp();
      }
    })();
    /* eslint-enable */
  },

  tagSession: (session: string) =>
    window.__insp && window.__insp.push(['tagSession', session]), // eslint-disable-line no-underscore-dangle, no-undef
};

export default Inspectlet;
