import { useEffect } from 'react';

import mixpanel, { Dict, Query } from 'mixpanel-browser';

export const useMixpanelTracking = () => ({
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
  getDistinctId: () => mixpanel.get_distinct_id(),
});

export const useMixpanelSessionReplay = () => {
  useEffect(() => {
    mixpanel.start_session_recording();

    return () => {
      mixpanel.stop_session_recording();
    };
  }, []);
};

export * from './events';
