import Script from 'next/script';

import { getMazeAccountId } from '@packages/eh-utils/browserEnv';

const MazeContainer = () => {
  const mazeAccountId = getMazeAccountId();

  if (!mazeAccountId) {
    return null;
  }

  return (
    <Script id="maze-container-script">
      {`(function (m, a, z, e) {
  var s, t;
  try {
    t = m.sessionStorage.getItem('maze-us');
  } catch (err) {}

  if (!t) {
    t = new Date().getTime();
    try {
      m.sessionStorage.setItem('maze-us', t);
    } catch (err) {}
  }

  s = a.createElement('script');
  s.src = z + '?apiKey=' + e;
  s.async = true;
  a.getElementsByTagName('head')[0].appendChild(s);
  m.mazeUniversalSnippetApiKey = e;
})(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '${mazeAccountId}');`}
    </Script>
  );
};

export default MazeContainer;
