import { useEffect } from 'react';
import Script from 'next/script';
import { useSession } from 'next-auth/react';

import dayjs from 'dayjs';

import { getAppcuesAccountId } from '@packages/eh-utils/browserEnv';

import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';

const AppcuesContainer = () => {
  const { status, data } = useSession();
  const { userProfileData } = useFetchUserProfile();
  const isAuthenticated = status === 'authenticated';

  useEffect(() => {
    if (isAuthenticated && data?.user.email && userProfileData?.data) {
      window.Appcues?.identify(data?.user.email, {
        source: 'career-page',
        created_at: dayjs(userProfileData.data?.created_at).isValid()
          ? dayjs(userProfileData.data?.created_at).format('YYYY-MM-DD')
          : undefined,
      });
    }
  }, [isAuthenticated, data, userProfileData?.data]);

  return (
    <>
      <Script id="appcues" type="text/javascript">
        {`window.AppcuesSettings = { enableURLDetection: true }`}
      </Script>

      <Script src={`https://fast.appcues.com/${getAppcuesAccountId()}.js`} />
    </>
  );
};

export default AppcuesContainer;
