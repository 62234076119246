import { useMemo } from 'react';
import { useTheme } from 'styled-components';

type Props = {
  /**
   * The color variant of the logo.
   * @default 'black'
   */
  color?: 'black' | 'white';

  /**
   * The width of the logo in pixels.
   * @default 90
   */
  width?: number;

  /**
   * Optional ID for the SVG element.
   */
  id?: string;

  /**
   * Accessible title for the logo.
   * @default 'Swag Logo'
   */
  title?: string;
};

/**
 * Logo component displays the Swag logo as an SVG.
 *
 * We can use screen.getByRole('img', { name: 'Swag Logo' }); to find the logo in tests.
 *
 * @component
 * @example
 * // Basic usage
 * <Logo />
 *
 * @example
 * // Custom variant and width
 * <Logo color="white" width={120} />
 *
 * @example
 * // With custom ID and title
 * <Logo id="header-logo" title="Company Logo" />
 */
export const Logo = ({
  color = 'black',
  width = 90,
  id,
  title = 'Swag Logo',
}: Props) => {
  const theme = useTheme();

  const fillColor = useMemo(() => {
    if (color === 'white') {
      return theme.colors.palette.white; // we don't have a white color in the theme colors
    }

    return theme.colors.black;
  }, [color, theme.colors.black, theme.colors.palette.white]);

  return (
    <svg
      viewBox="0 0 1752 587"
      width={width}
      color={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      role="img"
    >
      <title>{title}</title>
      <path
        d="M111.202 149.84C111.202 129.364 134.022 112.558 172.826 112.558C219.23 112.558 248.164 136.686 239.014 172.499H339.443C354.664 86.2284 277.055 34.3237 172.804 34.3237C76.1972 34.3446 4.65887 79.6617 4.65887 154.959C4.65887 293.848 245.85 244.88 245.85 307.023C245.85 328.947 217.701 343.57 177.368 343.57C127.143 343.57 97.4665 320.912 99.7594 284.365H0.0949233C-2.94043 369.166 67.0474 421.805 177.39 421.805C280.112 421.805 352.393 377.222 352.393 301.191C352.393 161.568 111.202 214.207 111.202 149.861V149.84Z"
        fill="currentColor"
      />
      <path
        d="M1153.57 34.3445C1053.14 34.3445 974.77 85.515 994.555 169.582H1098.04C1091.18 136.685 1114.03 118.412 1152.83 118.412C1206.85 118.412 1225.11 153.511 1225.87 201.745C1200.76 196.626 1171.85 193.709 1142.94 193.709C1035.65 193.709 967.935 238.292 967.935 312.142C967.935 375.019 1016.63 421.805 1099.57 421.805C1161.96 421.805 1208.38 395.496 1236.53 347.976V410.853H1329.36V203.969C1329.36 100.893 1270.01 34.3655 1153.59 34.3655H1153.57V34.3445ZM1123.13 346.486C1091.18 346.486 1071.4 333.332 1071.4 307.736C1071.4 279.959 1095.75 261.685 1152.81 261.685C1175.63 261.685 1204.54 265.335 1228.89 272.658C1211.4 317.261 1171.83 346.486 1123.13 346.486Z"
        fill="currentColor"
      />
      <path
        d="M1607.81 328.947C1680.85 289.462 1721.18 230.991 1721.18 171.051C1721.18 152.777 1717.38 135.951 1710.53 120.615C1741.73 91.3684 1757.7 42.4008 1749.33 0H1672.49C1678.58 24.8614 1681.62 52.6391 1674.78 74.5633C1644.34 48.9676 1599.44 34.3654 1544.67 34.3654C1438.15 34.3654 1367.38 89.9207 1367.38 171.072C1367.38 247.838 1430.53 296.806 1523.36 296.806C1541.62 296.806 1558.37 294.603 1573.59 290.952C1540.11 313.611 1492.18 330.437 1428.26 337.738V402.797C1463.27 394.027 1504.34 388.174 1537.82 388.174C1598.68 388.174 1627.61 408.65 1627.61 437.162C1627.61 465.674 1594.9 482.479 1552.27 482.479C1509.65 482.479 1479.23 466.388 1472.37 437.897H1357.49C1373.47 518.314 1451.85 568.015 1553.04 568.015C1654.23 568.015 1735.64 517.579 1735.64 442.282C1735.64 381.607 1685.41 339.941 1607.81 328.968V328.947ZM1544.65 225.138C1502.81 225.138 1474.64 203.948 1474.64 172.498C1474.64 141.049 1502.79 119.859 1544.65 119.859C1586.51 119.859 1613.9 141.049 1613.9 172.498C1613.9 203.948 1586.51 225.138 1544.65 225.138Z"
        fill="currentColor"
      />
      <path
        d="M891.571 35.6243L770.113 326.199V123.09C770.113 74.1647 728.688 34.3445 677.764 34.3445C641.624 34.3445 608.606 54.8211 593.604 86.501L480.859 326.807V35.3935H386.392V331.863C386.392 380.474 427.009 420.798 476.929 421.763C478.654 421.805 480.423 421.763 482.213 421.721C518.135 420.462 551.524 398.706 565.281 367.676L675.646 132.405V331.863C675.624 380.474 716.241 420.798 766.161 421.763H768.083C769.196 421.763 770.332 421.763 771.467 421.7C807.739 420.441 841.259 398.391 854.907 366.774L993.332 35.5823H891.549V35.6243H891.571Z"
        fill="currentColor"
      />
      <path
        d="M33.7008 548.189V559.371H22.4766V463.618H33.7008V502.536C38.0464 495.256 47.1525 490.41 57.1102 490.41C79.122 490.41 91.176 505.62 91.176 525.425C91.176 545.231 79.122 560.441 57.1102 560.441C47.3053 560.441 38.0464 555.595 33.7008 548.189ZM79.6897 525.425C79.6897 510.068 70.4308 500.102 56.1493 500.102C41.8679 500.102 32.74 510.068 32.74 525.425C32.74 540.783 41.9989 550.748 56.1493 550.748C70.2998 550.748 79.6897 540.783 79.6897 525.425Z"
        fill="currentColor"
      />
      <path
        d="M136.86 550.077L123.954 586.834H113.167L122.142 560.441L95.7844 491.479H107.424L127.47 545.356L147.516 491.479H159.287L136.86 550.056V550.077Z"
        fill="currentColor"
      />
      <path
        d="M204.839 465.087H266.245V475.451H216.762V505.474H256.986V515.838H216.762V548.965H267.795V559.33H204.861V465.045V465.087H204.839Z"
        fill="currentColor"
      />
      <path
        d="M283.059 491.48H294.283V504.551C296.947 496.327 304.787 490.41 315.596 490.41C327.235 490.41 336.341 495.53 338.874 504.824C341.538 496.746 350.928 490.41 361.716 490.41C377.002 490.41 386.239 500.25 386.239 516.677V559.372H375.015V519.51C375.015 507.929 368.988 499.977 359.03 499.977C347.26 499.977 340.25 507.929 340.25 519.51V559.372H329.026V519.51C329.026 507.929 322.999 499.977 313.194 499.977C301.271 499.977 294.261 507.929 294.261 519.51V559.372H283.037V491.501H283.059V491.48Z"
        fill="currentColor"
      />
      <path
        d="M405.281 491.48H416.505V502.663C420.851 495.257 429.957 490.41 440.068 490.41C461.927 490.41 473.981 505.621 473.981 525.426C473.981 545.231 461.643 560.442 439.784 560.442C429.826 560.442 420.72 555.722 416.505 548.462V586.856H405.281V491.501V491.48ZM462.341 525.426C462.341 510.069 453.083 500.103 438.932 500.103C424.782 500.103 415.523 510.069 415.523 525.426C415.523 540.784 424.782 550.749 438.932 550.749C453.083 550.749 462.341 540.784 462.341 525.426Z"
        fill="currentColor"
      />
      <path
        d="M488.96 463.597H500.184V559.35H488.96V463.597Z"
        fill="currentColor"
      />
      <path
        d="M515.187 525.426C515.187 504.95 530.189 490.41 551.065 490.41C571.941 490.41 587.096 504.95 587.096 525.426C587.096 545.903 572.094 560.442 551.065 560.442C530.036 560.442 515.187 545.756 515.187 525.426ZM575.61 525.426C575.61 510.614 565.652 500.103 551.087 500.103C536.522 500.103 526.695 510.614 526.695 525.426C526.695 540.238 536.653 550.749 551.087 550.749C565.521 550.749 575.61 540.238 575.61 525.426Z"
        fill="currentColor"
      />
      <path
        d="M632.78 550.077L619.874 586.834H609.087L618.062 560.441L591.705 491.479H603.344L623.39 545.356L643.437 491.479H655.207L632.78 550.056V550.077Z"
        fill="currentColor"
      />
      <path
        d="M666.278 491.48H677.503V504.551C680.167 496.327 688.006 490.41 698.816 490.41C710.455 490.41 719.561 495.53 722.094 504.824C724.758 496.746 734.148 490.41 744.936 490.41C760.222 490.41 769.459 500.25 769.459 516.677V559.372H758.234V519.51C758.234 507.929 752.207 499.977 742.25 499.977C730.479 499.977 723.47 507.929 723.47 519.51V559.372H712.245V519.51C712.245 507.929 706.218 499.977 696.414 499.977C684.491 499.977 677.481 507.929 677.481 519.51V559.372H666.257V491.501H666.278V491.48Z"
        fill="currentColor"
      />
      <path
        d="M851.174 537.553C848.794 551.421 836.325 560.442 818.79 560.442C797.914 560.442 783.894 544.413 783.894 525.028C783.894 505.642 796.8 490.41 817.545 490.41C838.29 490.41 850.497 504.152 850.497 522.195C850.497 523.685 850.366 525.972 850.213 526.916H794.987C795.839 540.93 805.491 550.896 818.812 550.896C830.298 550.896 837.592 546.05 839.841 537.574H851.196H851.174V537.553ZM795.512 518.293H838.967C838.116 506.838 830.145 499.579 817.523 499.579C805.6 499.579 797.608 506.313 795.512 518.293Z"
        fill="currentColor"
      />
      <path
        d="M865.607 491.48H876.832V504.551C880.478 495.131 889.301 490.41 900.11 490.41C916.095 490.41 926.467 501.32 926.467 517.894V559.372H915.243V519.636C915.243 508.055 908.233 500.25 897.577 500.25C885.523 500.25 876.832 509.544 876.832 522.741V559.372H865.607V491.501V491.48Z"
        fill="currentColor"
      />
      <path
        d="M961.209 469.808V491.501H982.937V501.068H961.209V537.825C961.209 545.357 965.686 549.805 972.565 549.805H982.937V559.372H972.15C958.698 559.372 950.007 551.294 950.007 538.098V501.068H935.289V491.501H950.007V469.808H961.231H961.209Z"
        fill="currentColor"
      />
      <path
        d="M1105.44 465.087V559.371H1093.52V515.879H1041.5V559.371H1029.58V465.087H1041.5V505.494H1093.52V465.087H1105.44Z"
        fill="currentColor"
      />
      <path
        d="M1188.29 537.553C1185.91 551.421 1173.44 560.442 1155.91 560.442C1135.03 560.442 1121.01 544.413 1121.01 525.028C1121.01 505.642 1133.92 490.41 1154.67 490.41C1175.41 490.41 1187.62 504.152 1187.62 522.195C1187.62 523.685 1187.49 525.972 1187.33 526.916H1132.11C1132.96 540.93 1142.61 550.896 1155.93 550.896C1167.42 550.896 1174.71 546.05 1176.96 537.574H1188.32H1188.29V537.553ZM1132.63 518.293H1176.09C1175.24 506.838 1167.27 499.579 1154.64 499.579C1142.72 499.579 1134.73 506.313 1132.63 518.293Z"
        fill="currentColor"
      />
      <path
        d="M1232.3 501.046C1217.99 501.046 1213.93 513.445 1213.93 529.453V559.35H1202.71V491.479H1213.93V505.221C1216.88 495.927 1222.75 491.479 1233.13 491.479H1242.1V501.046H1232.3Z"
        fill="currentColor"
      />
      <path
        d="M1246.73 525.426C1246.73 504.95 1261.73 490.41 1282.61 490.41C1303.48 490.41 1318.64 504.95 1318.64 525.426C1318.64 545.903 1303.64 560.442 1282.61 560.442C1261.58 560.442 1246.73 545.756 1246.73 525.426ZM1307.15 525.426C1307.15 510.614 1297.19 500.103 1282.63 500.103C1268.06 500.103 1258.24 510.614 1258.24 525.426C1258.24 540.238 1268.2 550.749 1282.63 550.749C1297.06 550.749 1307.15 540.238 1307.15 525.426Z"
        fill="currentColor"
      />
    </svg>
  );
};
