import { isDate } from 'lodash/fp';

import { formatDistanceWithOptions, isValid } from 'date-fns/fp';
import enAU from 'date-fns/locale/en-AU';

const formatRelativeTime = (date: Date | null): string | null => {
  if (isDate(date) && isValid(date)) {
    const now = new Date();

    return formatDistanceWithOptions({ locale: enAU }, now, date);
  }

  return null;
};

export { formatRelativeTime };
