import Script from 'next/script';

import { getGoogleTagManagerId } from '@packages/eh-utils/browserEnv';

const GTM_ID = getGoogleTagManagerId();

const GoogleTagManagerContainer = () => {
  if (!GTM_ID) return null;

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtm/js?id=${GTM_ID}`} />
      <Script id="google-tag-manager">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtm(){dataLayer.push(arguments);}
          gtm('js', new Date());
          gtm('config', '${GTM_ID}');
        `}
      </Script>
    </>
  );
};

export default GoogleTagManagerContainer;
