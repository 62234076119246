import { ValueOf } from '@packages/type-utils';

export const ALLOW_JOB_DETAIL_PAGES = ['Direct application', 'Jora'];

export const NOTIFICATION_TYPE = {
  ATS_DIRECT_MESSAGE: 'ats_direct_message',
  ATS_CANDIDATE_SAVED: 'ats_candidate_saved',
} as const;

export type TNotificationType = ValueOf<typeof NOTIFICATION_TYPE>;
