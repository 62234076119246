import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Badge, Box, Button } from '@hero-design/react';

import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import {
  TRACK_NOTIFICATION_USER_CLICK_NOTIFICATION_BELL,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import {
  NOTIFICATION_TYPE,
  TNotificationType,
} from 'src/modules/CareersPage/components/shared/constants';
import useGetUnreadNotificationCounter from 'src/modules/notifications/hooks/useGetUnreadNotificationCounter';

const NotificationBell = () => {
  const { track } = useMixpanelTracking();

  const { permissionsData } = useFetchPermissions();

  const isRouteToMyNotificationsPage =
    permissionsData?.data.notification_v1_enabled;

  const type: TNotificationType = NOTIFICATION_TYPE.ATS_DIRECT_MESSAGE;

  const router = useRouter();

  const {
    refresh: getUnreadNotificationCounter,
    data: getUnreadNotificationCounterData,
  } = useGetUnreadNotificationCounter({ type });

  // call api to get unread notification counter each 3 minutues.
  useEffect(() => {
    const timer = setTimeout(() => {
      getUnreadNotificationCounter();
    }, 180000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const totalNotification = getUnreadNotificationCounterData?.data?.total || 0;
  const hasNotification = totalNotification > 0;

  const goToMyMessagePage = useCallback(() => {
    router.push('/messages');
    track(TRACK_NOTIFICATION_USER_CLICK_NOTIFICATION_BELL);
  }, []);

  const goToMyNotificationPage = useCallback(() => {
    router.push('/notifications');
    track(TRACK_NOTIFICATION_USER_CLICK_NOTIFICATION_BELL);
  }, []);

  if (hasNotification) {
    return (
      <Box sx={{ mr: 'large' }}>
        <Badge.Status intent="error">
          <Button.Icon
            icon="bell-outlined"
            intent="disabled-text"
            size="large"
            onClick={() => {
              if (isRouteToMyNotificationsPage) {
                goToMyNotificationPage();
              } else {
                goToMyMessagePage();
              }
            }}
            data-test-id="notification-bell"
          />
        </Badge.Status>
      </Box>
    );
  }

  return (
    <Button.Icon
      icon="bell-outlined"
      intent="disabled-text"
      size="large"
      onClick={() => {
        if (isRouteToMyNotificationsPage) {
          goToMyNotificationPage();
        } else {
          goToMyMessagePage();
        }
      }}
      data-test-id="notification-bell-no-notification"
      sx={{ mr: 'large' }}
    />
  );
};

export default NotificationBell;
