import compact from 'lodash/fp/compact';

import { TPermissions } from '@shared/hooks/useFetchPermissions';
import { TRACK_ATS_SMART_MATCH_PAGE_CLICK } from '@shared/trackTools/mixpanel';

import { DataWrapper } from 'src/modules/CareersPage/types';
import { TGetUnreadNotificationCounterResponse } from 'src/modules/notifications/hooks/useGetUnreadNotificationCounter';

type GetSwagNavBarItemsProps = {
  permissionsData?: DataWrapper<TPermissions>;
  isAuthenticated: boolean;
  track: (event: string, props?: Record<string, unknown>) => void;
  notificationsCount?: TGetUnreadNotificationCounterResponse;
};

// TODO: refactor into hook
export const getSwagNavBarItems = ({
  permissionsData,
  isAuthenticated,
  track,
  notificationsCount,
}: GetSwagNavBarItemsProps) =>
  compact([
    permissionsData?.data?.job_matching_enabled && {
      id: 'job-matches',
      name: 'Smartmatch',
      href: isAuthenticated
        ? '/user/job_matches'
        : 'https://swagapp.com/jobs/features/smartmatch/',
      onClick: () => {
        if (isAuthenticated) {
          track(TRACK_ATS_SMART_MATCH_PAGE_CLICK, {
            logged_in: isAuthenticated,
          });
        }
      },
      target: isAuthenticated ? '_self' : '_blank',
      showIndicator: !!notificationsCount?.data?.total,
    },
    {
      id: 'job-search',
      name: 'Job Search',
      href: '/search',
    },
    isAuthenticated &&
      permissionsData?.data?.access_saved_jobs && {
        id: 'saved-jobs',
        name: 'Saved Jobs',
        href: '/user/savedJobs',
      },
    isAuthenticated && {
      id: 'applications-tracking',
      name: 'Applications',
      href: '/user/applications',
    },
    isAuthenticated && {
      id: 'career-tracking',
      name: 'Career',
      href: '/maximise_your_potential',
    },
  ]);
