import React from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import styled from 'styled-components';

import { Button, theme } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';

const Wrapper = styled.div`
  display: flex;

  ${mediaMaxQueries.sm} {
    display: none;
  }
  ${mediaMaxQueries.md} {
    display: none;
  }
  ${mediaMaxQueries.lg} {
    display: none;
  }
`;

const LogInButton = styled(Button)`
  color: ${theme.colors.text} !important;
  border-color: ${theme.colors.defaultDarkBackground} !important;
  margin-right: ${theme.space.medium}px !important;

  ${mediaMaxQueries.sm} {
    margin-right: 0 !important;
    margin-bottom: ${theme.space.small}px !important;
    height: 24px !important;
    min-width: 50px !important;
    font-size: ${theme.fontSizes.small}px !important;
    padding: ${theme.space.xsmall}px ${theme.space.small}px !important;
  }
`;

const SignUpButton = styled(LinkButton)`
  color: ${theme.colors.lightText} !important;
  background-color: ${theme.colors.defaultDarkBackground} !important;

  ${mediaMaxQueries.sm} {
    padding: ${theme.space.xsmall}px ${theme.space.small}px !important;
    height: 24px !important;
    min-width: 50px !important;
    font-size: ${theme.fontSizes.small}px !important;
  }
`;

const JOB_POSTING_URL = /^\/jobs\/(.+)$/;
const CALLBACK_URL_WHITELIST = [JOB_POSTING_URL];

export const getCallbackUrl = (url: string) => {
  const isWhitelisted = CALLBACK_URL_WHITELIST.some(regex => regex.test(url));

  if (!isWhitelisted) {
    return undefined;
  }

  return encodeURIComponent(url);
};

const AuthStatusNotSignedIn = () => {
  const router = useRouter();

  const { callbackUrl } = router.query || {};
  const encodedCallbackUrl = callbackUrl
    ? encodeURIComponent(callbackUrl as string)
    : getCallbackUrl(router.asPath);

  return (
    <Wrapper>
      <LogInButton
        id="log-in-btn"
        text="LOG IN"
        variant="outlined"
        onClick={() =>
          signIn('EmploymentHero', {
            callbackUrl: decodeURIComponent(encodedCallbackUrl ?? '/'),
          })
        }
      />
      <SignUpButton
        id="sign-up-btn"
        text="SIGN UP"
        href={`/setup_profile${
          encodedCallbackUrl ? `?callbackUrl=${encodedCallbackUrl}` : ''
        }`}
      />
    </Wrapper>
  );
};

export default AuthStatusNotSignedIn;
