import { useSession } from 'next-auth/react';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getNotificationServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import {
  NOTIFICATION_TYPE,
  TNotificationType,
} from 'src/modules/CareersPage/components/shared/constants';

type TGetUnreadNotificationCounterParams = void;

export type TGetUnreadNotificationCounterResponse = {
  data: {
    total: number;
    events: {
      [key: string]: number;
    };
  };
};

type TGetUnreadNotificationCounterProps = Pick<
  ApiConfigs<
    TGetUnreadNotificationCounterResponse,
    TGetUnreadNotificationCounterParams
  >,
  'onCompleted' | 'onFailed'
> & {
  type?: TNotificationType;
};

const useGetUnreadNotificationCounter = ({
  onCompleted,
  onFailed,
  type = NOTIFICATION_TYPE.ATS_CANDIDATE_SAVED,
}: TGetUnreadNotificationCounterProps) => {
  const { status } = useSession();

  const { fetcher } = useFetch<
    TGetUnreadNotificationCounterResponse,
    TGetUnreadNotificationCounterParams
  >({
    endpoint: `${getNotificationServiceDirectApiHost()}/api/web/v1/notifications/unread?pillar=job&type[]=${type}`,
    onCompleted,
    onFailed,
  });

  const { data, mutate } = useSWR<TGetUnreadNotificationCounterResponse>(
    status === 'authenticated'
      ? { key: 'getUnreadNotificationCounter' }
      : undefined,
    () => fetcher()
  );

  return {
    data,
    refresh: mutate,
  };
};

export default useGetUnreadNotificationCounter;
