export const PHONE_CODE_OPTIONS = [
  {
    value: '+61',
    text: 'AU',
  },
  {
    value: '+64',
    text: 'NZ',
  },
  {
    value: '+60',
    text: 'MY',
  },
  {
    value: '+65',
    text: 'SG',
  },
];
