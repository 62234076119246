import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';

type TPopulateProfileParams = null;

type TPopulateProfileResponse = unknown;

type TPopulateProfileProps = Pick<
  ApiConfigs<TPopulateProfileResponse, TPopulateProfileParams>,
  'onCompleted' | 'onFailed'
>;

const usePopulateProfile = ({
  onCompleted,
  onFailed,
}: TPopulateProfileProps) => {
  const { fetcher, loading } = useFetch<
    TPopulateProfileResponse,
    TPopulateProfileParams
  >({
    method: 'POST',
    endpoint: `${getDirectMainAppHost()}/api/v1/candidate_profiles/populate`,
    onCompleted,
    onFailed,
  });

  return {
    fetcher,
    loading,
  };
};

export default usePopulateProfile;
