import { flow, isNaN } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';

import { IStringifyOptions as StringifyOptionsType, stringify } from 'qs';

export type GenerateGetURLType = (
  href: string,
  params?: Record<string, unknown>,
  stringifyOption?: StringifyOptionsType
) => string;

const generateGetURL: GenerateGetURLType = (
  href,
  params,
  stringifyOption = { arrayFormat: 'brackets' }
) => {
  const normalizedParamsFunction = flow(omitBy(isNil), omitBy(isNaN));
  const normalizedParams = normalizedParamsFunction(params);

  return isEmpty(normalizedParams)
    ? href
    : `${href}${href.includes('?') ? '&' : '?'}${stringify(
        normalizedParams,
        stringifyOption
      )}`;
};

export default generateGetURL;
