import { isEmpty } from 'lodash/fp';

import { DEFAULT_LOCALE } from '@shared/utils/autoDetectLocale/constants';
import { REGION_OPTIONS } from '@shared/utils/constants';

import { DownloadAppType, MenuItem } from './types';

import { COUNTRIES_LOCALES_MAP, DOWNLOAD_APP_TYPES } from './constants';

export const generateDownloadLink = (
  locale: string,
  type: DownloadAppType
): string => {
  const baseUrl = 'https://employmenthero.com';

  if (type === DOWNLOAD_APP_TYPES.EH_JOBS) {
    return `${baseUrl}/jobs/app/`;
  }

  if (type === DOWNLOAD_APP_TYPES.EH_WORK_EMPLOYEE) {
    return `${baseUrl}/work/`;
  }

  return `${baseUrl}/products/work-app/`;
};

export const checkLinkSupportLocale = (
  locales: MenuItem['locales'],
  locale?: string
) => {
  if (isEmpty(locales) || !locale) {
    return true;
  }

  if (locale === DEFAULT_LOCALE && locales?.includes('au')) {
    return true;
  }

  const localeCode =
    COUNTRIES_LOCALES_MAP[locale as keyof typeof COUNTRIES_LOCALES_MAP];

  return locales?.includes(localeCode);
};

export const normalizeLinkWithLocale = (
  { url, locales }: Pick<MenuItem, 'url' | 'locales'>,
  routerLocale?: string
) => {
  if (!url || !checkLinkSupportLocale(locales, routerLocale)) {
    return null;
  }

  const isLocaleSupported = REGION_OPTIONS.some(
    region =>
      String(region.value).toLowerCase() === String(routerLocale).toLowerCase()
  );

  // The raw url could be:
  // employmenthero.com/:locale/products/work-app/
  // employmenthero.com/:locale/

  // If the locale is 'au' or undefined, we just need to remove the locale placeholder :locale
  if (
    !routerLocale ||
    routerLocale === DEFAULT_LOCALE ||
    !isLocaleSupported ||
    routerLocale === 'AU'
  ) {
    return url.replace('/:locale', '');
  }

  // If the locale is not 'au', we need to replace the placeholder :locale with the actual locale
  const localeCode =
    COUNTRIES_LOCALES_MAP[routerLocale as keyof typeof COUNTRIES_LOCALES_MAP];

  return url.replace(':locale', localeCode);
};
