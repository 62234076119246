import { ReactNode, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Box, Divider, SxObject } from '@hero-design/react';

import { getInspectletId } from '@packages/eh-utils/browserEnv';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import AppBanner from '@shared/AppBanner';
import NavBar from '@shared/NavBar';
import NavMenu from '@shared/NavMenu';
import RegionBanner from '@shared/RegionBanner';
import SiteFooter from '@shared/SiteFooter';
import SiteFooterV2 from '@shared/SiteFooterV2';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useWindowSize from '@shared/hooks/useWindowSize';
import { getMobileDetector } from '@shared/utils/getMobileDetector';
import { getSwagNavBarItems } from '@shared/utils/getSwagNavBarItems';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';

import AppDownloadBanner from 'src/modules/CareersPage/components/LandingPage/AppDownloadBanner';
import useGetUnreadNotificationCounter from 'src/modules/notifications/hooks/useGetUnreadNotificationCounter';

import useInspectletTracking from '../trackTools/inspectlet/useInspectletTracking';

const HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER: string[] = [
  '/setup_profile',
  '/smartmatch_introduction',
  '/user/setup_profile',
  '/user/setup_job_preferences',
  '/user/setup_success',
];

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ChildrenWrapper = styled.div`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.lg} {
    width: 100%;
  }
`;

type PageLayoutProps = {
  children: ReactNode;
  pageProps?: {
    hideCountrySelectorBanner?: boolean;
    hideNavBar?: boolean;
    hideNavBarForSmallScreen?: boolean;
    hideFooter?: boolean;
  };
};

const PageLayout = ({ children, pageProps = {} }: PageLayoutProps) => {
  useInspectletTracking(getInspectletId());
  const { track } = useMixpanelTracking();

  const router = useRouter();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  const { isSmallScreen } = useWindowSize();
  const { permissionsData } = useFetchPermissions();
  const { data: unreadNotificationData } = useGetUnreadNotificationCounter({});
  const isMobile = getMobileDetector()?.isMobile ?? false;

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isRegionBannerOpened, setIsRegionBannerOpened] = useState(false);

  const navBarItems = getSwagNavBarItems({
    track,
    permissionsData,
    isAuthenticated,
    notificationsCount: unreadNotificationData,
  });

  const navMenuRefactoringEnabled =
    permissionsData?.data.nav_menu_refactoring_enabled ?? false;
  const showNavMenu = navMenuRefactoringEnabled && !isAuthenticated;

  const hideNavBar = useMemo(
    () =>
      pageProps.hideNavBar ||
      (isSmallScreen && pageProps.hideNavBarForSmallScreen),
    [isSmallScreen, pageProps.hideNavBar, pageProps.hideNavBarForSmallScreen]
  );

  const showAppBanner = useMemo(
    () =>
      isMobile && !HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER.includes(router.pathname),
    [isMobile, router.pathname]
  );

  const showRegionBanner = useMemo(
    () => !pageProps.hideCountrySelectorBanner && showNavMenu,
    [pageProps.hideCountrySelectorBanner, showNavMenu]
  );

  const getPageContentMargin = () => {
    if (!showRegionBanner) return 0;
    return isRegionBannerOpened ? '121px' : '57px';
  };

  return (
    <Box style={{ flex: 1 }}>
      <Box
        sx={
          showNavMenu
            ? {
                top: 0,
                left: 0,
                zIndex: 100,
                width: '100%',
                position: 'fixed',
                overflow: 'hidden',
                bgColor: 'default-light-background',
              }
            : undefined
        }
      >
        {showRegionBanner && (
          <>
            <RegionBanner
              isVisible={isRegionBannerOpened}
              setIsVisible={setIsRegionBannerOpened}
            />
            <Divider />
          </>
        )}
        <Box>{showAppBanner && <AppDownloadBanner isMobile />}</Box>

        {!hideNavBar && showNavMenu && (
          <NavMenu
            setIsMenuOpened={setIsMenuOpened}
            isRegionBannerOpened={isRegionBannerOpened}
          />
        )}
        {!hideNavBar && !showNavMenu && <NavBar navBarItems={navBarItems} />}
      </Box>

      <PageContainer
        style={
          isMenuOpened && showNavMenu
            ? { width: '100%', position: 'fixed' }
            : undefined
        }
      >
        <AppBanner />
        <StyledDiv style={{ marginTop: getPageContentMargin() }}>
          {children}
        </StyledDiv>

        {!pageProps.hideFooter && (
          <Box data-test-id="footer" sx={{ zIndex: 1 }}>
            {navMenuRefactoringEnabled ? <SiteFooterV2 /> : <SiteFooter />}
          </Box>
        )}
      </PageContainer>
    </Box>
  );
};

export const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <ChildrenWrapper>
    <Box p="large">{children}</Box>
  </ChildrenWrapper>
);

export const JobListWrapper = ({
  children,
  containerSx,
}: {
  children: ReactNode;
  containerSx?: SxObject;
}) => (
  <Box
    bgColor="grey-light-85"
    sx={{
      minHeight: '80vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      ...containerSx,
    }}
  >
    <ChildrenWrapper>
      <Box p="large">{children}</Box>
    </ChildrenWrapper>
  </Box>
);

export default PageLayout;
