import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Button, Divider, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { TABLET_SIZE } from '@shared/NavMenu/constants';
import { checkLinkSupportLocale } from '@shared/NavMenu/helpers';
import { MenuItem } from '@shared/NavMenu/types';
import useWindowSize from '@shared/hooks/useWindowSize';

import DownloadAppCTA from '../DownloadAppCTA';
import LoginButton from '../MenuLogin/LoginButton';

import { useMegaMenu } from '../context';
import { Link } from '../styledComponents';

const FooterItem = ({ item }: { item: MenuItem }) => {
  const theme = useTheme();
  const router = useRouter();

  const isItemSupported = checkLinkSupportLocale(item.locales, router.locale);

  if (!isItemSupported) {
    return null;
  }

  return (
    <Button
      variant="inline-text"
      text={
        <Box
          sx={{
            display: 'flex',
            gap: 'small',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <Image
              width={14}
              height={14}
              src={`/assets/menu-icons/${item.icon}.svg`}
            />
          </Box>
          <Typography.Text
            fontSize={12}
            intent="subdued"
            fontWeight="semi-bold"
            sx={{ mt: `-${theme.space.xxsmall}px` }}
          >
            <Link item={item} intent="subdued">
              {item.title}
            </Link>
          </Typography.Text>
        </Box>
      }
    />
  );
};

const MenuFooter = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { selectedLevel1 } = useMegaMenu();

  const isTablet = windowSize?.width && windowSize.width <= TABLET_SIZE;
  const footerItems = selectedLevel1?.footer || [];

  if (isTablet) {
    return (
      <>
        <Box
          sx={{
            p: 'medium',
            gap: 'xlarge',
            display: 'flex',
            flexWrap: 'wrap',
            ml: isTablet ? 'small' : undefined,
          }}
        >
          {footerItems.map(item => (
            <FooterItem key={item.title} item={item} />
          ))}
        </Box>

        <DownloadAppCTA.Button type={selectedLevel1?.download_app_type} />

        <Divider />
        <LoginButton />
      </>
    );
  }

  return (
    <Box
      pl="xlarge"
      sx={{
        height: 44,
        gap: 'large',
        display: 'flex',
        alignItems: 'center',
        // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
        backgroundColor: '#fcfbff',
        borderBottomLeftRadius: theme.radii.large,
        borderBottomRightRadius: theme.radii.large,
      }}
    >
      {footerItems.map(item => (
        <FooterItem key={item.title} item={item} />
      ))}
    </Box>
  );
};

export default MenuFooter;
