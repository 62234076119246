import React from 'react';
import Link from 'next/link';

import { Button } from '@hero-design/react';

type ButtonProps = React.ComponentProps<typeof Button>;

type LinkButtonProps = ButtonProps & {
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
};

const LinkButton = (props: LinkButtonProps) => (
  <Link href={props.href} passHref legacyBehavior>
    {props?.target ? (
      <a target={props.target} rel="noreferrer">
        <Button {...props} />
      </a>
    ) : (
      <Button {...props} />
    )}
  </Link>
);

export default LinkButton;
