import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { ExtendedErrorData } from '@packages/eh-utils/api/types';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';

import { TUserProfile } from 'src/modules/User/types';

type GetPublicProfileResponse = {
  data: TUserProfile;
};

type GetPublicProfileParams = {
  friendly_id: string;
  preview_mode?: boolean;
};

type TFetchPublicProfileProps = {
  friendlyId?: string;
  previewMode?: boolean;
  customToken?: string;
  configs?: SWRConfiguration;
};

const useFetchPublicProfile = ({
  friendlyId,
  previewMode,
  customToken,
  configs,
}: TFetchPublicProfileProps) => {
  const { fetcher } = useFetch<
    GetPublicProfileResponse,
    GetPublicProfileParams,
    ExtendedErrorData
  >({
    endpoint: `${getDirectMainAppHost()}/api/v3/users/public_profile`,
    method: 'GET',
    headers: {
      'Jwt-Token': customToken,
    },
    withSWR: true,
  });
  const {
    data,
    mutate: fetchPublicProfile,
    error,
  } = useSWR(
    friendlyId
      ? {
          key: 'publicProfile',
          params: previewMode
            ? { friendly_id: friendlyId, preview_mode: previewMode }
            : { friendly_id: friendlyId },
        }
      : null,
    ({ params }) => fetcher(params),
    {
      revalidateOnFocus: false,
      ...configs,
    }
  );

  return {
    publicProfileData: data,
    fetchPublicProfile,
    publicProfileError: error,
    isFetchingPublicProfile: !data && !error,
  };
};

export default useFetchPublicProfile;
