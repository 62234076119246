/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useEffect } from 'react';
import Script from 'next/script';

import { getMarketoBaseUrl } from '@packages/eh-utils/browserEnv';

const MarketoContainer = () => {
  const marketoBaseUrl = getMarketoBaseUrl();

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const linkTags = document.querySelectorAll('link[href*="forms2.css"]');

      linkTags.forEach(link => link.parentNode?.removeChild(link));
    });

    observer.observe(document.head, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  if (!marketoBaseUrl) {
    return null;
  }

  return (
    <Script
      id="marketo-script"
      strategy="beforeInteractive"
      src={`${marketoBaseUrl}/js/forms2/js/forms2.min.js`}
    />
  );
};

export default MarketoContainer;
