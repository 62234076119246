import styled, { css } from 'styled-components';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import { styledVariant, themeGet } from '../../utils';

interface InputContainerProps {
  inline?: boolean;
}

export default styled.div<InputContainerProps>`
  margin-bottom: ${themeGet('space.medium')}px;

  ${Error} {
    text-align: ${props => (props.inline === true ? 'right' : 'left')};
  }

  ${FieldLabel} {
    ${styledVariant(
      props => props.inline,
      inline =>
        inline
          ? css`
              display: flex;
              justify-content: space-between;
            `
          : css`
              display: block;
            `
    )};
  }
`;
