import { useEffect } from 'react';
import { SessionProvider, signOut, useSession } from 'next-auth/react';

import { FETCH_JWT_ERROR } from 'src/constants';

const SessionErrorChecker = () => {
  const { data: sessionData } = useSession();

  useEffect(() => {
    if (sessionData?.user && sessionData?.error === FETCH_JWT_ERROR) {
      signOut();
    }
  }, [sessionData]);

  return null;
};

type CustomSessionProviderProps = React.ComponentProps<typeof SessionProvider>;

const CustomSessionProvider = ({
  children,
  ...props
}: CustomSessionProviderProps) => (
  <SessionProvider {...props}>
    <SessionErrorChecker />
    {children}
  </SessionProvider>
);

export default CustomSessionProvider;
