import { useEffect } from 'react';

import { Box, Grid, Typography } from '@hero-design/react';

import {
  getEHThankyouPageUrl,
  getMarketoBaseUrl,
  getMarketoEmailSubscriptionFormId,
  getMarketoMunchkinId,
} from '@packages/eh-utils/browserEnv';

declare const MktoForms2: MktoForms2;

const MarketoEmailSubscription = () => {
  const baseUrl = getMarketoBaseUrl() ?? '';
  const ehThankYouPageUrl = getEHThankyouPageUrl();
  const munchkinId = getMarketoMunchkinId() ?? '';
  const formId = Number(getMarketoEmailSubscriptionFormId());
  const htmlFormId = `mktoForm_${formId}`;

  useEffect(() => {
    if (!MktoForms2) return;

    MktoForms2.loadForm(baseUrl, munchkinId, formId, form => {
      form.onSuccess(() => {
        window.open(ehThankYouPageUrl, '_blank');
        window.location.reload();
        return false;
      });
    });

    MktoForms2.onFormRender(() => {
      const submitButton = document.querySelector(
        `#${htmlFormId} button[type="submit"]`
      );

      if (submitButton) {
        // eslint-disable-next-line immutable/no-mutation
        submitButton.textContent = 'Talk to us';
      }
    });

    return () => {
      MktoForms2.onFormRender(() => {});
      const formElement = document.getElementById(htmlFormId);

      if (formElement) formElement.remove();
    };
  }, []);

  return (
    <Grid.Col span={[24, 12, 12, 12, 12]}>
      <Typography.Text fontSize={16} fontWeight="semi-bold">
        News, resources and insights - delivered to your inbox. Subscribe.
      </Typography.Text>
      <Box sx={{ mt: 'large' }}>
        <form id={htmlFormId} className={'email_subscription_marketo_form'} />
      </Box>
    </Grid.Col>
  );
};

export default MarketoEmailSubscription;
