import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { Box } from '@hero-design/react';

import { Button as NavMenuButton } from '@shared/NavMenu/types';
import useWindowSize from '@shared/hooks/useWindowSize';

import { checkLinkSupportLocale, normalizeLinkWithLocale } from '../helpers';

import { useMegaMenu } from './context';
import { CustomButton } from './styledComponents';

const MenuButton = () => {
  const router = useRouter();
  const { isMediumScreen } = useWindowSize();
  const { selectedLevel1 } = useMegaMenu();

  const buttons = selectedLevel1?.meta_data?.buttons;

  const handleButtonClick = useCallback(
    (button: NavMenuButton) => {
      if (button.url) {
        const href = normalizeLinkWithLocale(
          { url: button.url, locales: button.locales },
          router.locale
        );

        if (href) {
          router.push(href);
        }
      }
    },
    [router]
  );

  return (
    <Box
      sx={{
        p: 'medium',
        gap: 'large',
        display: 'flex',
        justifyContent: isMediumScreen ? 'space-around' : 'flex-end',
      }}
    >
      {buttons?.map(button => {
        if (!checkLinkSupportLocale(button.locales, router.locale)) {
          return null;
        }

        return (
          <CustomButton
            key={button.title}
            text={button.title}
            variant={button.variant}
            onClick={() => handleButtonClick(button)}
            sx={{ width: isMediumScreen ? '100%' : undefined }}
          />
        );
      })}
    </Box>
  );
};

export default MenuButton;
