import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useWindowSize from '@shared/hooks/useWindowSize';

import { MenuItem } from '../types';

interface MegaMenuContextProps {
  selectedLevel1: MenuItem | undefined;
  selectedLevel2: MenuItem | undefined;
  selectedLevel3: MenuItem | undefined;
  selectedLevel4: MenuItem | undefined;
  isOpenMenuLogin: boolean;
  isOpenRequestDemo: boolean;
  setSelectedLevel1: Dispatch<SetStateAction<MenuItem | undefined>>;
  setSelectedLevel2: Dispatch<SetStateAction<MenuItem | undefined>>;
  setSelectedLevel3: Dispatch<SetStateAction<MenuItem | undefined>>;
  setSelectedLevel4: Dispatch<SetStateAction<MenuItem | undefined>>;
  closeMegaMenu: () => void;
  openMenuLogin: () => void;
  closeMenuLogin: () => void;
  openRequestDemo: () => void;
  closeRequestDemo: () => void;
}

const MegaMenuContext = createContext<MegaMenuContextProps | undefined>(
  undefined
);

export const MegaMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isMediumScreen } = useWindowSize();
  const [selectedLevel1, setSelectedLevel1] = useState<MenuItem | undefined>();
  const [selectedLevel2, setSelectedLevel2] = useState<MenuItem | undefined>();
  const [selectedLevel3, setSelectedLevel3] = useState<MenuItem | undefined>();
  const [selectedLevel4, setSelectedLevel4] = useState<MenuItem | undefined>();
  const [isOpenMenuLogin, setIsOpenMenuLogin] = useState<boolean>(false);
  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState<boolean>(false);

  const closeMegaMenu = useCallback(() => {
    setSelectedLevel1(undefined);
    setSelectedLevel2(undefined);
    setSelectedLevel3(undefined);
    setSelectedLevel4(undefined);
    setIsOpenMenuLogin(false);
  }, []);

  const openMenuLogin = useCallback(() => {
    setIsOpenMenuLogin(true);
  }, []);

  const closeMenuLogin = useCallback(() => {
    setIsOpenMenuLogin(false);
  }, []);

  const openRequestDemo = useCallback(() => {
    setIsOpenRequestDemo(true);
  }, []);

  const closeRequestDemo = useCallback(() => {
    setIsOpenRequestDemo(false);
  }, []);

  const value = useMemo(
    () => ({
      selectedLevel1,
      selectedLevel2,
      selectedLevel3,
      selectedLevel4,
      isOpenMenuLogin,
      isOpenRequestDemo,
      setIsOpenMenuLogin,
      setSelectedLevel1,
      setSelectedLevel2,
      setSelectedLevel3,
      setSelectedLevel4,
      closeMegaMenu,
      openMenuLogin,
      closeMenuLogin,
      openRequestDemo,
      closeRequestDemo,
    }),
    [
      closeMegaMenu,
      openMenuLogin,
      closeMenuLogin,
      openRequestDemo,
      closeRequestDemo,
      selectedLevel1,
      selectedLevel2,
      selectedLevel3,
      selectedLevel4,
      isOpenMenuLogin,
      isOpenRequestDemo,
    ]
  );

  // Reset selected levels 2,3 and 4 when a new level 1 is selected
  useEffect(() => {
    if (selectedLevel1 && !isMediumScreen) {
      const newSelectedLevel2 = selectedLevel1?.items?.[0];

      setSelectedLevel2(newSelectedLevel2);
    }
  }, [selectedLevel1]);

  // Reset selected level 3 when a new level 2 is selected
  useEffect(() => {
    const newSelectedLevel3 = selectedLevel2?.items?.[0];

    setSelectedLevel3(newSelectedLevel3);
  }, [selectedLevel2]);

  // Reset selected level 4 when a new level 3 is selected
  useEffect(() => {
    const newSelectedLevel4 = selectedLevel3?.items?.[0];

    setSelectedLevel4(newSelectedLevel4);
  }, [selectedLevel3]);

  return (
    <MegaMenuContext.Provider value={value}>
      {children}
    </MegaMenuContext.Provider>
  );
};

export const useMegaMenu = (): MegaMenuContextProps => {
  const context = useContext(MegaMenuContext);

  if (!context) {
    throw new Error('useMegaMenu must be used within a MegaMenuProvider');
  }

  return context;
};
