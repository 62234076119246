import React, { ReactElement, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { Icon, Tooltip, Typography } from '@hero-design/react';

import { Tooltip as ITooltip } from '../types';

const LeftAlign = styled.div`
  text-align: left;
`;

const withOptional = (text: string) => `${text} (Optional)`;

const getLabelIntent = ({
  hasError,
  disabled,
}: {
  hasError?: boolean;
  disabled?: boolean;
}) => {
  if (hasError === true) {
    return 'danger';
  }

  return disabled === true ? 'subdued' : 'main';
};

const getWrapperCursor = ({
  clickable,
  disabled,
}: {
  clickable?: boolean;
  disabled?: boolean;
}) => {
  if (disabled === true) {
    return 'not-allowed';
  }

  return clickable === true ? 'pointer' : 'default';
};

type FieldLabelProps = {
  text?: string | ReactElement;
  subText?: string | ReactNode;
  helpText?: string;
  required?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  tooltip?: ITooltip;
  clickable?: boolean;
  htmlFor?: string;
  input?: ReactElement;
  className?: string;
};

const FieldLabel = ({
  text,
  subText,
  helpText,
  required,
  hasError,
  disabled,
  tooltip,
  clickable,
  htmlFor,
  input,
  className,
}: FieldLabelProps) => {
  const theme = useTheme();
  const intent = getLabelIntent({ hasError, disabled });

  const { title, html, placement = 'right' } = tooltip || {};

  return (
    <LeftAlign className={className}>
      {text !== undefined && (
        <div
          style={{
            marginBottom: theme.space.xsmall,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography.Text
            fontSize={14}
            fontWeight="semi-bold"
            intent={intent}
            tagName="label"
            htmlFor={htmlFor}
            style={{
              cursor: getWrapperCursor({ clickable, disabled }),
              marginBottom: 0,
              color: 'inherit',
              width: '100%',
            }}
          >
            {typeof text === 'string' && (
              <span>{required === true ? text : withOptional(text)}</span>
            )}

            {React.isValidElement(text) && text}
          </Typography.Text>

          {tooltip != null && (
            <Tooltip
              data-test-id={tooltip['data-test-id']}
              content={html || title || ''}
              target={
                <Icon
                  icon="circle-info-outlined"
                  intent={disabled ? 'subdued-text' : 'text'}
                  style={{ cursor: 'pointer' }}
                />
              }
              interactive={tooltip.interactive}
              placement={placement}
              style={{ marginLeft: theme.space.xsmall, display: 'flex' }}
            />
          )}
        </div>
      )}

      {subText != null && (
        <div
          style={{
            marginBottom: theme.space.xsmall,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography.Text tagName="span" fontWeight="regular" intent="subdued">
            {subText}
          </Typography.Text>
        </div>
      )}

      {input != null && <div>{input}</div>}

      {helpText != null && (
        <div
          style={{
            marginTop: theme.space.xsmall,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography.Text tagName="span" fontWeight="regular" intent="subdued">
            {helpText}
          </Typography.Text>
        </div>
      )}
    </LeftAlign>
  );
};

export default styled(FieldLabel)<FieldLabelProps>``;
