import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Box } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import { Logo } from '@shared/Logo';
import NavBarActions from '@shared/NavBarActions';
import useWindowSize from '@shared/hooks/useWindowSize';

import MegaMenu from './MegaMenu';
import { MegaMenuProvider, useMegaMenu } from './MegaMenu/context';
import MenuItemLevel1 from './MegaMenu/MenuItemLevel1';
import SideBar from './SideBar';
import { MenuData } from './types';

import rawMenuData from './data/menu-data.json';

interface NavWrapperProps {
  centerItem?: boolean;
  isOpenSmallScreen?: boolean;
}

const NavWrapper = styled.div<NavWrapperProps>`
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  padding: ${({ theme }) => `${theme.space.small}px 140px 0px 140px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme, isOpenSmallScreen }) =>
    isOpenSmallScreen
      ? undefined
      : `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`};

  ${mediaMaxQueries.lg} {
    height: 64px;
    padding: ${({ theme }) => `0px ${theme.space.xlarge}px`};
  }

  ${mediaMaxQueries.md} {
    padding: ${({ theme }) => `0px ${theme.space.medium}px`};
  }
`;

type NavMenuProps = {
  isRegionBannerOpened: boolean;
  setIsMenuOpened: (isMenuOpened: boolean) => void;
};

const NavMenuContent = ({
  setIsMenuOpened,
  isRegionBannerOpened,
}: NavMenuProps) => {
  const { query } = useRouter();
  const [isOpenSideBar, setOpenSideBar] = useState(false);
  const { setSelectedLevel1, selectedLevel1 } = useMegaMenu();
  const { isMediumScreen } = useWindowSize();

  const level1Items = rawMenuData as MenuData;

  const normalizeHref = useCallback(
    (href: string) => generateGetURL(href, { mode: query?.mode }),
    [query?.mode]
  );

  const openMegaMenu = useCallback(() => {
    setSelectedLevel1(level1Items[0]);
  }, [level1Items, setSelectedLevel1]);

  useEffect(() => {
    setIsMenuOpened(Boolean(selectedLevel1));
  }, [selectedLevel1]);

  return (
    <>
      <NavWrapper isOpenSmallScreen={selectedLevel1 && isMediumScreen}>
        <Box
          sx={{
            width: 960,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link href={normalizeHref('/')} passHref legacyBehavior>
            <Box sx={{ cursor: 'pointer', display: 'flex' }}>
              <Logo id="eh-logo" />
            </Box>
          </Link>

          <Box>
            {!isMediumScreen && (
              <Box
                sx={{
                  display: 'flex',
                  ml: 'xxxlarge',
                  height: 48,
                  justifyContent: 'center',
                }}
              >
                {level1Items.map(item => (
                  <MenuItemLevel1 key={item.title} item={item} />
                ))}
              </Box>
            )}
          </Box>

          <NavBarActions
            isOpenSideBar={isOpenSideBar}
            setOpenSideBar={setOpenSideBar}
            openMegaMenu={openMegaMenu}
          />
        </Box>
      </NavWrapper>

      <MegaMenu
        level1Items={level1Items}
        isRegionBannerOpened={isRegionBannerOpened}
      />

      {isOpenSideBar && (
        <SideBar
          navBarItems={level1Items}
          isOpenSideBar={isOpenSideBar}
          setOpenSideBar={setOpenSideBar}
        />
      )}
    </>
  );
};

const NavMenu = (props: NavMenuProps) => (
  <MegaMenuProvider>
    <NavMenuContent {...props} />
  </MegaMenuProvider>
);

export default NavMenu;
