import React from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import useFetchPublicProfile from '@shared/hooks/useFetchPublicProfile';

import AuthStatusNotSignedIn from './AuthStatusNotSignedIn';
import AuthStatusSignedIn from './AuthStatusSignedIn';

const AuthStatus = () => {
  const { data: session, status } = useSession();
  const user = session?.user;
  const router = useRouter();

  const { pathname } = router;

  const { previewMode, token, friendlyId } = router.query || {};

  const { publicProfileData } = useFetchPublicProfile({
    friendlyId: friendlyId as string,
    previewMode: !!previewMode,
    customToken: previewMode ? (token as string) : undefined,
  });

  const allowJobSeekersStream1Experiment1Enabled =
    !!publicProfileData?.data.job_seekers_stream_1_experiment_1_enabled;

  const isOnStream1Experiment1 =
    pathname === '/user/[friendlyId]' &&
    status !== 'authenticated' &&
    allowJobSeekersStream1Experiment1Enabled;

  if (status === 'loading' || isOnStream1Experiment1) {
    return null;
  }

  return (
    <>
      {!user ? (
        <AuthStatusNotSignedIn />
      ) : (
        <AuthStatusSignedIn session={session} />
      )}
    </>
  );
};

export default AuthStatus;
