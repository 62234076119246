import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Dropdown, Icon, Menu, Typography } from '@hero-design/react';

import { LOCALE_OPTIONS_V2 } from '@shared/utils/constants';

const renderContent = (
  locale: string | undefined,
  changeSite: (newLocale: string | undefined) => void
) => (
  <Menu>
    {LOCALE_OPTIONS_V2.map(({ value, text }) => (
      <Menu.Item
        key={value}
        text={text}
        onClick={() => changeSite(value)}
        active={locale === value}
      />
    ))}
  </Menu>
);

const getLocaleText = (locale: string | undefined) =>
  LOCALE_OPTIONS_V2.find(({ value }) => value === locale)?.text ?? 'Region';

const CountrySelector = () => {
  const { asPath, locale, query, pathname, push } = useRouter();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const changeSite = (newLocale: string | undefined) => {
    if (newLocale) {
      push({ pathname, query }, asPath, { locale: newLocale, shallow: false });
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const localeText = getLocaleText(locale);

  return (
    <Dropdown
      open={open}
      content={renderContent(locale, newLocale => changeSite(newLocale))}
      onClose={handleClose}
      data-test-id="locale-dropdown"
      target={
        <Box
          onClick={handleToggle}
          data-test-id="locale-select"
          sx={{
            minWidth: '140px',
            borderBottom: `2px solid ${theme.colors.defaultBorder}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'space-between',
              mb: 'small',
            }}
          >
            <Typography.Text>{localeText}</Typography.Text>
            <Icon
              icon={open ? 'single-up-arrow' : 'single-down-arrow'}
              sx={{ ml: 'medium', mr: 'medium' }}
              size="small"
              data-test-id="region-select-icon"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default CountrySelector;
