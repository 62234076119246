import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Typography } from '@hero-design/react';

type ErrorProps = {
  text: string;
  style?: CSSProperties;
  className?: string;
};

const Error = ({ text, style, className }: ErrorProps) => (
  <Typography.Text
    style={style}
    fontSize={12}
    intent="danger"
    className={className}
  >
    {text}
  </Typography.Text>
);

export default styled(Error)<ErrorProps>``;
