import React from 'react';
import { useTheme } from 'styled-components';

import { Typography } from '@hero-design/react';
import { Theme } from '@hero-design/react/types/theme';

interface SearchNoResultsProps {
  noResultText?: string;
}

const SearchNoResults = ({ noResultText }: SearchNoResultsProps) => {
  const theme = useTheme() as Theme;

  return (
    <Typography.Text
      intent="subdued"
      style={{ padding: theme.space.small, textAlign: 'center' }}
    >
      {noResultText || 'No results'}
    </Typography.Text>
  );
};

export default SearchNoResults;
