import { useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import { getMobileDetector } from '@shared/utils/getMobileDetector';

import AppDownloadBanner from 'src/modules/CareersPage/components/LandingPage/AppDownloadBanner';
import OffboardEmployeePopUp from 'src/modules/CareersPage/components/PopulateProfile/OffboardEmployeePopUp';
import PopulateProfileInfoBanner from 'src/modules/CareersPage/components/PopulateProfile/PopulateProfileInfoBanner';

import EmailVerificationBanner from './EmailVerificationBanner';

const Stack = styled.div`
  position: relative;
`;

const BannerWrapper = styled('div')<{ zIndex: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.zIndex};
`;

const PopulateProfileProfileBannerWrapper = () => {
  const router = useRouter();

  const company = router.query?.company?.toString() || '';
  const digitalCv = router.query?.digital_cv === 'true';

  const [isOpenModal, setIsOpenModal] = useState(true);
  const [isOpenBanner, setIsOpenBanner] = useState(false);

  return (
    <>
      {isOpenBanner ? (
        <PopulateProfileInfoBanner
          setIsOpenBanner={setIsOpenBanner}
          setIsOpenModal={setIsOpenModal}
        />
      ) : null}

      {digitalCv ? (
        <OffboardEmployeePopUp
          company={company}
          setIsOpenBanner={setIsOpenBanner}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      ) : null}
    </>
  );
};

const SHOW_PATHS_FOR_APP_DOWNLOAD_BANNER: string[] = ['/'];
const HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER: string[] = [
  '/setup_profile',
  '/smartmatch_introduction',
  '/job_seekers_completion_profile',
  '/user/setup_job_preferences',
  '/user/setup_profile',
  '/user/setup_success',
];

const SHOW_PATHS_FOR_POPULATE_PROFILE_INFO_BANNER: string[] = [
  '/user/[friendlyId]',
];

const HIDE_PATHS_FOR_REMINDER_BANNER: string[] = [
  '/sign_up',
  '/job_seekers_completion_profile',
  '/user/setup_job_preferences',
  '/user/setup_profile',
  '/user/setup_success',
  '/locked_verification',
  '/jobs/[id]/apply',
];

const isMatchPath = (pathname: string, showPaths: string[]) =>
  showPaths.find(item => item === pathname);

const AppBanner = () => {
  const router = useRouter();
  const session = useSession();
  const friendlyId = session.data?.user.friendlyId;

  const previewMode = router.query?.previewMode;
  const friendlyIdRouteParam = router.query?.friendlyId;

  const isSelfProfile =
    !!friendlyId && friendlyId === friendlyIdRouteParam && !previewMode;

  const { permissionsData, isFetchingPermissions } = useFetchPermissions();

  const isShowReminderBanner =
    !!permissionsData?.data?.show_unverified_user_restriction_banner;

  if (isFetchingPermissions) {
    return null;
  }

  return (
    <Stack>
      {isShowReminderBanner &&
      !isMatchPath(router.pathname, HIDE_PATHS_FOR_REMINDER_BANNER) ? (
        <EmailVerificationBanner />
      ) : (
        <>
          <BannerWrapper zIndex={1}>
            {isMatchPath(router.pathname, SHOW_PATHS_FOR_APP_DOWNLOAD_BANNER) &&
              !isMatchPath(
                router.pathname,
                HIDE_PATHS_FOR_APP_DOWNLOAD_BANNER
              ) &&
              !getMobileDetector()?.isMobile && <AppDownloadBanner />}
          </BannerWrapper>

          <BannerWrapper zIndex={2}>
            {isMatchPath(
              router.pathname,
              SHOW_PATHS_FOR_POPULATE_PROFILE_INFO_BANNER
            ) &&
              (router.pathname !== '/user/[friendlyId]' || isSelfProfile) && (
                <PopulateProfileProfileBannerWrapper />
              )}
          </BannerWrapper>
        </>
      )}
    </Stack>
  );
};

const AppBannerWithNoSSR = dynamic(Promise.resolve(AppBanner), {
  ssr: false,
});

export default AppBannerWithNoSSR;
