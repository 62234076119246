import { useFetch } from '@packages/eh-utils/api';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';
import { decamelizeKeys } from '@packages/eh-utils/formatObject';

export type TUpdateUserProfileParams = Partial<{
  firstName: string;
  lastName: string;
  headline: string;
  email: string;
  phoneNumber: string;
  address: string;
  vendorLocationId: string | number | null;
  vendorLocationName: string;
  city: string;
  countryCode: string;
  avatarUrl: string;
  avatarFileId: string;
  geolocation?: Partial<{
    latitude: string;
    longitude: string;
  }>;
  summary?: string;
  postcode?: string | null;
  expectedWorkTravelDistance?: number;
  allowSmartMatchSmsNotification?: boolean;
}>;

export const prepareSubmitParams = (values: TUpdateUserProfileParams) => ({
  userInfo: {
    firstName: values.firstName,
    lastName: values.lastName,
    headline: values.headline,
    email: values.email,
    phoneNumber: values.phoneNumber,
    address: values.address,
    countryCode: values.countryCode,
    vendorLocationId: values.vendorLocationId,
    vendorLocationName: values.vendorLocationName,
    city: values.city,
    avatarFileId: values.avatarFileId,
    geolocation: values.geolocation,
    summary: values.summary,
    postcode: values?.postcode,
    expectedWorkTravelDistance: values?.expectedWorkTravelDistance,
    allow_smart_match_sms_notification: values?.allowSmartMatchSmsNotification,
  },
});

// This hook is first created to support job preferences page
// TODO: replace legacy fetcher by this hook in profile page
const useUpdateUserProfile = () => {
  const { fetcher, loading } = useFetch<unknown, TUpdateUserProfileParams>({
    method: 'PATCH',
    endpoint: `${getDirectMainAppHost()}/api/v3/users/self_profile`,
    formatBody: params =>
      params
        ? JSON.stringify(decamelizeKeys(prepareSubmitParams(params)))
        : undefined,
  });

  return {
    updateProfile: fetcher,
    updatingProfile: loading,
  };
};

export default useUpdateUserProfile;
