import { Box, Button, Divider } from '@hero-design/react';

import AuthStatusSignedIn from '@shared/AuthStatus/AuthStatusSignedIn';
import NotificationBell from '@shared/NavBar/NotificationBell';
import { useMegaMenu } from '@shared/NavMenu/MegaMenu/context';

import LoginRedirectDropdown from 'src/modules/auth/components/LoginRedirectDropdown';

import ItemWrapper from './components/ItemWrapper';
import useNavBarActions from './hooks/useNavBarActions';

type NavBarActionsProps = {
  variant?: 'default' | 'sideBar';
  isOpenSideBar: boolean;
  setOpenSideBar: (open: boolean) => void;
  openMegaMenu?: () => void;
};

const NavBarActions = ({
  variant,
  isOpenSideBar,
  setOpenSideBar,
  openMegaMenu,
}: NavBarActionsProps) => {
  const {
    isMediumScreen,
    isSmallScreen,
    isXSmallScreen,
    encodedCallbackUrl,
    showNotificationBell,
    showLoginRedirectDropdown,
    showSignedInStatus,
    session,
  } = useNavBarActions();

  const { closeMegaMenu } = useMegaMenu();

  const isTabletOrMobileView =
    isMediumScreen || isSmallScreen || isXSmallScreen;

  const items = [
    showNotificationBell && {
      key: 'notificationBell',
      component: <NotificationBell />,
    },
    showLoginRedirectDropdown && {
      key: 'loginRedirectDropdown',
      component: <LoginRedirectDropdown callbackUrl={encodedCallbackUrl} />,
    },
    showSignedInStatus &&
      session && {
        key: 'authStatusSignedIn',
        component: <AuthStatusSignedIn session={session} />,
      },
  ].filter(Boolean);

  if (variant === 'sideBar') {
    return (
      <Box pl="medium" pr="medium">
        <Divider marginY="medium" />

        {showLoginRedirectDropdown && (
          <LoginRedirectDropdown callbackUrl={encodedCallbackUrl} />
        )}

        {showSignedInStatus && session && (
          <AuthStatusSignedIn session={session} />
        )}
      </Box>
    );
  }

  if (isTabletOrMobileView) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 'medium',
        }}
      >
        <Button.Icon
          size="large"
          icon={isOpenSideBar ? 'cancel' : 'menu'}
          onClick={() => {
            if (!isOpenSideBar) {
              setOpenSideBar(true);
              openMegaMenu?.();
            } else {
              setOpenSideBar(false);
              closeMegaMenu();
            }
          }}
          data-test-id="menu-icon"
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {items.map(
        (item, idx) =>
          item && (
            <ItemWrapper key={item.key} isFistItem={idx === 0}>
              {item.component}
            </ItemWrapper>
          )
      )}
    </Box>
  );
};

export default NavBarActions;
