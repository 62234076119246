import React, { useCallback, useMemo } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';
import styled from 'styled-components';

import { Select } from '@hero-design/react';

import { InputFocusHandler } from '@shared/types';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import SearchNoResults from '../SearchNoResults';
import { ExtraProps, LabelProps } from '../types';

type ExtraInputProps = {
  menuVariant?: 'wrap' | 'nowrap';
};

const StyledSelect = styled(Select.Multi)`
  input {
    width: 100%;
  }
`;

interface MultiSelectInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  inputProps: Omit<React.ComponentProps<typeof Select>, 'onChange' | 'value'> &
    ExtraInputProps;
  labelProps?: LabelProps;
  extraProps?: ExtraProps & {
    queryable?: boolean;
    clearable?: boolean;
    noResultText?: string;
    onQueryChange?: (query: string) => void;
    onCreateNewOption?: (optionText: string) => void;
    onSelectCallbackFn?: (optionValue: string) => void;
    onRemoveCallbackFn?: (optionValue: string) => void;
  };
}
const MultiSelectInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps,
  labelProps = {},
  extraProps = {},
}: MultiSelectInputProps<TFieldValues, TName>) => {
  const {
    onBlur: externalOnBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    options,
    optionRenderer,
    optionPredicate,
    menuVariant = 'wrap',
    placeholder,
    prefix,
    disabled,
    loading,
    onScrollListToBottom,
    autoComplete = 'off',
    size,
  } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { error } = fieldState;
  const { value, onChange, onBlur: fieldOnBlur, name } = field;
  const {
    error: extraError,
    queryable = true,
    noResultText,
    onQueryChange: extraOnQueryChange,
    onCreateNewOption,
    'data-test-id': dataTestId,
    onRemoveCallbackFn,
    onSelectCallbackFn,
  } = extraProps;
  const hasError = error != null || extraError != null;

  const [query, setQuery] = React.useState();
  const onQueryChange = React.useCallback(
    (q: any) => {
      setQuery(q);
      extraOnQueryChange?.(q);
    },
    [extraOnQueryChange]
  );
  const queryProps = queryable
    ? {
        query,
        onQueryChange,
        noResults: <SearchNoResults noResultText={noResultText} />,
      }
    : {};
  const optionMenuStyle = useMemo(() => {
    switch (menuVariant) {
      case 'wrap':
        return undefined;
      case 'nowrap':
        return { width: 'auto' };
      default:
        return undefined;
    }
  }, [menuVariant]);

  const onBlur = useCallback<InputFocusHandler>(
    e => {
      externalOnBlur?.(e);
      fieldOnBlur();
    },
    [externalOnBlur, fieldOnBlur]
  );
  const id = `hero-theme-select-input__${name}`;

  const customOnChange = useCallback(
    (newValue: any) => {
      const deleteValues =
        value?.filter((item: string) => !newValue.includes(item)) || [];
      const createValues =
        newValue?.filter((item: string) => !value?.includes(item)) || [];

      deleteValues.forEach((item: string) => {
        onRemoveCallbackFn?.(item);
      });

      createValues.forEach((item: string) => {
        onSelectCallbackFn?.(item);
      });

      onChange(newValue);
    },
    [value, onChange, onRemoveCallbackFn, onSelectCallbackFn]
  );

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <StyledSelect
            id={id}
            name={name}
            value={value}
            options={options}
            optionRenderer={optionRenderer}
            optionPredicate={optionPredicate}
            optionMenuStyle={optionMenuStyle}
            onChange={customOnChange}
            placeholder={placeholder}
            prefix={prefix}
            invalid={hasError}
            disabled={disabled}
            loading={loading}
            onScrollListToBottom={onScrollListToBottom}
            autoComplete={autoComplete}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            size={size}
            onCreateNewOption={onCreateNewOption}
            {...queryProps}
          />
        }
      />

      {hasError && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default MultiSelectInput;
