const getCookieValue = (cookieName: string) => {
  if (typeof document === 'undefined') return null;
  const cookies = document.cookie.split('; ');
  const foundCookie = cookies.find(cookie =>
    cookie.startsWith(`${cookieName}=`)
  );

  return foundCookie ? foundCookie.split('=')[1] : null;
};

export default getCookieValue;
