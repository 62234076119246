import { useMemo } from 'react';
import { useSession } from 'next-auth/react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { ExtendedErrorData } from '@packages/eh-utils/api/types';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';
import { InitFetchData } from '@shared/utils';

import { TUserProfile } from '../types';

type UserProfileResponse = {
  data: TUserProfile;
};

export type QueryParams = {
  histories_sort?: string;
};

const useFetchUserProfile = (
  initData?: InitFetchData<TUserProfile>,
  configs?: SWRConfiguration,
  queryParams?: QueryParams,
  onCompleted?: (data: UserProfileResponse) => void
) => {
  const { status } = useSession();

  const initFetchData = initData?.data;
  // normalize to match with data shape of fetch
  const initUserProfile = useMemo(
    () => initFetchData && { data: initFetchData },
    [initFetchData]
  );
  const { fetcher } = useFetch<UserProfileResponse, void, ExtendedErrorData>({
    endpoint: `${getDirectMainAppHost()}/api/v3/users/self_profile`,
    method: 'GET',
    withSWR: true,
    onCompleted,
  });

  const {
    data,
    mutate: fetchUserProfile,
    error,
  } = useSWR<UserProfileResponse>(
    status === 'authenticated'
      ? { key: 'userSelfProfile', params: queryParams }
      : null,
    ({ params }) => fetcher(params),
    {
      revalidateOnFocus: false,
      ...configs,
    }
  );

  return {
    userProfileData: data || initUserProfile,
    fetchUserProfile,
    userProfileError: error,
    isFetchingUserProfile: !data && !error,
  };
};

export default useFetchUserProfile;
