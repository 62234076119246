import { useCallback, useEffect, useState } from 'react';
import { signIn } from 'next-auth/react';
import { useTheme } from 'styled-components';

import { Box, Dropdown, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { useMegaMenu } from '@shared/NavMenu/MegaMenu/context';

import LoginOptions from './LoginOptions';
import { Option } from './types';

import { LOGIN_OPTIONS, LOGIN_TYPES } from './constants';

type LoginRedirectDropdownProps = {
  callbackUrl?: string;
};

const LoginRedirectDropdown = ({ callbackUrl }: LoginRedirectDropdownProps) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const { selectedLevel1, setSelectedLevel1 } = useMegaMenu();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    if (selectedLevel1) {
      setSelectedLevel1(undefined);
    }

    setIsOpen(true);
  }, [selectedLevel1, setSelectedLevel1]);

  const handleChooseOption = useCallback(
    (option: Option) => {
      if (option.name === LOGIN_TYPES.JOBSEEKER) {
        signIn('EmploymentHero', {
          callbackUrl: decodeURIComponent(callbackUrl ?? '/'),
        });

        return;
      }

      const selectedOption = LOGIN_OPTIONS.find(
        ({ name }) => name === option.name
      );

      if (selectedOption?.url) {
        window.open(selectedOption.url, '_blank');
      }

      onClose();
    },
    [callbackUrl, onClose]
  );

  useEffect(() => {
    if (selectedLevel1 && isOpen) {
      onClose();
    }
  }, [selectedLevel1]);

  return (
    <Dropdown
      open={isOpen}
      onClose={onClose}
      content={<LoginOptions handleChooseOption={handleChooseOption} />}
      target={
        <Box
          onClick={onOpen}
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Image
            src="/assets/images/user-outlined.svg"
            style={{ marginRight: theme.space.small }}
            sx={{ width: theme.space.medium, height: theme.space.medium }}
          />
          <Typography.Text fontWeight="semi-bold">Login</Typography.Text>
        </Box>
      }
      sx={{ borderRadius: theme.radii.medium }}
    />
  );
};

export default LoginRedirectDropdown;
