import { useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import {
  Box,
  Button,
  Dropdown,
  Icon,
  Menu,
  Typography,
} from '@hero-design/react';

import useWindowSize from '@shared/hooks/useWindowSize';

import { localeOptions } from '../utils/constants';

const LocalesDropdown = () => {
  const { isXSmallScreen, isSmallScreen } = useWindowSize();

  const { asPath, locale, query, pathname, push } = useRouter();

  const { status } = useSession();

  const [openDropdown, setOpenDropdown] = useState(false);

  const isAuthenticated = status === 'authenticated';

  const changeActiveSite = (newLocale: string | undefined) => {
    if (newLocale) {
      push({ pathname, query }, asPath, { locale: newLocale, shallow: false });
      setOpenDropdown(false);
    }
  };

  const dropdownContent = (
    <Menu>
      {localeOptions.map(({ value, text }) => (
        <Menu.Item
          key={value}
          text={text}
          onClick={() => changeActiveSite(value)}
          active={locale === value}
        />
      ))}
    </Menu>
  );

  return (
    <Dropdown
      open={openDropdown}
      content={dropdownContent}
      onClose={() => setOpenDropdown(false)}
      data-test-id="locale-dropdown"
      target={
        <Box
          onClick={() => setOpenDropdown(!openDropdown)}
          data-test-id="locale-select"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              mr: isSmallScreen && !isAuthenticated ? 0 : 'medium',
              ml: 'xxlarge',
            }}
          >
            <Icon
              icon="location"
              sx={{ mr: 'small' }}
              size="small"
              data-test-id="locale-select-icon"
            />
            {!isXSmallScreen ? (
              <>
                <Typography.Text intent="subdued">
                  {localeOptions.find(({ value }) => value === locale)?.text ??
                    'International'}
                </Typography.Text>

                <Button.Icon icon="carat-down" sx={{ ml: 'small' }} />
              </>
            ) : null}
          </Box>
        </Box>
      }
    />
  );
};

export default LocalesDropdown;
