import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box } from '@hero-design/react';

import { TABLET_SIZE } from '@shared/NavMenu/constants';
import { checkLinkSupportLocale } from '@shared/NavMenu/helpers';
import useLocaleAndAppType from '@shared/NavMenu/hooks/useLocaleAndAppType';
import useWindowSize from '@shared/hooks/useWindowSize';

import DownloadAppCTA from '../DownloadAppCTA';
import MenuItemLevel2 from '../MenuItemLevel2';
import { Accordion, List } from '../MenuItemLevel3';

import { useMegaMenu } from '../context';
import { BodyMobileContainer } from '../styledComponents';

type MenuBodyProps = {
  isRegionBannerOpened: boolean;
};

const MenuBody = ({ isRegionBannerOpened }: MenuBodyProps) => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { selectedLevel1, selectedLevel2 } = useMegaMenu();
  const { isEHJobsInNonAU: isHideDownloadButton } = useLocaleAndAppType(
    selectedLevel1?.download_app_type
  );
  const router = useRouter();

  const isTablet = windowSize?.width && windowSize.width <= TABLET_SIZE;

  if (isTablet) {
    return (
      <BodyMobileContainer
        isHideDownloadButton={isHideDownloadButton}
        isRegionBannerOpened={isRegionBannerOpened}
        isHaveButtons={Boolean(selectedLevel1?.meta_data?.buttons?.length)}
      >
        <Box mt="medium" mb="medium">
          {selectedLevel1?.items?.map(
            menu =>
              menu.type !== 'hidden' && (
                <MenuItemLevel2 key={menu.title} menuData={menu} />
              )
          )}
        </Box>
      </BodyMobileContainer>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 540 }}>
      <Box
        sx={{
          width: 240,
          bgColor: isTablet ? 'defaultBackground' : undefined,
          borderRight: `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`,
        }}
      >
        <Box mt="medium">
          {selectedLevel1?.items?.map(
            menu =>
              menu.type !== 'hidden' && (
                <MenuItemLevel2 key={menu.title} menuData={menu} />
              )
          )}
        </Box>
      </Box>

      <Box sx={{ width: 496, overflowY: 'auto', overflowX: 'hidden' }}>
        <Box m="large" sx={{ display: 'flex', gap: 'medium' }}>
          {selectedLevel2?.type === 'accordion' &&
            selectedLevel2?.items?.[0] &&
            checkLinkSupportLocale(
              selectedLevel2?.items?.[0].locales,
              router.locale
            ) && <Accordion.Desktop data={selectedLevel2.items[0]} />}

          {selectedLevel2?.type === 'accordion' &&
            selectedLevel2?.items?.[1] &&
            checkLinkSupportLocale(
              selectedLevel2?.items?.[1].locales,
              router.locale
            ) && <Accordion.Desktop data={selectedLevel2.items[1]} />}

          {selectedLevel2?.type === 'list' && (
            <List.Desktop data={selectedLevel2} />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          width: 224, // eslint-disable-next-line @ehrocks/eh-linter/avoid-using-absolute-color
          backgroundColor: '#fcfbff',
        }}
      >
        <Box m="medium">
          <DownloadAppCTA.Banner type={selectedLevel1?.download_app_type} />
        </Box>
      </Box>
    </Box>
  );
};

export default MenuBody;
