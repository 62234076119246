import { FlattenInterpolation } from 'styled-components';

/**
 * An utility to separate CSS logic from JS/TS logic, Returns a function which
 * generates a style rule template injected to a styled-components body.
 *
 * @example
 *   // [Recommended for TS] __match__ body can be implemented by switch-case. (Type-safe)
 *   styled.div<{
 *     variant: 'collapsed' | 'uncollapsed';
 *   }>`
 *     ${styledVariant(
 *       props => props.variant, // variant is either collapsed or uncollapsed.
 *       variant => {
 *         switch (variant) {
 *           case 'collapsed':
 *             return css`
 *               display: none;
 *             `;
 *           case 'uncollapsed':
 *             return css`
 *               display: block;
 *             `;
 *         }
 *       }
 *     )};
 *   `;
 *
 * @example
 *   // [Recommended for JS] __match__ body can be implemented by an object matching.
 *   styled.div`
 *     ${styledVariant(
 *       props => props.variant, // variant is either collapsed or uncollapsed.
 *       variant =>
 *         ({
 *           collapsed: css`
 *             display: none;
 *           `,
 *           uncollapsed: css`
 *             display: block;
 *           `,
 *         }[variant])
 *     )};
 *   `;
 *
 * @see Inspiration from https://styled-system.com/variants
 */
const styledVariant =
  <T, U>(
    propSelector: (props: T) => U,
    match: (prop: U) => FlattenInterpolation<T>
  ) =>
  (props: T) =>
    match(propSelector(props));

export default styledVariant;
