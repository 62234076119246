import {
  Control,
  Controller,
  Path,
  PathValue,
  Validate,
} from 'react-hook-form';

import { requiredValidPhoneNumber } from '@packages/eh-utils/formValidators';
import { PhoneNumberInput } from '@packages/hero-theme/form';
import { PhoneNumberInputProps } from '@packages/hero-theme/form/PhoneNumberInput';

const PhoneNumberField = <
  T extends Record<string, unknown>,
  P extends Path<T>
>({
  name,
  control,
  validate,
  inputProps,
  labelProps,
  extraProps,
}: {
  name: PathValue<T, P> extends string ? P : never;
  control: Control<T>;
  validate?: Record<string, Validate<string>>;
} & Pick<
  PhoneNumberInputProps,
  'inputProps' | 'labelProps' | 'extraProps'
>) => {
  if (!control) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <PhoneNumberInput
          field={field}
          fieldState={fieldState}
          inputProps={inputProps}
          labelProps={labelProps}
          extraProps={extraProps}
        />
      )}
      rules={{
        validate: {
          requiredValidPhoneNumber,
          ...validate,
        },
      }}
    />
  );
};

export default PhoneNumberField;
