import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { getTheme, systemPalette, themeScale } from '@hero-design/react';

const theme = getTheme(themeScale, systemPalette);

const GlobalThemeProvider = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default GlobalThemeProvider;
