import styled from 'styled-components';

import { Box } from '@hero-design/react';

import data from '../data.json';

const Link = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.small}px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.subduedText};
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const LegalLinks = () => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 'large',
    }}
  >
    {data.legals.map(link => (
      <Link
        key={link.text}
        href={link.href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {link.text}
      </Link>
    ))}
  </Box>
);

export default LegalLinks;
