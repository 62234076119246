import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryMobile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line immutable/no-mutation, immutable/no-this
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  remountComponent() {
    // eslint-disable-next-line immutable/no-this
    this.setState({ hasError: false });

    return null;
  }

  render() {
    // eslint-disable-next-line immutable/no-this
    if (this.state.hasError) {
      // eslint-disable-next-line immutable/no-this
      return this.remountComponent();
    }

    // eslint-disable-next-line immutable/no-this
    return this.props.children;
  }
}

export default ErrorBoundaryMobile;
