import { Box } from '@hero-design/react';

const ItemWrapper = ({
  children,
  isFistItem,
}: {
  children: React.ReactNode;
  isFistItem: boolean;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ml: isFistItem ? 'unset' : 'large',
    }}
  >
    {children}
  </Box>
);

export default ItemWrapper;
